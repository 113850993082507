.loginSignUpHld,
.signUpSection {
  font-family: "Inter";
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
}
.loginSignUpHld .loginSection,
.loginSignUpHld .signUpSection {
  height: calc(100vh - 50px);
  overflow-y: hidden;
}

.loginSignUpHld .loginSeprator .seprator {
  width: 100%;
  border-bottom: 0.75px solid #d8d8d8;
}
.loginSignUpHld .loginSeprator span {
  color: #d8d8d8;
}
.loginSignUpHld .listContent p {
  display: inline-block;
}
.loginSignUpHld.listContent img {
  height: 20px;
  width: 20px;
}

.loginSignUpHld .loginHeader {
  padding: 13px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}
.loginSignUpHld .loginLeftContainer {
  background: #3c3e4d;
  text-align: center;
}
.loginSignUpHld .loginHeadText {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #ffff;
}
.loginSignUpHld .loginSubHead {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}
.loginSignUpHld .loginHeadingText {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #454b54;
}
.loginSignUpHld .loginText {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 4px;
}
.loginSignUpHld .loginInputHld input {
  border: none;
  padding: 0px 0px 0px 10px;
  width: 100%;
}
.loginSignUpHld .loginInputHld {
  border: 1px solid #b4bac3;
  border-radius: 5px;
  padding: 12px;
  display: flex;
  align-items: center;
}
.loginSignUpHld .loginInputHld input:focus-visible {
  outline: none;
}
.loginSignUpHld label,
.darkText {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #454b54;
}
.loginSignUpHld span {
  color: #f44d5e;
}
.loginSignUpHld .links {
  text-decoration-line: underline;
  cursor: pointer;
}
.loginSignUpHld .errorText {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #eb8989;
  margin: 4px 0px 4px 0px;
}
.loginSignUpHld label .grayText {
  color: #d0d4db;
}
.loginSignUpHld .submitButton {
  background: #f44d5e;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 15px;
}
.loginSignUpHld .registerButton {
  background: #ffffff;
  border: 1px solid #b4bac3;
  color: #3c3e4d;
}
.loginSignUpHld .formHeading {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #1d1d1d;
  text-align: center;
}
.loginSignUpHld .fontGray,
.loginSignUpHld a {
  color: #8c93a1;
}
.loginSignUpHld .loginLinkText {
  color: #565c65;
  margin-top: 18px;
}

.loginSignUpHld .carousel-item {
  background: linear-gradient(
    324.93deg,
    rgba(255, 255, 255, 0) 0.74%,
    rgba(255, 255, 255, 0.1) 81.04%
  );
  border-radius: 18px;
  padding: 30px 20px;
  opacity: 0.08;
}
.loginSignUpHld .carousel-inner .active {
  opacity: 1;
  transition-property: opacity;
  transition-duration: 700ms;
}
.loginSignUpHld .carousel-item p {
  /* width: 80%; */
}
.loginSignUpHld .logoBtn {
  background: #ffffff;
  border-radius: 10px;
  border: none;
  width: 147px;
  padding: 12px 10px;
  text-align: center;
}

.loginSignUpHld .carousel-indicators {
  margin-bottom: -3rem;
}

.loginSignUpHld .carousel-indicators [data-bs-target] {
  height: 6px;
  width: 6px;
  border: none;
  border-radius: 50px;
  background: transparent;
  border: 1px solid #8c93a1;
}

.loginSignUpHld .carousel-indicators .active {
  height: 6px;
  width: 6px;
  background: #8c93a1;
}
.loginSignUpHld .inputLogo {
  height: 17px;
  width: 16px;
}

.loginSignUpHld .checkInputHld .styledCheckBox {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #565658;
  border-radius: 2px;
  margin: 0;
  margin-right: 10px;
  font: inherit;
  color: currentColor;
  width: 14px;
  height: 14px;
  transform: translateY(-0.075em);
  display: grid;
  background: #ffffff;
  place-content: center;
}

.loginSignUpHld .checkInputHld .styledCheckBox::before {
  content: "";
  width: 0.57em;
  height: 0.57em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  background: #ffff;
}

.loginSignUpHld .checkInputHld .styledCheckBox:checked {
  margin-top: 4px;
  background: #f44d5e;
  border: none;
}
.loginSignUpHld .checkInputHld .styledCheckBox {
  background: #ffffff;
}

.loginSignUpHld .checkInputHld .styledCheckBox:checked::before {
  transform: scale(1);
}

.loginSignUpHld .errorInputField {
  border: 1px solid #f44d5e;
}
.loginSignUpHld .loginForm {
  height: calc(100vh - 50px);
}
.loginSignUpHld .registerContainer {
  width: 60%;
  max-width: 440px;
}
.loginSignUpHld .registerContainer .userName {
  gap: 15px;
}
.loginSignUpHld .viewEye {
  margin-top: 2px;
}

.loginSignUpHld .successSvg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.loginSignUpHld.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.loginSignUpHld .checkmarkSvg {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.loginSignUpHld .checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
.loginSignUpHld .successMsg {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.loginSignUpHld .innerFormContainer {
  height: 72%;
}
