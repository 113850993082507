.assessment {
  margin-top: 15px;
}

.assessmentLeft {
  padding-top: 28px;
}

.assesmentRight {
  overflow-y: auto;
}

.assessmentTable p {
  font-size: 14px;
}

.assessmentTable thead tr:last-child td {
  border-bottom: none;
}

.assessmentTable thead {
  background-color: #f3f3f3;
}

.assesmentRight::-webkit-scrollbar {
  display: none;
}

.assessmentRow span {
  max-width: 200px;
  min-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assessmentHover .assesmentImage {
  width: 63px;
  height: 42px;
}

.moreAssessments {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .assessmentBottom {
    flex-direction: column;
  }

  .assessmentLeft {
    width: 100%;
  }

  .assesmentRight {
    width: 100%;
  }
}

@media only screen and (max-width: 540px) {
  .assessmentData p {
    font-size: 8px;
  }

  .assessmentData img {
    width: 12px;
    height: 12px;
  }

  .assessmentTable p {
    font-size: 13px;
  }

  .assessmentTable small {
    font-size: 13px;
  }
}

.dashboardParticipantTableData {
  font-size: 12px;
  color: #747a8a;
}

.dashboardNoAssessmentImage {
  width: 62px;
  height: 68px;
}

.dashboardNoAssessmentButton {
  width: 184px;
  height: 34px;
  background: #3c3e4d;
  color: #ffffff;
  border-radius: 50px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}

.dashboardNoAssessmentAdditionIcon {
  width: 12px;
  height: 12px;
}

.dashboardNoUserImage {
  width: 124px;
  height: 80px;
}

.assessmentBottom {
  min-height: 252px;
}

.dashboardParticipantTable {
  padding: 20px 0px;
}

.dashboardParticipantTable thead {
  background-color: rgba(172, 173, 173, 0.1);
}

.dashboardParticipantTable thead tr th {
  padding: 10px 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #6B7280;
}

.dashboardParticipantTable tbody tr td {
  padding: 12px 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #6B7280;
}

.dashboardParticipantTable tbody tr td:first-child {
  border-left: none;
}

.dashboardParticipantTable tbody tr td:last-child {
  border-right: none;
}

.dashboardParticipantTable .actionTab {
  background: rgba(47, 76, 221, 0.05);
  border-radius: 50px;
  padding: 4px 10px;
  text-align: center;
}