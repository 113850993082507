.interview {
  background-color: #ffffff;
  border-radius: 20px 20px 20px 20px;
  /* max-width: 1400px; */
  margin: 0 auto;

  font-family: "Inter";
}
.interviewTitle {
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #3c3e4d;
  line-height: initial;
  font-family: "Inter";
}
.goToScheduleButton {
  border: none;
  border-radius: 50px;
  background: #3c3e4d;
  color: #ffffff;
  padding: 5px 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}
.goToScheduleButton img {
  margin-top: -2px;
}
.interviewProcessHeading {
  border-bottom: 1px solid #d9d9d9;
  background: #ffffff;
}
.interviewProcessBackground {
  background: #f2f3f4;
}
.reportTimelineHeader {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #3c3e4d;
  margin-top: 5px;
}
.timeLiineReportInterview {
  background: #f2f3f4;
  height: calc(100vh - 110px);
  overflow: auto;
}
.interviewProcess .intervieweeDetails .intervieweeImg {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  margin: auto 0;
}
.intervieweeDetails .name {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #212529;
}
.intervieweeDetails .candidatesDetails {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #3c3e4d;
}
.intervieweeDetails .candidatesDetails .jobsIcon {
  width: 15px;
  vertical-align: inherit;
}
.intervieweeDetails .candidatesDetails .locationIcon {
  width: 12px;
  vertical-align: inherit;
}
.candidatesDetailsList li {
  list-style: none;
  display: inline;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #3c3e4d;
}
.candidatesDetailsList li:after {
  content: "|";
  padding: 0;
  margin: 0 5px;
  vertical-align: middle;
  font-size: x-large;
  line-height: 0;
  font-weight: 200;
}
.candidatesDetailsList li:last-child:after {
  content: none;
}
.messagebtn {
  background: #ff9f4b;
  border-radius: 5px;
  border: 1px solid #ff9f4b;
  padding: 4px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}
.bownloadResumebtn {
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 5px;
  padding: 4px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}
.viewProfilebtn {
  background: #92929d;
  border-radius: 50px;
  border: none;
  color: #ffffff;
  padding: 4px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}
.viewReportImgIcon {
  margin-top: -2px;
}
.intervieweeDetailsBorder {
  border-bottom: 1px solid #d9d9d9;
}
.li-dot {
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  top: 5px;
  left: -58px;
  z-index: 9999;
}
.left-line {
  width: 3px;
  height: 108%;
  position: absolute;
  top: 10px;
  /* background-color: #000; */
  left: -46.5px;
  border-right: 1px dashed #3c3e4d;
}
.hackathonRulesList {
  list-style: none;
}
.hackathonRulesList > li {
  position: relative;
}
.interviewList {
  box-sizing: border-box;
  border-radius: 5px;
  background: #ffffff;
}
.interviewDetailsList {
  display: flex;
}
.interviewDetailsList ul {
  padding-left: 0px;
}
.interviewDetailsList li {
  list-style: none;
  font-family: "Inter";
  font-weight: normal;
  font-size: 14px;
  font-weight: 400;
  color: #92929d;
}
.interviewDetailsList .interviewDateDot {
  background: #92929d;
  width: 5px;
  height: 5px;
  margin: 0px 5px;
  padding: 2px;
  border-radius: 50%;
}
.interviewDetailsList li:last-child:after {
  content: none;
}
.interviewDetailsList .interviewerName {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #92929d;
}
.calenderIcon {
  width: 16px;
}
.feedbackDesc {
  border-top: 1px solid #d9d9d9;
}
.feedbackDesc .feedbackhead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3c3e4d;
  display: inline-block;
}
.feedbackDesc .feedbacktext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  color: #92929d;

  opacity: 0.8;
  margin-top: 23px;
}
.interviewList .jobDesc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3c3e4d;
}

.interviewScheduled .jobDesc {
  background-color: #ffecdb;
}
.interviewScheduled .jobDesc::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 18px solid #ffecdb;
  position: absolute;
  top: 17px;
}
.passedText {
  font-weight: 500;
  font-size: 14px;
  color: #3c3e4d;
}
.no-status-text {
  font-weight: normal;
  font-size: 14px;
  color: #c4c4c4;
}
.update-now-text {
  font-weight: normal;
  font-size: 15px;
  text-decoration: underline;
  color: #3c3e4d;
  cursor: pointer;
}
.selectedInterview {
  background: #ffffff;
  border: 1px solid #05d871;
  border-radius: 5px;
}
.rejectedInterview {
  background: #ffffff;
  border: 1px solid #f44d5e;
  border-radius: 5px;
}
.greenReportBtn {
  background: #05d871 !important;
}
.redReportBtn {
  background: #f44d5e;
}
.selectedInterview .viewProfilebtn {
  background: #92929d;
}
.messagebtn div {
  background: #fff;
  color: #ff9f4b;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  vertical-align: middle;
  text-align: center;
  display: inline-flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center;
}
.editStatushld {
  background: #fff;
  position: absolute;
  top: 10px;
  right: 30px;
  width: 150px;
  border: 0.5px solid #d8d8d8;
  box-sizing: border-box;
  box-shadow: 0px 8px 12px rgb(0 0 0 / 10%);
  border-radius: 5px;
  z-index: 9999999;
}
.editStatushld p {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #3c3e4d;
  cursor: pointer;
}
.candidateInterviewStatus {
  width: 24%;
  text-align: right;
}
.editStatushld p:hover {
  background-color: #ededee;
}
.feedbackeditIcon {
  width: 10px;
  height: 10px;
  cursor: pointer;
  margin-top: -2px;
  filter: opacity(67%);
}
.passedNextRound {
  padding: 7px 114px;

  margin-bottom: 20px;
}
.editIconBorder {
  width: 29px;
  height: 29px;
  border: 1px solid #e2e2ea;
  border-radius: 50px;
  margin-left: 10px;
  padding: 0px 5px;
}
.editIconPad {
  padding: 0px 4px !important;
  border: 1px solid #000000;
}
.reportFeedbackIcon {
  filter: opacity(100%);
}
.interviewPtextArea {
  border: 1px solid #c4c4c4;
  font-size: 14px;
  border-radius: 5px;
}
.interviewPtextArea:focus-visible {
  outline: none;
}
.IPsubmitbtn {
  position: absolute;
  bottom: 6px;
  color: #fff;
  border: none;
  background: #f44d5e;
  width: 70px;
  font-size: 14px;
  border-radius: 4px;
  height: 30px;
  font-weight: 500;
  right: 0px;
}
