.assessmentHeader {
  font-weight: 300;
  font-size: 16px;
}

.assessmentImage {
  width: 60px;
  height: 60px;
  background: #f3f8fc;
  border: 1px solid #e4e4e5;
}
.assessmentTitle p {
  font-size: 12px;
  font-weight: 400;
  padding: 0px 10px;
}

.assessmentTabCreatedTime {
  font-size: 12px;
  color: #747a8a;
  font-style: normal;
}
.assessmentTabCompanyName {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-right: 10px;
  color: #3c3e4d;
}

.assessmentTabTable tr td {
  border-left: none;
  border-right: none;
}

.table tr td {
  border-bottom: none;
}

tr:last-child td {
  border-bottom: 1px solid #e4e4e4;
}

.assessmentName {
  font-size: 12px;
  font-weight: 500;
  color: #3c3e4d;
  /* margin-top: 5px; */
}

.sharedlistNumber {
  font-size: 14px;
  color: #3c3e4d;
  font-weight: 700;
}

.participantNumber {
  color: #3c3e4d;
  font-weight: 700;
}

.totalParticipants {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f6f9;
  width: 143px;
  cursor: pointer;
}

.assessmentTabArrowIcon{
  width: 14px;
}


.assessmentTabAssessmentTable {
  margin-bottom: 0px;
}

.assessmentTabAssessmentTable tr:first-child td:first-child {
  border-top-left-radius: 0px;
}

.assessmentTabAssessmentTable tr:first-child td:last-child {
  border-top-right-radius: 0px;
}

.assessmentTabAssessmentTable td {
  padding-top: 10px;
  padding-bottom: 12px;
}

.totalParticipants:hover {
  background: #dfe0e4;
  cursor: pointer;
}

.totalParticipants p {
  font-size: 12px;
  padding: 3px;
  color: #3c3e4d;
}

.assessmentTabTable {
  height: calc(100vh - 145px);
  /* border-right: 1px solid #e4e4e5; */
  overflow: auto;
  position: relative;
}

.assessmentInsideTabTable {
  height: calc(100% - 60px);
}

/* .assessmentLeft {
  margin-right: 10px;
} */
.assessmentShared {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f6f9;
  width: 130px;
}

.assessmentShared:hover {
  background: #dfe0e4;
  cursor: pointer;
}

.assessmentInsideTabTable tbody tr:hover {
  box-shadow: none;
}

.assessmentDelete p {
  font-size: 12px;
  color: #3c3e4d;
}

.assessmentTabEditButton{
  width: 12px;
  height: 12px;
}

.assessmentTabDeleteButton{
  width: 12px;
  height: 12px;
}

.assessmentShared p {
  color: #3c3e4d;
  font-size: 12px;
  font-weight: 400;
  padding: 3px;
}

.assessmentEdit {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #3c3e4d;
}

.assessmentEdit p {
  font-size: 14px;
}

.assessmentDelete {
  width: 160px;
  /* padding: 1px; */
  border-radius: 30px;
  cursor: pointer;
  border: 1px solid #3c3e4d;
}
