.errorPage {
  background-color: #e5e5e5;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorMessage {
  text-align: center;
}
.errorMessage h3 {
    margin: 20px 0;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 500;
  color: #b1b0b4;
}