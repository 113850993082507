.enterpriseModal {
  background: #ffffff;
  border: 1px solid #e1e3e6;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 3px 5px;
  z-index: 9999999;
  position: fixed;
  top: auto;
  right: auto;
  left: 50%;
  bottom: 30px;
  transition: opacity 5s;
  transform: translate(-50%, -50%);
}
.settingToast .enterpriseModal {
  right: 320px !important;
  left: auto !important;
}
.settingsToast .enterpriseModal{
  right:240px !important;
  left: auto !important;
}
.enterprisePopUpText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}
