body {
  font-family: Roboto !important;
}
.interviews {
  background-color: #ffffff;
  border-radius: 20px 20px 20px 20px;
  /* max-width: 1400px; */
  margin: 0 auto;
}
.interviewsNavbarContainer {
  background-color: #3c3e4d;
  border-radius: 20px 0 0 20px;
  padding: 0;
  height: calc(100vh - 150px);
}
.interviewsHeadingContainer {
  position: relative;
  padding: 5px 20px;
  margin: 0;
  border: solid 1px #e5e5e5;
  border-radius: 0px 20px 0px 0px;
}
.interviewsHeadingContainer h2 {
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #3c3e4d;
}
.interviewsContentContainer {
  padding: 0px 50px;
  height: 90%;
}
.createInterview {
  min-width: 290px;
  min-height: 148px;
  border-radius: 5px;
  border: dashed 1px #f44d5e;
  background-color: #fff6f7;
}
.createInterview p {
  color: #f44d5e;
  font-weight: 500;
  padding-top: 10px;
  font-size: 16px;
}
.createInterviewLink .modal-content {
  margin: 0px !important;
  padding: 0px !important;
  background: #f8f8f8;
}
.createInterviewLink .modal-header {
  border-bottom: none;
  padding: 16px 16px 0px 0px;
}
.createInterviewLink .modal-body {
  margin: 0px !important;
  /* text-align: left!important; */
  padding: 0px 10px;
  /* background-color: #3c3e4d; */
}
.createInterviewLink input {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}
.createInterviewLink .copyLinkBox input {
  font-weight: 400;
}
.createInterviewLink h5 {
  font-size: 16px;
  color: white;
  font-weight: bold;
  font-family: "Roboto";
  margin-bottom: 0;
}
/* .createInterviewLink img{
  margin-top: 3px;
  height: 12px;
} */
.createInterviewLink p {
  font-size: 14px;
  color: #666666;
  font-weight: 300;
}
.createInterviewLink .instantInterview {
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  color: #3c3e4d;
  background-color: white;
  border: solid 1px #3c3e4d;
  padding: 6px 30px;
  min-width: 375px;
  min-height: 44px;
}
.createInterviewLink .instantInterview:hover {
  background: #3c3e4d;
  color: #ffffff;
}
.createInterviewLink .scheduleInterview {
  font-size: 14px;
  font-weight: 500;

  color: white;
  background: linear-gradient(286.29deg, #f44d5e 2%, #fc7269 94.83%);
  border-radius: 5px;
  border: none;
  padding: 6px 30px;
  min-width: 375px;
  min-height: 44px;
}
.createInterviewLink .scheduleInterviewlg {
  background: linear-gradient(286.29deg, #f44d5e 2%, #fc7269 94.83%);
  border-radius: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  padding: 13px;
  color: #ffffff;
  height: 44px;
  border: none;
}
.copyBtn {
  background-color: #eff0f1;
  border: 1px solid #eff0f1;
  background: #3ed0f1;
  border-radius: 5px;
}
.copyBtn #text {
  font-size: 10px !important;
}
.createInterviewModal input::placeholder,
.createInterviewModal .css-1q9xf2f-Placeholder {
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #b1b2b8;
}
.createInterviewModal .rc-time-picker-panel {
  top: 509px !important;
}

.copyLink {
  width: 87%;
  height: 49px;
  background: #ffffff;
  border: none;
  border-left: 0.4px solid #d9d9d9;
  border-radius: 0px 5px 5px 0px;
  padding: 10px 66px 10px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
}
.scheduleInputs {
  background: #ffffff;
  font-size: 14px;
  border: 0.4px solid #d9d9d9;
}
.copyBtn {
  position: absolute;
  top: 4px;
  right: 3px;
  padding: 11px 15px;
}
.shareInterviewWhatsapp {
  background: #016abf;
  border-radius: 5px;
  width: 50px;
  height: 50px;
}
.shareInterviewEmail {
  background: #f44d5e;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  padding: 10px;
}
.greenBackground {
  background: #05d871;
}
.copyLink:focus-visible {
  outline: none;
}
.copyBtn p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff !important;
  font-weight: 400;
}
.linkIconModal {
  padding: 17px 20px;
  background: #ffffff;
}
.copyLinkBox {
  border: 0.4px solid #d9d9d9;
  border-radius: 5px;
  height: 49px;
  overflow: hidden;
  position: relative;
}
.copyBtn img {
  height: 14px;
}
.copyLink :focus {
  border: 1px solid #3c3e4d;
  border-radius: 5px 0 0 5px;
}
.instant p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #3c3e4d;
}
.shareInterview img {
  height: 38px;
}
.schedulingDiv {
  text-align: left;
  padding: 0px 6px;
}
.datepicker {
  position: relative;
}
.dropdownArrowImg {
  height: 11px;
  width: 12px;
  position: absolute;
  top: 8px;
  right: 16px;
}
.dateIconImg {
  position: absolute;
  top: 8px;
  left: 16px;
  z-index: 9999999;
}
.schedulingDiv .interviewList {
  font-size: 12px;
  font-weight: 500;
  width: 50%;
  height: 34px;
  color: #3c3e4d;
  background-color: white;
  border: solid 1px #e7e7e7;
}
.schedulingDiv .rc-time-picker-input {
  width: 190px;
  padding-left: 50px;
}
.timePicker {
  position: relative;
}
.interviewClock {
  position: absolute;
  top: 8px;
  left: 14px;
  z-index: 999999;
}
.timerLabels {
  display: block;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin: 28px 0px 5px;
  color: #3c3e4d;
}
.timePickerArrows {
  position: absolute;
  top: 7px;
  right: 15px;
  width: 14px;
}
.schedulingDiv .interviewList:active {
  font-size: 12px;
  font-weight: 500;
  width: 50%;
  height: 34px;
  color: #3c3e4d;
  background-color: #e7e7e7;
  border: solid 1px #e7e7e7;
}
.schedulingDiv .interviewList:active {
  font-size: 12px;
  font-weight: 500;
  width: 50%;
  height: 34px;
  color: #3c3e4d;
  background-color: #e7e7e7;
  border: solid 1px #e7e7e7;
}
.schedulingDiv h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #3c3e4d;
  margin: 26px 0px 10px;
}
.schedulingDiv img {
  height: 25px;
}
.schedulingDiv input {
  font-size: 13px;
  font-family: "Inter";
  width: 100%;
  height: 40px;
  border-radius: 5px;
  color: #3c3e4d;
}
.schedulingDiv input:focus-visible {
  outline: none;
}
.schedulingDiv .emailer {
  background-color: white;
  /* background-image: url('searchicon.png'); */
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding-left: 40px;
}
.schedulingDiv p {
  color: #3c3e4d;
}

.schedulingDiv .emailSelection input {
  width: 20px;
  height: 20px;
}
.schedulingDiv .emailSelection input[type="radio"]:checked::before {
  background: radial-gradient(#f44d5e 40%, transparent 50%);
  border: 1px solid #d9d9d9;
}
.schedulingDiv .emailSelection input[type="radio"]::before {
  border: 1px solid #d9d9d9;
  height: 20px;
  width: 20px;
}

.schedulingDiv span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #3c3e4d;
}

/* 7/1/2022 START */
/* .d-flex.justify-content-start > img {
  margin: auto;
} */

.rc-time-picker-clear-icon:after {
  display: none !important;
  pointer-events: none !important;
}

.rc-time-picker-clear {
  display: none !important;
}

.rc-time-picker-tofield {
  padding: 5px;
  font-size: small;
}

.datepicker > div {
  display: inline-block;
}

.margin-top-bottom {
  margin-top: 5px;
  margin-bottom: 5px;
}

.rdtActive {
  background-color: #f44d5e !important;
}
/* 7/1/2022 END */
.createInterviewModal .modal-sm {
  max-width: 465px;
}
.scheduledInterviewBoxMain {
  background: #ffffff;
  border: 0.195652px solid #d9d9d9;
  box-shadow: 0px 9.78261px 19.5652px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.scheduledInterviewBox {
  background: #ffffff;
  border: 0.195652px solid #d9d9d9;
  box-shadow: 0px 9.78261px 19.5652px rgba(0, 0, 0, 0.05);
  padding: 13px 24px;
  border-radius: 10px 10px 0px 0px;
}
.scheduledInterviewBoxMain .heading9 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3c3e4d;
}
.scheduledInterviewBoxMain .subHeading9 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #858585;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 11px;
}
.scheduledInterviewBoxMain .timesInterview span {
  width: 120px;
}
.scheduledInterviewBoxMain .verticalLine {
  position: absolute;
  width: 0px;
  height: 36px;
  left: 200px;
  top: 19px;

  border: 1px solid #e2e2e9;
}
.scheduledInterviewBoxMain .linkStyle {
  cursor: pointer;
  color: #5070fa;
  margin: 0px auto;
}
.scheduledInterviewBoxMain .linkStyle:hover {
  color: #5070fa;
}
