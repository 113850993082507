.iamContainer::-webkit-scrollbar,
.iamUserTable::-webkit-scrollbar {
  display: none;
}
.iamContainer .iamUserTable {
  margin-top: 50px;
}
.iamUserTable .roleCell {
  padding: 4px 13px;
  border-radius: 4px;
  width: fit-content;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
}
.iamContainer .iamUserTable .table-responsive td:nth-child(1),
.iamContainer .iamUserTable .table-responsive th:nth-child(1) {
  padding: 0.7rem 4px 0.7rem 30px;
}
.iamUserTable .admin_panel,
.iamUserTable .admin {
  background: #fff3e9;
  color: #e99d64;
}
.iamUserTable .super_admin {
  background: #ece5f7;
  color: #a783d3;
}
.iamUserTable .user {
  background: #d4f6f2;
  color: #6bcec6;
}
.iamUserTable .table-responsive table {
  border-collapse: collapse;
  position: relative;
}
.iamUserTable .table-responsive {
  height: calc(100vh - 170px);
}
.iamUserTable .table-responsive::-webkit-scrollbar {
  display: none;
}
.iamUserTable .table-responsive .tableAction {
  border: none;
  background: none;
  width: 100%;
}
.iamUserTable .table-responsive tbody {
  border: 0.5px solid #e2e2e9;
}
.iamUserTable .headerSection {
  display: flex;
  justify-content: space-between;
  padding: 40px 30px 10px 30px;
}
.iamUserTable .headerSection .searchBar {
  border: 0.5px solid #e2e2e9;
  padding: 6px 20px;
  border-radius: 50px;
}
.iamUserTable .headerSection input {
  border: none;
  margin-left: 16px;
}
.iamUserTable .headerSection input:focus-visible {
  outline: none;
}
.iamUserTable .headerSection input::placeholder,
.iamUserTable .headerSection input,
.settingsAddUser input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
}
.iamUserTable .headerSection input {
  font-size: 14px !important;
}
.iamUserTable .headerSection .exportBtn {
  background-color: #e2e2e9;
  border-radius: 50px;
  padding: 6px 30px;
  line-height: 14px;
}
.iamUserTable .headerSection .exportBtn:disabled {
  border-color: transparent;
}
.iamUserTable .headerSection .exportBtn img,
.iamUserTable .headerSection .InviteBtn img {
  margin-left: 10px;
}
.iamUserTable .headerSection .exportBtn a,
.iamUserTable .headerSection .InviteBtn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}
.iamUserTable .headerSection h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2e2c2b;
  margin-right: 10px;
}

.iamUserTable .headerSection .headerTag {
  background: #ffecec;
  border-radius: 50px;
  padding: 6px 12px;
  color: #f44d5e;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  font-family: "Inter";
}

.iamContainer .iamUserTable .table-responsive th {
  margin: 0;
  padding: 1rem 1rem;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
}
.iamUserTable .table-responsive table thead {
  background: #ffff;
  /* z-index: 1111;
  position: sticky;
  top: 0; */
}
.iamContainer .iamUserTable .table-responsive tr td:nth-child(2) {
  font-size: 14px;
  line-height: 17px;
}

.iamUserTable .table-responsive .nameCell .userImg {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 20px;
}
.iamContainer .iamUserTable .table-responsive td {
  margin: 0;
  padding: 10px 16px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
  text-align: left;
  border: none;
  position: relative;
}
.iamContainer .iamUserTable table tbody tr:nth-child(odd) {
  background: #f8f8f8;
}

/* Custom Bootstrap Modal  */
.userRemoveModal {
  width: 400px !important;
}

.userRemoveModal .modal-title,
.passwordResetModal .modal-title,
.settingsAddUser .modal-title {
  font-family: "Roboto";
  font-weight: 500;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-left: 12px;
}
.modal-backdrop {
  z-index: 11111 !important;
}
.modal {
  z-index: 99999 !important;
}

.resetPasswordForm .form-group label,
.settingsAddUser .addUserForm .form-group label {
  font-size: 14px;
  line-height: 16px;
  margin-top: 0px;
  margin-bottom: 5px;
}
.settingsAddUser .addUserForm .form-group label span {
  color: #f44d5e;
}
.resetPasswordForm .form-input,
.settingsAddUser .addUserForm .form-input {
  font-size: 14px;
}
.settingsAddUser .addUserForm .form-input,
.passwordResetModal .resetPasswordForm .form-input {
  border-radius: 50px;
  padding: 0px 15px;
  margin: 5px 12px 24px 0px;
}

.passwordResetModal {
  width: 500px !important;
}

.settingsAddUser {
  max-width: 586px !important;
}
.settingsAddUser.modal-title {
  width: 500px !important;
}

.userRevokeDialog p {
  font-family: Roboto;
  font-size: 16;
  line-height: 1.67;
  color: #3c3e4d;
}
.resetPasswordForm {
  margin-top: 15px;
  padding: 0 40px;
}
.resetPasswordForm .modalButtonSubmit {
  width: 40%;
}
.resetPasswordForm .form-group {
  padding: 5px 0px;
}

.settingsAddUser .addUserForm {
  padding: 15px;
}
.settingsAddUser .addUserForm p {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  padding-right: 1.8rem;
  padding-left: 0.8rem;
  color: #666666;
}
.settingsAddUser .addUserForm .thintText {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  padding-right: 1rem;
  color: #666666;
  padding-left: 0px;
}
.settingsAddUser.addUserForm .form-group {
  padding: 5px 0px;
}

.iamUserTable .table-responsive .tableMenu {
  display: none;
  right: 30px;
  top: 0px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  z-index: 1;
  cursor: pointer;
}
.iamUserTable .table-responsive .menuActive {
  display: block;
}

.iamUserTable .table-responsive .tableMenu div {
  color: black;
  padding: 12px 16px;
  display: block;
}

.iamUserTable .table-responsive .tableMenu div:hover {
  background: #f2f3f4;
}

.iamUserTable .table-responsive .tableMenu p {
  display: inline;
}

.iamUserTable .table-responsive .menuIcons {
  padding: 2px;
  margin: 0px 15px 0px 5px;
  width: 18px;
  height: 18px;
}

.iamTablePagination {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iamTablePagination button {
  margin: 0px 10px;
  padding: 4px;
  border: none;
  border-radius: 5px;
  color: #f1f1f1;
  background-color: #3c3e4d;
}

.addUserButton {
  position: absolute;
  bottom: 17px;
  right: 15px;
  border: none;
  border-radius: 50%;
  padding: 0;
  font-size: 30px;
  height: 50px;
  width: 50px;
  text-align: center;
  background-color: #f44d5e;
  color: #f1f1f1;
  cursor: pointer;
}

.userRevokeDialog div {
  text-align: center;
  padding: 10px 10px;
}
.settingsAddUser .addImage,
.settingsAddUser .userImage {
  background: #f2f3f4;
  text-align: center;
  height: 143px;
  width: 143px;
  object-fit: cover;
  border-radius: 5.95833px;
  cursor: pointer;
}
.iamContainer .iamUserTable .headerSection .exportBtnActive {
  background: #3c3e4d;
}
.settingsAddUser input[type="file"] {
  border: none;
  visibility: hidden;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.settingsAddUser input[type="file"]::before {
  content: url("../../../../../assets/Icons/Settings/addImage.svg");
  height: 64px;
  width: 64px;
  position: relative;
  left: 39px;
  top: 39px;
  visibility: visible;
}
.settingsAddUser .changePhoto {
  background-color: #ff9f4b;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 8px 30px;
}
.settingsAddUser .modalButtonSubmit,
.settingsAddUser .modalButtonSubmitActive,
.userRevokeDialog .modalButtonSubmitActive,
.resetPasswordForm .modalButtonSubmitActive,
.userRevokeDialog .modalButtonCancel,
.resetPasswordForm .modalButtonCancel {
  padding: 8px 14px;
  border: none;
  border-radius: 50px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 128%;
  background-color: #e2e2e9;
  color: #fff;
  width: 48%;
}
.settingsAddUser .modalButtonSubmitActive,
.userRevokeDialog .modalButtonSubmitActive,
.resetPasswordForm .modalButtonSubmitActive {
  background-color: #f44d5e;
}
.userRevokeDialog .modalButtonCancel,
.userRevokeDialog .modalButtonSubmitActive,
.userRevokeDialog .modalButtonSubmit {
  width: 70%;
}
.settingsAddUser .modal-content,
.userRemoveModal .modal-content {
  background: #ffffff;
  box-shadow: 0px 2px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.modalButtonCancel {
  padding: 8px 14px;
  border-radius: 5px;
  border: solid 2px #212529;
  background: #fff;
  width: 40%;
}

/* checkbox */

.styled-checkbox {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #a9a9a9;
  border-radius: 2px;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 14px;
  height: 14px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.styled-checkbox::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  background: #ffff;
}

.styled-checkbox:checked {
  background: #f44d5e;
  border: none;
}

.styled-checkbox:checked::before {
  transform: scale(1);
}
@media only screen and (min-width: 2560px) {
  th,
  td {
    font-size: 18px;
  }
  table td:nth-child(1),
  table td:nth-child(2) {
    font-size: 20px;
  }
  .userRemoveModal .modal-title,
  .passwordResetModal .modal-title,
  .settingsAddUser .modal-title {
    font-size: 30px;
  }

  .resetPasswordForm .form-group label,
  .settingsAddUser .addUserForm .form-group label {
    font-size: 18px;
  }

  .resetPasswordForm .form-input,
  .settingsAddUser .addUserForm .form-input {
    font-size: 18px;
  }
}

.hZMeQW:focus,
.hZMeQW:active {
  box-shadow: none;
}
