.assessmentCntr {
  margin-top: 49px;
}
.mcqForm {
  margin-top: 26px;
}
.astrick {
  color: #f44d5e;
}
.mcqNavigation {
  font-weight: 400;
  font-size: 12px;
  font-family: "Roboto";
  border: 1px solid #f2f3f4;
  padding: 8px 54px 8px 26px;
}
.mcqNavigation p {
  color: #666666;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
}
.mcqNavigation p span {
  color: #666666;
}
.mcqNavigation p span:hover {
  color: #3c3e4d;
}
.mcqNavigation span {
  color: #3c3e4d;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
}
.mcqNavigation .uploadCSVBtn {
  background-color: #3c3e4d;
  color: #ffffff;
  padding: 9px 20px;
  border-radius: 50px;
  border: none;
  font-size: 12px;
  font-weight: 500;
  font-family: "inter";
}
.mcqForm .markAsAns {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  padding: 12px 37px;
  border-radius: 5px 5px 0px 0px;
  border-bottom: none;
}
.mcqForm .optionAnswerBox {
  width: 100%;
  border-radius: 5px 0px 5px 5px;
  padding-bottom: 45px;
}
.mcqForm .addOptionsBtn {
  background-color: #d9d9d9;
  border-radius: 50px;
  color: #666666;
  padding: 12px 0px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 24px;
}
.mcqForm .tagsMcq {
  margin-bottom: 12px;
}
.mcqForm .saveBtns {
  padding: 7px 27px;
  border: none;
  box-shadow: none;
  border-radius: 50px;
  background-color: #f44d5e;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter";
}
.mcqForm .mcqOptions {
  margin-top: 35px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: #3c3e4d;
}
.mcqForm .mcqFields {
  margin-top: 12px;
  border-radius: 50px;
  padding-top: 4px;
}
.mcqForm .correctOption {
  border: 1px solid #009f53;
  padding: 12px 13px;
  background-color: #009f53;
  color: #ffffff;
}
.mcqForm .mcqDuration {
  padding-top: 4px;
  margin-top: 12px;
  border-radius: 50px 0px 0px 50px;
}
.mcqForm .durationCapsule,
.customCode .durationCapsule {
  margin-top: 12px;
  border-radius: 0px 50px 50px 0px;
  padding: 9px 20px;
  background-color: #d9d9d9;
}
.mcqForm .problemText,
.mcqForm .scoreAndDuration,
.mcqForm .questionLabel {
  color: #3c3e4d;
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
}
.mcqForm input::placeholder {
  color: #b1b2b8;
  font-size: 12px;
  font-weight: 400;
}
.mcqForm input[type="radio"] {
  border-radius: 50%;
  border: none !important;
  margin-top: 2.5px;
}
.mcqForm input[type="radio"]:checked {
  border: none;
}
.mcqForm input:focus {
  box-shadow: none;
  border: 1px solid #d9d9d9;
}
.mcqForm input[type="radio"]::before {
  border: 1.5px solid #d9d9d9;
}
.mcqForm input[type="radio"]:checked::before {
  border: 1.5px solid #3c3e4d;
}
/* custom code css start*/
.customCode {
  font-family: "Inter";
  padding: 26px 0px;
}
.customCode .formInput {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
  padding: 7px 20px;
}
.customCode .codeFormInputHld,
.customCode .testCaseHld {
  margin-bottom: 39px;
}
.customCode .errorMsg {
  font-family: sans-serif;
  font-size: 13px;
  color: #f44d5e;
  padding-top: 5px;
}
.customCode .durationCapsule {
  margin-bottom: 39px;
  margin-top: 0px;
  padding: 8px 20px;
  font-size: 14px;
}
.customCode .codeDuration input {
  border-radius: 50px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.customCode .codeFormLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #3c3e4d;
  margin-bottom: 12px;
}
.customCode .grayInputField {
  background: #f2f3f4;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 100%;
  color: #3c3e4d;
  margin-top: 9px;
  white-space: pre-wrap;
}
.customCode .caseExample {
  width: 47%;
}
.customCode .secondaryLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
}
.customCode .addOptionsBtn {
  border-radius: 50px;
  color: #666666;
  padding: 12px 0px;
}
.customCode input:focus-visible {
  outline: none;
}
.customCode .addTestCase {
  border: none;
  background: #f2f3f4;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
  padding: 8px 20px;
  font-size: 12px;
}
.customCode input::placeholder {
  font-size: 12px;
}
.customCode .caseExample .deleteTestCase {
  width: 36px;
  margin-top: 9px;
}
.createCodeBtnOr {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #666666;
}
/* custom code css end*/
/* tagsautoSearch */
.tagsHld {
  position: relative;
}
.tagsHld .tagsDropdown {
  max-height: 180px;
  overflow-y: scroll;
  position: absolute;
  top: 32px;
  left: 15px;
  border-radius: 5px;
  background-color: #ffff;
  font-size: 12px;
  font-weight: 400;
  box-shadow: 0px 2px 20px 10px rgb(0 0 0 / 5%);
  z-index: 9;
}
.tagsHld .tagsDropdown::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.tagsHld .tagsDropdown p {
  cursor: pointer;
  text-align: left;
  padding: 9px 32px 11px 18px;
}
/* tagsautoSearch */

/* media queries */

@media (min-width: 1450px) {
  .mcqForm input::placeholder,
  .mcqForm .problemText,
  .mcqForm .scoreAndDuration,
  .mcqForm .questionLabel,
  .mcqForm .mcqOptions,
  .mcqForm .markAsAns,
  .mcqNavigation,
  .customCode .codeFormLabel,
  .mcqForm input::placeholder,
  .customCode input::placeholder,
  .customCode .customDropdown {
    font-size: 14px;
  }
}
