.intervieweeDetails {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #3c3e4d;
}
.DownloadReportBtn {
  border: none;
  background: #d9d9d9;
  border-radius: 50px;
  padding: 7px 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #3c3e4d;
}
.DownloadReportBtn img {
  width: 14px;
  height: 14px;
}
.reportBackground {
  background: #f2f3f4;
  height: calc(100vh - 50px);
}
.reportHeading {
  background: #ffffff;
}
.dividerLine {
  color: #e2e2ea;
}
.reportBody {
  background: #ffffff;
  border-radius: 5px;
  margin-top: 37px;
  padding: 30px 0px 30px 30px;
}
.candidateDetailsSection {
  background: #e2e2ea;
  border-radius: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding: 7px 24px;
  color: #3c3e4d;
  width: 200px;
  height: 30px;
}
.candidateDetails {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #92929d;
}
.candidateDetailsValue {
  font-weight: 600;
  margin-top: 10px;
}
.notesHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3c3e4d;
}
.verticalDivider {
  border: 1px solid #e2e2ea;
  height: 49px;
  width: 1px;
  margin: 0px 37px;
}
.interviewDetails {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #212529;
}
.interviewDetailscandidate {
  font-weight: normal;
  font-size: 14px;
  color: #abacb3;
  margin-bottom: 8px;
}
.interviewDetailsinterview {
  font-weight: normal;
  font-size: 14px;
  color: #abacb3;
  margin-bottom: 8px;
}
.interviewDetailstext {
  font-weight: 500;
  font-size: 14px;
  color: #212529;
  margin-bottom: 8px;
}
.interviewDetailsCol {
  width: 12%;
}

.notesHld .heading {
  font-weight: 500;
  font-size: 16px;
  color: #3c3e4d;
  text-align: left;
  border-bottom: 1px solid #c1c1c1;
}
.notesHld .notesText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #92929d;
  text-align: justify;
  text-justify: inter-word;
}
.interviewReportContainer .react-stars span {
  margin: 0px 7px;
}
.notestitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3c3e4d;
}
.starRating {
  width: 150px;
}
.margin-fix-InterviewReport {
  margin-top: -16px;
}
.status0ftheCandidate p:hover {
  background: #92929d;
  color: #ffffff;
}
.selectedstatusOftheCandidate {
  background: #92929d !important;
  color: #ffffff !important;
}
.css-26l3qy-menu {
  z-index: 999999;
  border: 1px solid #e4e4e4;
}
.interviewVideo img {
  width: 100%;
}
.screenWrapper {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;
  background: gray;
}
.playerBody {
  background: #ffffff;
  height: 100%;
  border-right: solid thin gray;
}
.screen {
  overflow: hidden;
  position: absolute;
  transform-origin: left top;
  top: 50%;
  left: 50%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.iframe {
  position: absolute;
  border: none;
  background: white;
}
.status0ftheCandidate {
  border: 1px solid #eff0f1;
  border-radius: 10px;
  overflow: hidden;
}
.status0ftheCandidate p {
  font-weight: 500;
  font-size: 14px;
  color: #494b58;
  background-color: #ffffff;
  text-align: center;
  width: 34%;
  cursor: pointer;
}
.candidateDetailBox {
  background: #ffffff;
  border: 0.4px solid #d9d9d9;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  display: flex;
  padding: 10px;
}
.candidateDetailBox img {
  width: 34px;
  height: 34px;
}
.candidateDetailBox h6 {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #3c3e4d;
}
.candidateDetailBox p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #92929d;
}
.submitbtn {
  background: linear-gradient(286.29deg, #f44d5e 2%, #fc7269 94.83%);
  border-radius: 50px;
  color: #ffffff;
  border: 1px solid #e9505c;
  width: 100%;
  padding: 6px;
}
.SbtextArea {
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #ffffff;
  resize: none;
  color: #92929d;
}
.modalCloseBtn {
  width: 9px;
  height: 9px;
}
.interviewReportContainer .modal-header {
  border-bottom: none;
  background: #f8f8f8;
  padding-bottom: 0px;
}
.interviewReportContainer .modal-body {
  background: #f8f8f8;
  padding: 0px 0px 31px 0px;
}
.modal-content {
  border-radius: 10px !important;
}
.interviewReportContainer .modal-body h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 38px;
}
.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 1.5cm;
  margin: 1cm auto;
  background: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}
.question-Type-report {
  height: 100%;
}
.question-Type-report .sidebar {
  width: 20%;
}
.question-Type-report .questionBlock {
  width: 30px;
}
.question-Type-report .interviewerQuestionStatushead img {
  width: 12px;
}
.question-Type-report .scorehld {
  margin: 0px 34px;
}
.question-Type-report {
  border-radius: 0px;
}
.pageQuestionHld {
  margin-right: 1.5cm;
  margin-left: 1.5cm;
}
.reportDetailsCol {
  width: 15%;
}
.assessment-round-report-card {
  border-radius: 8px;
  border: 0.5px solid #e2e2ea;
  background: #f2f3f4;
  position: relative;
  padding-top: 28px;
  margin-right: 20px;
  margin-top: 30px;
}
.assessment-round-report-card .roundstext {
  background: #e2e2ea;
  display: inline;
  color: #3c3e4d;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: -12px;
  padding: 6px 20px;
  border-radius: 16px 50px 50px 16px;
}
.assessment-round-report-card .assessment-title {
  color: #3c3e4d;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  padding-left: 40px;
  text-transform: capitalize;
}
.assessment-round-report-card .assessment-user-result-cntr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding-right: 10px;
}
.assessment-round-report-card .assessment-user-result-hld {
  background: #fff;
  display: inline-flex;
  list-style-type: none;
  padding: 0;
  padding: 4px 30px;
  border-radius: 0 50px 50px 0;
  margin-bottom: 0;
}
.assessment-round-report-card
  .assessment-user-result-hld
  li:first-child::before {
  content: "";
  margin: 0;
}
.assessment-round-report-card .assessment-user-result-hld li::before {
  content: "|";
  margin: 0 20px;
  font-weight: 300;
  font-size: large;
  color: #e2e2ea;
}
.assessment-round-report-card .assessment-user-result-hld li {
  color: #92929d;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.assessment-round-report-card .assessment-user-result-hld li span {
  color: #3c3e4d;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
}
.assessment-round-report-card .user-result {
  color: #3c3e4d;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}
.assessment-round-report-card .user-result-score {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
}
.assessment-round-report-card .user-pass-score {
  color: #05D871;
}
.assessment-round-report-card .user-reject-score {
  color: #FF0058;
}
.pagePadding {
  padding-right: 1.5cm;
  padding-left: 1.5cm;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}
.viewReportEditStatus {
  top: 121px !important;
  right: 80px !important;
}
.DownloadReport .heading {
  width: 21cm;
  margin: 0px auto 10px;
  border: none;
}
.DownloadReport .heading h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #000000;
}
.DownloadReport .heading button {
  background: #f44d5e;
  border-radius: 5px;
  color: #ffffff;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
}
