.lms-container {
  background: #f5f6fa;
  min-height: 100vh;
}

.study-plan-card {
  border-radius: 14px;
  background: linear-gradient(297deg, #ecf3dc -133.19%, #fff 101.96%);
  box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
  padding: 14px;
  margin: 14px 0;
  min-height: 204px;
}

.studyplan-status-hld {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.studyplan-duration-text {
  color: rgba(32, 34, 36, 0.7);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.studyplan-duration-text img {
  vertical-align: bottom;
}

.studyplan-status-percentage {
  border-radius: 10px;
  background: linear-gradient(
    289deg,
    #ff9f4b -6.91%,
    rgba(255, 226, 75, 0) 114.37%
  );
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding: 2px 8px;
}

.studyplan-title {
  color: #202224;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.studyplan-percentage-representation-hld {
  background: rgba(217, 217, 217, 0.5);
  width: 100%;
  height: 3px;
  margin: 26px 0;
}

.studyplan-percentage-representation {
  background: linear-gradient(
    97deg,
    #00f4a4 2.88%,
    #00f4c1 18.54%,
    #00ccf2 35.1%,
    #5ef3a8 47.07%,
    #ebff00 64.25%
  );
  height: 100%;
}

.studyplan-details-hld {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.studyplan-total-learners ,.noMatchFoundCard {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 167px;
  white-space: nowrap;
}

.studyplan-startedAt {
  color: #7a7a7a;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.studyplan-dayChange {
  color: #606060;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.studyplan-top-learner {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin-left: -10px;
  border: 2px solid #ffffff;
}

.studyplan-top-learner:first-child {
  margin-left: 0px;
}

.completed-study-plan-card {
  background: linear-gradient(
    288deg,
    rgba(194, 223, 232, 0.4) 17.81%,
    rgba(194, 223, 232, 0.4) 44.71%,
    rgba(252, 255, 124, 0.4) 134.04%
  );
}

.completed-study-plan-card .studyplan-status-percentage {
  background: #ffffff;
}

.studyplan-completed-hld {
  background: linear-gradient(90deg, #00d8dc 0.06%, #8cf082 99.92%);
  margin: 0 -14px -14px;
  padding: 8px 15px;
  border-radius: 0px 0 10px 10px;
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.studyplan-completed-hld svg {
  vertical-align: text-bottom;
}

.download-csv-button {
  border: none;
  background: none;
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.view-play-ground-btn {
  border-radius: 4.69px;
  background: linear-gradient(274deg, #f44d5e -5.92%, #f46b4d 114.01%);
  border: 1px solid transparent;
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 5px 10px;
  margin-left: 8px;
}

.view-play-ground-btn img {
  filter: invert(99%) sepia(96%) saturate(1%) hue-rotate(322deg)
    brightness(105%) contrast(100%);
  vertical-align: text-top;
}

.overview-heading h2 {
  color: #000;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.study-plan-card-inner {
  border-radius: 14px;
  background: #fff;
  box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
  padding: 27px 24px 24px;
  min-height: 264px;
}

.study-plan-card-inner .card-heading {
  color: #202224;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.36px;
  font-family: Inter;
}

.study-plan-card-inner .card-text {
  color: #202224;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter;
}

.studyplan-inner-percentage-hld {
  background: rgba(217, 217, 217, 0.5);
  width: 100%;
  height: 3px;
  margin: 10px;
  border-radius: 50px;
}

.studyplan-inner-gradient {
  border-radius: 50px;
}

.studyplan-inner-gradient-0 {
  background: linear-gradient(97deg, #f1f543, #de4ae1, #4ca8e5);
  height: 100%;
}

.studyplan-inner-gradient-1 {
  background: linear-gradient(97deg, #24ff1b, #678ef3);
  height: 100%;
}

.studyplan-inner-gradient-2 {
  background: linear-gradient(97deg, #9e8fff, #ebcbff, #ff9c28);
  height: 100%;
}

.studyplan-inner-gradient-3 {
  background: linear-gradient(97deg, #ff78c1, #01d6ce, #f9e63b);
  height: 100%;
}

/* top performer card */
.study-plan-card-inner .user-card {
  border: 1px solid #e0e0e0;
  padding: 16px;
  border-radius: 8px;
}

.score-tab {
  padding: 6px 10px;
  border-radius: 50px;
  background: #ececec;
  color: #7a7a7a;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}
.score-tab-1 {
  background: #e6f8f1;
  color: #00b977;
}
.score-tab-2 {
  background: #ebecfe;
  color: #3c59f5;
}
.score-tab-3 {
  background: #ffefee;
  color: #ea7b14;
}
.study-plan-carousel-hld {
  margin-top: -9px;
}
.study-plan-card-inner .user-card .studyplan-inner-user-image {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin: auto;
  border: 2px solid #ffffff;
}

/* slick css  */
.study-plan-card-inner .slick-slide {
  padding: 0px 6px;
}
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  padding: 0px 26px;
}

@media (min-width: 768px) {
  .slick-slider .slick-caption {
    display: block !important;
  }
}

.slick-slider .slick-caption {
  padding-bottom: 45px;
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  color: #ffffff;
  text-align: center;
  z-index: 3;
  display: none;
}

.slick-slider .slick-slide > div:first-child {
  position: relative;
}

.slick-slider .slick-icons {
  position: relative;
  top: 5px;
}

.slick-slider .slick-image {
  width: 100% !important;
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  height: 100%;
  padding: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  color: transparent;
  outline: none;
  background: transparent;
  width: 8%;
  z-index: 2;
  opacity: 0.5;
}
button.slick-arrow.slick-prev {
  left: -5.5%;
}
button.slick-arrow.slick-next {
  right: -5.5%;
}
.slick-prev {
  left: 0;
}

.slick-prev::before {
  content: "<";
  font-weight: 600;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #979797;
  font-size: 30px;
  width: 100%;
}

.slick-next {
  right: 0;
}

.slick-next::before {
  content: ">";
  font-weight: 600;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #979797;
  font-size: 30px;
  width: 100%;
}

.slick-list {
  z-index: 1;
}

.slick-dots li,
.slick-dots li button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  padding: 5px;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.user-name-text {
  color: #202224;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  line-height: normal;
  height: 34px;
  -webkit-line-clamp: 2;
  letter-spacing: -0.029px;
}

.filterHld {
  border-radius: 4.69px;
  border: 0.6px solid #e0e0e0;
  background: #fff;
  padding: 4px;
}

.filterHld .menuhld {
  color: rgba(32, 34, 36, 0.7);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 18px;
  cursor: pointer;
}

.filterHld .active-menuhld {
  border-radius: 3px;
  background: #f3f4f8;
}

.filterHld .menuhld img {
  opacity: 0.7;
  vertical-align: text-top;
}

.overview-card-hld {
  border-radius: 14px;
  background: #fff;
  box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
  padding: 20px 30px;
  min-height: 285px;
}

.overview-card-hld h5 {
  color: #202224;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.bar-chart-hld {
  height: 200px;
}

.doughnut-label {
  color: #b5b5b5;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}

.doughnut-label-color-representation {
  width: 11px;
  height: 11px;
  border-radius: 3px;
}

.green-color {
  background: linear-gradient(
    179.8deg,
    rgba(0, 227, 126, 0.38) 0.17%,
    #00e37e 75.27%
  );
}

.blue-color {
  background: linear-gradient(
    179.8deg,
    rgba(1, 113, 220, 0.38) 0.17%,
    #0173df 75.27%
  );
}

.violet-color {
  background: linear-gradient(
    179.8deg,
    rgba(128, 97, 219, 0.38) 0.17%,
    rgba(128, 97, 219, 0.94) 75.27%
  );
}

.skyblue-color {
  background: linear-gradient(
    179.8deg,
    rgba(0, 221, 213, 0.38) 0.17%,
    #00ddd5 75.27%
  );
}

.total-number-of-participants {
  color: #242424;
  text-align: center;
  font-family: Inter;
  font-size: 28px;
  font-weight: 500;
}

.total-participants-heading {
  font-size: 24px;
  text-align: left;
}

.course-completion-labels {
  width: 9px;
  height: 9px;
  border-radius: 50px;
}

.studyplan-percentage-status {
  color: #242424;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.course-studyplan-dayChange {
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  border-radius: 2px;
  padding: 2px 4px;
}

.studyplan-positive-dayChange {
  color: #377e36;
  background: #e0f0e4;
}

.studyplan-negative-dayChange {
  color: #b12f30;
  background: #f7eaea;
}

.studyplan-not-started-dayChange {
  color: rgba(191, 121, 42, 0.9);
  background: rgba(255, 187, 108, 0.4);
}

.user-activity-hld {
  border-radius: 14px;
  background: #fff;
  min-height: 620px;
  box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
}
.user-details-hld {
  border-radius: 14px;
  background: linear-gradient(115deg, #f5f6fa 2.96%, #fff 151.86%);
  box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
}
.lms-search-participants {
  background: #f5f6fa;
  border: 0.6px solid #d5d5d5;
  border-radius: 50px;
  padding: 6px 12px;
  color: #202224;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
}
.lms-search-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 12px;
}
.lms-participants-card {
  display: flex;
  align-items: center;
  padding: 14px 10px;
}
.lms-participants-card:hover,
.lms-participants-active-card {
  border: 0.5px solid #15c3da;
  background: linear-gradient(
    90deg,
    rgba(241, 245, 67, 0.1) 6.33%,
    rgba(76, 168, 229, 0.1) 95.93%
  );
  border-radius: 6px;
}
.lms-user-img {
  border-radius: 50%;
}
.lms-participants-user-details-hld {
  width: 100%;
  margin-left: 7px;
}
.lms-participants-rank {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 11px;
  font-weight: 600;
  padding: 4px 10px;
  border-radius: 5px;
  background: linear-gradient(290deg, #eef0fb -7%, #fff 214.93%);
  border: 1px solid #e0e5f1;
}
.lms-participants-card:hover .lms-participants-rank,
.lms-participants-active-card .lms-participants-rank {
  background: linear-gradient(
    289deg,
    #ff9f4b -6.91%,
    rgba(255, 226, 75, 0) 114.37%
  );
  border: none;
}
.lms-overall-score-hld {
  list-style-type: none;
  display: inline-flex;
  padding: 0;
  margin: 0;
}
.lms-overall-score-li::after {
  content: "|";
  margin: 0 4px;
  color: #d9d9d9;
  width: 0.5px;
}
.lms-overall-score-li:last-child:after {
  display: none;
}
.lms-overall-score-li {
  display: inline-block;
  color: #636465;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}
.lms-overall-score-li span,
.lms-user-score span,
.overall-score span,
.score-tab span {
  font-weight: 700;
}
.lms-user-score {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}
.lms-user-detatails-username {
  color: #222;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
}
.lms-user-detatails-description {
  color: #6b7280;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
}
.overall-score {
  border-radius: 4px;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 20px;
  margin-right: 20px;
}
.today-score-bg {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    linear-gradient(121deg, #00ccf2 -66.77%, #ddf040 160.44%);
}
.overall-score-bg {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    linear-gradient(273deg, #db96f8 -16.87%, #3749a6 282.15%);
}
.lms-rank {
  background: linear-gradient(
    289deg,
    #ff9f4b -6.91%,
    rgba(255, 226, 75, 0) 114.37%
  );
  padding: 8px 16px;
  border-radius: 10px 0 0 10px;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}
.lms-user-details-hld {
  padding-left: 25px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.lms-courses-card {
  border-radius: 4.69px;
  background: #fff;
  box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
  padding: 10px;
  min-width: 150px;
}
.lms-courses-card-info-text {
  color: #6b7280;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}
.days-covered-hld {
  background: #fff;
  box-shadow: drop-shadow(6px 6px 54px rgba(0, 0, 0, 0.05));
  border-radius: 20px 20px 0 0px;
}
.course-completed-text {
  color: #757575;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}
.days-covered-card-hld {
  border-bottom: 0.8px solid #e0e0e0;
  padding: 15px 0;
}
.lms-present-day-card {
  background: linear-gradient(
    90deg,
    rgba(0, 72, 195, 0.2) 0%,
    rgba(206, 0, 111, 0.2) 101.25%
  );
  color: rgba(191, 121, 42, 0.9);
}
.lms-present-day-card .lms-courses-card-info-text,
.lms-present-day-card .course-completed-text {
  color: #000;
}

.carousel-arrow-hld {
  padding: 2px 10px;
  border-radius: 4.69px;
  border: 0.8px solid #e0e0e0;
  background: #fff;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  cursor: pointer;
}
.carousel-arrow-hld.carousel-arrow-hld-right {
  left: -8px;
}
.carousel-arrow-hld.carousel-arrow-hld-left {
  right: -8px;
}
.studyplan-dayChange-positive {
  color: #4ea184;
}
.studyplan-dayChange-negative {
  color: #f44d5e;
}
.user-activity-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 16px 0;
}
.user-activity-pagination .carousel-arrow-hld {
  position: unset;
  transform: none;
  display: inline-block;
}
.lms-user-details-separator {
  border-top-color: #d9d9d9;
}
.score-overlay {
  background: rgba(0, 0, 0, 0.05);
  position: absolute;
  top: -15px;
  left: 0;
  bottom: -15px;
  right: 0;
}
.lms-no-data-hld {
  background: linear-gradient(
    90deg,
    rgba(0, 72, 195, 0.1) 0%,
    rgba(206, 0, 111, 0.1) 101.25%
  );
  border-radius: 30px;
  text-align: center;
  padding: 50px 0;
}
.lms-no-data-text {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
}
.lms-no-data-call-to-action {
  border-radius: 10px;
  background: linear-gradient(
    289deg,
    #ff9f4b -6.91%,
    rgba(255, 226, 75, 0) 114.37%
  );
  border: none;
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 6px 40px;
}
.lms-no-data-cntr {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.user-activity-users-list {
  height: 650px;
  overflow: scroll;
}
.studyplan-top-learner > div {
  min-width: 30px;
  min-height: 30px;
  width: 30px !important;
}
.content-percentage-width {
  width: 35px;
}
.bar-graph-loader > div {
  min-width: 100%;
  width: 100% !important;
  min-height: 100%;
}
.top-content-hld {
  height: 162px;
  overflow: scroll;
  padding-right: 3px;
}
.top-content-hld::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.top-content-hld::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #E0E0E0;
}
.days-plan-list-hld {
  min-height: 410px;
}
.cursor-no-drop {
  cursor: no-drop;
}
.study-plan-details {
  min-height: auto;
}
.noMatchFoundCard{
  background: #F5F6FA;
  border: 0.6px solid #D5D5D5;
  padding: 5px 10px;
  margin: 0px 10px;
  width: 93%;
  max-width: none;
  border-radius: 15px;
}