.Subscription-plan-container .subscriptionheadinghld {
  background: #fff;
  padding: 10px 20px;
}
.Subscription-plan-container .primaryBtnActive,
.createPlanModal .primaryBtnActive {
  padding: 4px 20px;
}
.w200 {
  width: 200px;
}
.Subscription-plan-container .subsheading {
  color: #575757;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  cursor: pointer;
}
/* .Subscription-plan-container .plansCntr {
  width: 295px;
} */
.Subscription-plan-container .plansHld {
  background: #fff;
  border-radius: 10px;
  padding: 25px 30px;
  margin-bottom: 20px;
}
.plansHld .unpublish-btn {
  border-radius: 50px;
  border: 1px solid #f44d5e;
  background: #ffffff;
  display: block;
  width: 100%;
  height: 40px;
  color: #f44d5e;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.plansHld .planFeatureshld {
  height: 185px;
}
.plansHld .publish-btn {
  border-radius: 50px;
  border: 1px solid #0fc475;
  background: #0fc475;
  display: block;
  width: 100%;
  height: 40px;
  color: #ffffff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.createPlanModal .modal-title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.createPlanModal .modal-header {
  border-bottom: none;
}
.createPlanModal .modal-content {
  border: none;
}
.createPlanModalBody label {
  color: #3c3e4d;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
}
.createPlanModal .plan-form-group {
  margin-bottom: 24px;
}
.createPlanModal .plan-form-group .form-control {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
}
.createPlanModal .plan-form-group input::placeholder,
.createPlanModal .plan-form-group textarea::placeholder,
.createPlanModal .plan-form-group .light-label {
  color: #bbb;
  font-size: 15px;
}
.createPlanModal .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: #ddd;
}
.createPlanModal .ck.ck-toolbar > .ck-toolbar__items {
  z-index: 0;
}
.createPlanModal .ck.ck-toolbar {
  border: 1px solid #ddd;
}
.createPlanModal .opacity50 {
  opacity: 0.5;
}
.nodata-img {
  width: 109px;
  height: 114px;
}
.nodata-text {
  color: #000;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.subscription-tabs {
  display: flex;
}
.subscription-tabs p {
  padding: 0 20px;
  position: relative;
}
.subscription-tabs .active-subscription-tab::after {
  content: "";
  background: #f44d5e;
  position: absolute;
  bottom: -15px;
  width: 100%;
  height: 3px;
  left: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.faqQuestion {
  color: #363835;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
}
.faqAnswer {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  color: #6b7280;
}
.faqbtn {
  background: #fff2f3;
  border-radius: 50px 0 0 50px;
  padding: 6px 16px;
  color: #f44d5e;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  border: #fff2f3;
}
.faqEditbtn {
  border-radius: 50px;
  background: #f2f3f6;
  padding: 8px;
  border: #f2f3f6;
  width: 115px;
  color: #6b7280;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  z-index: 999;
}
.faqdeletebtn {
  background: rgba(166, 170, 179, 0.2);
  border-radius: 0 0 8px 8px;
}
.faqEditbtn span {
  padding: 0 16px 0px 10px;
}
.faqOptiondropdown {
  position: absolute;
  left: 0;
  top: 35.5px;
}
.faqdropdownActiveeditbtn {
  border-radius: 8px 8px 0 0;
}
