.listContainerCandidates {
  border: 0.5px solid #e2e2e9;
  border-bottom: none;
  border-right: none;
  border-top-left-radius: 10px;
}
.applicantsDropdown {
  right: auto;
  top: auto;
}
.jobsApplicantsCntr {
  margin-top: 50px;
}
.applicantDetailsInfoSection {
  border: 0.5px solid #e2e2e9;
  border-top-right-radius: 10px;
}
.manageJobDropDown {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 25px;
  top: 46px;
  background-color: #ffffff;
  color: #666666;
  text-align: left;
  box-shadow: 0px 2px 20px 10px rgb(0 0 0 / 5%);
  border-radius: 5px;
  z-index: 1;
}
.applicantFilterByDropDown {
  z-index: 1;
  background: #ffffff;
  width: 160px;
  box-shadow: 0px 2px 20px 10px rgb(0 0 0 / 5%);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: absolute;
  color: #666666;
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  right: 0;
  top: 28px;
}
.manageJobDropDown > span {
  text-align: left;
  padding: 15px 20px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: #666666;
}
.applicantFilterByDropDown :hover {
  color: #3c3e4d !important;
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  background: #f2f3f4;
}
.applicantDataSortByFilter :hover {
  color: #3c3e4d !important;
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  background: #f2f3f4 !important;
}
.manageJobDropDown :hover {
  color: #3c3e4d !important;
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  background: #f2f3f4 !important;
}
.manageJobDropDown > img {
  width: 20px;
}
.enterpriseApplicantHeader {
  background: #f2f3f4;
  margin-top: 50px;
}
.applicantDataSortByFilter {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 90px;
  width: 21%;
  background-color: #ffffff;
  color: #666666;
  text-align: left;
  box-shadow: 0px 2px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.applicantFilter {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: #3c3e4d;
}
.applicantAssessBtn {
  background-color: #f2f3f4;
  height: fit-content;
}
.applicantStartInterviewBtn {
  height: fit-content;
}
.applicantStartInterview {
  background-color: #3c3e4d;
  border: 1px solid #3c3e4d;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  border-radius: 50px;
  padding: 6px 30px;
}
.applicantParticipantContact {
  font-weight: 500;
  font-size: 14px;
  color: #3c3e4d;
  font-family: "Inter";
}
.applicantEarnedBadge {
  background: #f2f3f4;
  padding-left: 17px;
  padding-right: 8px;
}
.applicantEarnedBadge > img {
  margin-top: -16px;
}
.applicantUserDetails {
  padding: 14px 20px;
}
.applicantReviewSection {
  background: #ffffff;
  border: 0.5px solid #e2e2e9;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.applicantWorkExperience {
  padding: 16px 17px;
  color: #000000;
}
.applicantWorkExperience > p {
  line-height: 24px;
}
.applicantWorkExperienceHeader {
  background: #ffffff;
  border: 0.5px solid #e2e2e9;
  padding: 11px 9px;
  border-radius: 5px;
}
.applicantDataVerticalBar {
  width: 0px;
  height: 16px;
  background: #000000;
  border: 0.5px solid #3c3e4d;
  border-radius: 5px;
}
.applicantResumeDownload {
  background: #f2f3f4;
  padding: 15px;
}
.applicantInfoHeader {
  background: #f2f3f4;
  padding: 15px 20px;
  border-top-right-radius: 10px;
}
.applicantEducation {
  padding: 16px 17px;
}
.applicantcurrentCompanyData {
  color: #000000;
}
.applicantReviewSectionQuestion {
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}
.applicantReviewSectionAnswer {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}

.applicantUserDetails > div > div {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}
.applicantFilterByDropDown > span,
.innerDropDown > p {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
  text-align: left;
  padding: 15px 10px;
}
/* .innerDropDown > p {
  padding-left: 30px;
} */
.innerDropDown > p:hover {
  background: #f2f3f4;
  color: #000000;
}
.innerDropDown > .salary-range:hover {
  background: #fff;
  color: #666666;
}
.applicantFilterByDropDown > .dropDownItem {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.applicantFilterByDropDown > .dropDownItem > .innerContainer {
  display: none;
}
.applicantFilterByDropDown > .dropDownItem:hover > .innerContainer {
  display: block;
  position: absolute;
  background: transparent;
  left: -170px;
  width: 170px;
  padding: 10px;
}
.applicantFilterByDropDown > .dropDownItem:hover > .innerContainerSalary {
  left: -340px;
  width: 340px;
  top: 0px;
}
.applicantFilterByDropDown
  > .dropDownItem:hover
  > .innerContainer
  > .innerDropDown {
  background: #ffffff;
  box-shadow: 0px 2px 20px 10px rgb(0 0 0 / 5%);
  border-radius: 5px;
  color: #666666;
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}

.applicantFilterByDropDown > .dropDownItem .filterDropdownImg {
  background: url("../../../../assets/Icons/Jobs/rightArrow.svg");
  background-size: 6px;
  width: 10px;
  background-repeat: no-repeat;
}
.applicantFilterByDropDown > .dropDownItem:hover .filterDropdownImg {
  background: url("../../../../assets/Icons/Jobs/rightArrowActive.svg");
  background-size: 6px;
  width: 10px;
  background-repeat: no-repeat;
}
.noApplicants p {
  font-family: "inter";
  font-weight: 500;
  font-size: 18px;
  margin-top: 38px;
}
.noApplicants_Icon {
  margin-top: 150px;
  margin-left: -50px;
}
.noApplicants .shareToLinkedIn {
  margin-top: 31px;
  background-color: #f44d5e;
  font-family: "Roboto";
  color: #ffffff;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  padding: 10px 27px;
  cursor: pointer;
  border: none;
}
.noApplicants .shareToLinkedIn img {
  margin-left: 16px;
}
.applicantDataSortByFilter > span {
  text-align: left;
  padding: 15px 20px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: #666666;
}

.applicantDataSortByFilter > img {
  width: 20px;
}
.applicantJobDetail {
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 20px 0px;
}
.applicantDots {
  width: 25px;
  height: 20px;
  cursor: pointer;
  margin-left: 33px;
}
.applicantDots:hover {
  border-radius: 5px;
  background-color: #f3f3f6;
}
.jobsApplicantsCntr .SkeletonLoading {
  height: 30px;
  width: 120px;
  margin: 0px 4px;
}
.jobsApplicantsCntr .imageLoader {
  width: 50px;
  height: 50px;
}
.jobsApplicantsCntr .loderH-30{
  height: 30px;
}
.applicantCategory .btn-tabs {
  box-sizing: border-box;
  width: 120px;
  height: 30px;
  background: #ffffff;
  border: 0.5px solid #e2e2e9;
  border-radius: 50px;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 600;
  font-family: Inter;
}
.applicantCategory .active-tab {
  background-color: #3c3e4d;
  color: #ffffff;
}

.applicantManageJobButton .Icon {
  margin-top: -3px;
  width: 20px !important;
}
.applicantJobDetailDot {
  background: rgb(102, 102, 102);
  width: 5px;
  height: 5px;
  border-radius: 50px;
}
.AppliacantJobDesignation {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}
.ApplicantJobStatusActive {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: #2bbd10;
}
.ApplicantJobStatusDraft {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: #0d0d0d;
}
.ApplicantJobStatusClosed {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: #de2f1f;
}
.applicantJobLocation {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}
.applicantDataSearchInput > input {
  all: unset;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
.applicantDataSearchInput > input::placeholder {
  color: #666666;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
.applicantDataSearchInput {
  display: inline;
  padding: 7px 15px;
  background: #f2f3f4;
  border: 0.5px solid #ffffff;
  border-radius: 50px;
}
.ApplicantPaginationNum {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: #3c3e4d;
}

.apllicantCalendar .react-calendar-heatmap .color-scale-1 {
  fill: #91e7ab;
  stroke-width: 5;
  ry: 2;
}
.apllicantCalendar .react-calendar-heatmap .color-scale-2 {
  fill: #09c269;
  stroke-width: 5;
  ry: 2;
}
.apllicantCalendar .react-calendar-heatmap .color-scale-3 {
  fill: #009f53;
  stroke-width: 5;
  ry: 2;
}
.apllicantCalendar .react-calendar-heatmap .color-scale-4 {
  fill: #046d3c;
  stroke-width: 5;
  ry: 2;
}

.apllicantCalendar .react-calendar-heatmap .color-empty {
  fill: #f2f3f4;
  stroke: #e2e2e9;
  stroke-width: 1;
  ry: 2;
}

.apllicantCalendar {
  background: #ffffff;
  border: 1px solid #e2e2e9;
  border-right: none;
  padding: 35px 17px;
}

.apllicantCalendar .react-calendar-heatmap-weekday-label {
  x: -10px !important;
}

.apllicantCalendar .react-calendar-heatmap-month-label,
.apllicantCalendar .react-calendar-heatmap-weekday-label {
  font-weight: 500;
  font-size: 10px;
  fill: #3c3e4d;
}
.applicantForScroll {
  overflow: auto;
  height: calc(100vh - 270px);
}
.enterpriseApplicantHeader .backToJobsArrow {
  cursor: pointer;
}
