.allInterviewScheduleTimePicker,
.rc-time-picker-panel {
  top: 469px !important;
}

.rc-time-picker-panel-select ul::-webkit-scrollbar,
.rc-time-picker-panel-select::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.rc-time-picker-panel-select ul,
.rc-time-picker-panel-select {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.addInterviewBtn {
  padding: 8.5px 37px;
  margin-top: 43px;
}
.addInterview img {
  margin-left: -54px;
}
.loaderCard {
  width: 270px;
  height: 148px;
  margin-bottom: 20px;
  margin-right: 20px;
  background: #f2f3f4;
  border-radius: 14px;
}
.filterTextInterview {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.modalHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin: 0px 0px 30px 0px;
}
.datepicker .rdt {
  width: 100%;
}
.datepicker .rdt input {
  padding-left: 50px;
  outline: none;
}
.datepicker .rdt input:focus {
  outline: none;
}
.addInterview .noInterviewHeading {
  margin-top: 41px;
  font-family: "Inter";
  color: #000000;
  font-weight: 500;
  font-size: 18px;
  line-height: 21.78px;
}
.addInterview .noInterviewTagline {
  color: #b0b0b4;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 10px;
}
.all-interview-container > h1 {
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  color: #3c3e4d;
  padding: 16px;
  border-bottom: solid #d9d9d9 1px;
}
.all-interview-container .secondaryColoredBtn img {
  width: auto;
}
.no-padding {
  padding: 0 !important;
}

.all-interview-container {
  padding: 0;
  background-color: #f2f3f4;
  height: calc(100vh - 50px);
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.rdtPicker {
  z-index: 99999999 !important;
  background: #ffffff;
  border: 0.195652px solid #d9d9d9;
  box-shadow: 0px 9.78261px 19.5652px rgba(0, 0, 0, 0.05);
  border-radius: 9.78261px;
}
.interview .tableMenu {
  display: none;
  right: 27px;
  top: -2px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1;
  cursor: pointer;
}
.interview .tableMenu .menuItem {
  color: black;
  padding: 12px 30px;
  display: flex;
  text-align: left;
}
.interview .hypenTable {
  margin-left: -52px;
}

.interview .acceptancyAwait {
  padding: 2px 13px 4px 7px;
  color: #f88f33;
  background: url("../../../../assets/Icons/Interview/New/orangeAcceptancy.svg")
    no-repeat;
}
.interview .acceptancyAccept {
  padding: 2px 23px 4px 7px;
  color: #05d871;
  background: url("../../../../assets/Icons/Interview/New/greenAcceptancy.svg")
    no-repeat;
}
.interview .acceptancyDecline {
  padding: 2px 23px 4px 7px;
  color: #f44d5e;
  background: url("../../../../assets/Icons/Interview/New/redAcceptancy.svg")
    no-repeat;
}
.interview .tableMenu .menuItem:hover {
  background: #f2f3f4;
}

.interview .tableMenu p {
  display: inline;
  margin-right: 13px;
}
.createInterviewModal .css-g1d714-ValueContainer {
  background: #ffffff;
  border: 0.4px solid #d9d9d9;
  border-radius: 5px;
  position: relative;
  padding: 0px 8px 0px 30px !important;
}
.createInterviewModal .css-1hwfws3 {
  border: 0.4px solid #d9d9d9;
  border-radius: 5px;
  padding: 0px 8px 0px 30px !important;
}
.createInterviewModal .css-1q9xf2f-Placeholder {
  margin-left: 4px;
}
.createInterviewModal .css-1hyly4f-control {
  border-bottom: none;
}
.createInterviewModal .css-1pndypt-Input {
  margin: 0px;
  height: 39px;
}
.createInterviewModal .MhUserSearch input {
  height: 33px !important ;
}

.createInterviewModal .css-1b2b2p0-control {
  border-bottom: none !important;
}

.createInterviewModal .searchIconModal {
  position: absolute;
  bottom: 108px;
  left: 26px;
  width: 15px;
  height: 15px;
}
.interview .menuIcons {
  padding: 2px;
  margin: 0px 15px 0px 0px;
  display: inline-block;
  width: 18px;
  height: 18px;
}

.user-image {
  width: 20%;
  border-radius: 50%;
}

.scheduled-on {
  white-space: initial !important;
  margin-bottom: 0 !important;
}

.scheduled-on-list-item {
  list-style-type: none;
  display: inline;
}

.no_of_rounds {
  border-radius: 50px;
  background: #e4e4e4;
  color: #3c3e4d;
  border: 1px solid #d8d8d8;
  padding: 5px 10px 7px 15px;
  width: 138px;
}
/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.interview .show {
  display: block !important;
  border-radius: 5px;
  overflow: hidden;
}

.alert {
  margin-bottom: 0 !important;
}

.table-row {
  padding: 7px 33px 10px 265px;
  box-shadow: 0 14px 24px 0 rgb(0 0 0 / 10%);
}

.no_background {
  background: none;
  border: none;
}

.three_dots {
  background: none;
  color: black;
  border: none;
  display: inline-block;
}

.three_dots:after {
  content: "\2807";
}

th {
  color: #c4c4c4;
  font-weight: 400;
}

.pagination {
  justify-content: center;
}

.page-link {
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 4px;
  color: #a5a6aa;
}

.page-item.active {
  background-color: white;
  color: #f04f5f;
}

.page-item.active .page-link {
  background-color: #f04f5f;
  border-color: #f04f5f;
}

td {
  white-space: nowrap;
}

.button-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 40px;
  border-bottom: 1px solid #e2e2ea;
  background: white;
}

.button-container > div {
  justify-content: center;
}

.button-items {
  justify-content: center;
  width: 100%;
}

/* .button-items > ul > li { */
/* padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px; */
/* border: solid #e4e4e4;
  padding: 11px 39px 10px 38px !important;
} */

.filter-button {
  margin: 10px;
}

.button-items > ul > li > a {
  text-decoration: none;
  color: #3c3e4d;
}

.center {
  text-align: center;
  width: 300px;
}

.right {
  text-align: right;
}

.button-items > ul {
  list-style-type: none;
  text-decoration: none;
  padding-left: 0;
}

.button-items > ul > li {
  display: inline-block;
  cursor: pointer;
  padding: 6px 17px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #666666;
}
.createInterviewBtn {
  background: linear-gradient(286.29deg, #f44d5e 2%, #fc7269 94.83%);
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 50px;
  padding: 7px 25px;
  border: none;
}
.button-items > ul > li:first-child {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.button-items > ul > li:last-child {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.button-items > ul > li.active {
  color: #000000;
  position: relative;
}
.button-items > ul > li.active::before {
  content: "";
  background: #f44d5e;
  position: absolute;
  width: 100px;
  height: 3px;
  border-radius: 5px 5px 0px 0px;
  bottom: -16px;
}
.InterviewTimings {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #858585;
}
.msgInfo {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px;
  text-align: center;
  color: #858585 !important;
  margin: 30px auto 41px;
}

/* Dropdoen START */
.dropbtn-right {
  padding: 6px 20px;
  font-size: 14px;
  cursor: pointer;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.dropbtn-right span {
  font-size: 12px;
  font-family: "Roboto";
}
.dropbtn-right img {
  width: 14px;
}

.dropdown-right {
  position: relative;
  display: inline-block;
}

.dropdown-content-right {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content-right a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}
#upcoming > span,
#completed > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #666666;
  background: #eeeeee;
  border-radius: 12.5px;
  padding: 2px 7px;
}

.dropdown-content-right a:hover {
  background-color: #f1f1f1;
  color: black;
}

.button-items.right {
  width: 100%;
}

.dropdown-content-right > a > button {
  border: none;
  background: none;
}
.listTablehld .interviewStatus {
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  margin-left: 10px;
  line-height: 14px;
}
.listTablehld .interviewStatusImg {
  height: 20px;
  width: 20px;
}
.interviewStatusImgCard {
  width: 27px;
  transform: translateX(17px);
}
.interviewStatusCardView {
  margin-left: -17px;
}
.reportBtn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}
@media only screen and (max-width: 992px) {
  .button-items.center {
    display: inline;
    width: 100%;
  }

  .button-items.right {
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    padding-right: 0px;
  }

  .button-container {
    width: 100%;
    display: block !important;
    margin-top: 20px;
  }
}
.greyScheduleBtn {
  background: #92929d !important;
  border: none;
  color: #ffffff;
}

#card {
  display: none;
  margin-bottom: 20px;
  text-align: center;
  overflow: auto;
  height: calc(100vh - 180px);
}
#card::-webkit-scrollbar {
  display: none;
}

/* DROPDOWN END */

/* CARD Start */
/*Flex items*/
.list {
  display: flex;
  flex-wrap: wrap;
}

.list-item {
  display: flex;
  padding: 0.5em;
  width: 100%;
}

.listTablehld .table .interview-plan-hld {
  border-bottom: 0 !important;
  padding: 0 !important;
  border-radius: 10px !important;
}
@media all and (min-width: 20em) {
  .list-item {
    width: 99%;
    margin: 1%;
  }
}

@media all and (min-width: 40em) {
  .list-item {
    width: 48%;
    margin: 1%;
  }
}

@media all and (min-width: 60em) {
  .list-item {
    width: 30%;
    margin: 1%;
  }
}

.list-content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  width: 100%;
}

.list-content p {
  flex: 1 0 auto;
}

.list-item.create {
  color: #f04f5f;
  border: dashed;
}

.list-item {
  border: #e4e4e4 solid;
}

.create-interview {
  background: none;
  border: none;
  text-align: center;
  color: #f04f5f;
}

.width {
  width: 50%;
  font-weight: 500;
  padding-right: 0;
  text-align: right;
}

.no_design_button.btn.btn-primary {
  padding-top: 0;
}

.no_design_button {
  background: none;
  border: none;
  color: black;
}

.awaiting-response {
  color: #f88f33;
}

.grey-icon {
  color: #f3f3f3;
}

.bottom-row > .column {
  width: 33.33%;
}

.bottom {
  width: 35%;
}

.start {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  text-align: right;
  border-radius: 20px;
  width: 100%;
  margin-right: -10px;
}

.chat {
  background: none;
  color: #ff9f4b;
}

#start-button {
  margin: 0;
  padding: 0 !important;
}

.fade.alert.alert-warning.show {
  font-size: small;
  padding: 8px 10px 9px !important;
}

.btn-primary:hover {
  color: black;
  background: #e4e4e4;
}

.no_design_button.btn.btn-primary:hover {
  background: white;
}

.three_dots.btn.btn-primary:hover {
  background: white;
}

.no_of_rounds.chat.btn.btn-primary {
  background: white;
  color: #ff9f4b;
}

.page-link:hover {
  color: grey;
}

.table-flex {
  display: flex;
}

.data {
  font-size: 14px;
}

.table-row-heading {
  font-size: 16px;
  font-weight: 500;
}

.user-data {
  font-size: 14px !important;
}

.inline {
  display: inline;
}

.td-margin {
  display: block;
  margin-right: 26px;
  border-bottom-width: 0px !important;
}

.list-item {
  padding: 16px 0 15px 21px;
  border-radius: 5px;
  border: solid 1px #e4e4e4;
}

.shadow {
  box-shadow: 0 14px 24px 0 rgb(0 0 0 / 10%);
}

.awaiting-response-div {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 10px;
  display: flex;
}

.avatar {
  width: 10%;
  float: left;
  border-radius: 15px;
}

.all-interviews {
  margin-left: 30px;
  margin-right: 30px;
}

.create-card.card {
  border: none !important;
}

.create-body {
  border: dashed 1px #f44d5e;
  color: #f44d5e;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-body > .card-text {
  text-align: center;
}

.create-col {
  display: flex;
  /* flex : 0 0 33.33%; */
  /* max-width: 33.33%; */
  height: 170px;
  width: 295px;
}

.create-card {
  width: 100%;
}

.create-text {
  font-size: 16px;
  font-weight: 500;
}

.col-title {
  margin-right: 0;
  padding-right: 0;
  text-align: center;
}

.col-link {
  text-align: right;
  margin: 0;
  padding: 0;
}

.col-btn {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: none;
  background: #f44d5e;
  color: white;
}

.no-padding-1 {
  padding-right: 0;
  height: 30px;
}

.start-button {
  text-align: right;
  margin: 0;
  padding: 0;
}

.avatar.all-interview {
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 50px;
}

.interviewCardHld .start-button {
  background: url("../../../../assets/Icons/Interview/New/greyCamera.svg");
  border-radius: 50px;
  width: 100%;
  background: #f2f3f4;
  color: #92929d;
  text-align: center;
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  height: 34px;
  border: none;
  padding: 8px 24px;
}
.interviewCardHld .start-button:hover {
  background: linear-gradient(286.29deg, #f44d5e 2%, #fc7269 94.83%);
  color: #fff;
}
.viewType {
  border-radius: 5px;
  padding: 0px 6px 4px 6px;
  border: 0.5px solid #e2e2e9;
}

.custom-margin {
  margin-bottom: 5px;
}

.three_dots_center {
  text-align: center;
  margin: auto 0;
}

.alert-custom {
  font-size: 12px;
}

.text-status {
  font-size: 14px;
  display: inline-block;
  padding-left: 5px;
  line-height: initial;
}

.user-name {
  font-size: 12px;
  padding-top: 5px;
  text-align: left;
}

.user-chat {
  text-align: right;
}

.interviewer-name {
  margin-top: 10px;
  font-size: 14px;
}

.left-align {
  text-align: left;
}

.custom-color {
  color: #c4c4c4;
}

/* CARD End */

/* Media QUERIES */

.custom-margin-1.row {
  margin-right: -17px;
}

@media screen and (max-width: 914px) {
  .no-padding-1.col-md-3.col-4 {
    width: 50%;
    text-align: right;
    margin: auto;
  }

  .custom-margin-1.row > .col-md-6.col-8 {
    width: 100%;
    margin-bottom: 10px;
  }

  .user-chat {
    width: 50%;
    text-align: left;
  }
}

/* 7/1/2022 END */

/* sample card */
.interviewCardHld {
  background: #ffffff;
  border: 1px solid #f2f3f4;
  border-radius: 5px;
  box-shadow: 0;
  min-height: 162px;
  font-family: "Inter";
}

.interviewCardHld:hover {
  box-shadow: 0px 20px 24px rgba(0, 0, 0, 0.04);
  border: 1px solid #f2f3f4;
  transition: box-shadow 0.5s ease-out;
}

.link-button {
  /* Link button when normal */
  border-radius: 50%;
  padding: 3px;
  background: white;
  border: solid #f44d5e 1px;
  color: #f44d5e;
  height: 1.5em !important;
  width: 1.5em !important;
  margin-right: 7px;
}

.interviewCardHld:hover .link-button {
  /* Link Button when hover*/
  border-radius: 50%;
  padding: 3px;
  background: #f44d5e;
  border: solid #f44d5e 1px;
  color: white;
  height: 1.5em !important;
  width: 1.5em !important;
  /* Link Button  when hover*/
}

.start-button.btn.start-btn-active {
  background-color: #3c3e4d;
}

.interviewCardHld .jobRole {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3c3e4d;
  text-transform: capitalize;
  margin: 8px 0px;
}
.createInterviewModal .orText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #666666;
}
.createInterviewModal .rdtPicker td,
.createInterviewModal .rdtPicker th {
  border: none;
  font-size: 12px;
  font-family: "Inter";
  color: #858585;
}
.createInterviewModal .rdtPicker th {
  color: #121212;
  font-weight: 400;
}
.createInterviewModal .rdtPicker th span {
  color: #f44d5e;
  margin-top: 5px;
  font-size: 28px;
}
.createInterviewModal .rdtPicker thead tr:first-of-type th {
  color: #3c3e4d;
  font-weight: 500;
  font-size: 13.3px;
}
.createInterviewModal .rdtPicker td.rdtDay:hover {
  background: #f44d5e;
  border-radius: 8px;
  color: #ffffff;
}
.createInterviewModal .rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 30px;
  width: 30px;
}
.createInterviewModal .rdtPicker td.rdtToday {
  color: #3c3e4d;
  font-weight: 600;
}
.rc-time-picker-panel-select {
  border: none;
  width: auto;
  margin-left: 0px;
}
.interviewCardHld .copylinkImg {
  width: 25px;
}

.interviewCardHld .timings {
  text-align: initial;
  background: #ffecdb;
  border-radius: 5px;
}

.interviewCardHld .timings li {
  list-style: none;
  display: inline;
  font-size: 14px;
  color: #3c3e4d;
  font-weight: 400;
}

.interviewCardHld .timings li:after {
  content: "\00b7";
  padding: 0;
  margin: 0 5px;
  vertical-align: middle;
  font-size: xx-large;
  line-height: 0;
}
.timings-card-view .interviewScheduleDate {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #92929d;
}
.timings-card-view .interviewDateDot {
  background: #92929d;
  width: 5px;
  height: 5px;
  margin: 0px 5px;
  padding: 2px;
  border-radius: 50%;
}

.interviewCardHld .timings li:last-child:after {
  content: none;
}

.interviewCardHld .interviewerTxt {
  font-weight: normal;
  font-size: 14px;
  color: #c4c4c4;
}

.interviewCardHld .interviewerName {
  font-weight: normal;
  font-size: 14px;
  color: #000;
}

.interviewCardHld .statusbar {
  width: 25%;
  height: 2px;
  background: #66bd66;
}

.createInterviewCard {
  border-radius: 5px;
  border: dashed 1px #f44d5e;
}

.create-interview {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  color: #f44d5e;
}

.createInterviewCard {
  height: 65%;
}

.three_dots_color {
  color: #c4c4c4;
}

.grey {
  background: #f3f3f3 !important;
}

.green {
  background: #e1f1dd !important;
}

.green-icon {
  color: #67bb55 !important;
}

.red {
  background: #fff1f2 !important;
}

.red-icon {
  color: #f44d5e !important;
}

.list-table-heading {
  font-size: 12px;
  font-weight: 400;
  border-bottom: 0px;
  color: #666666;
  white-space: nowrap;
  word-spacing: 4px;
  line-height: 14px;
  font-family: "Roboto";
}

.user-image-table-view {
  width: 2vw;
  border-radius: 50%;
}

.interview-active:hover {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}
.listTablehld tr th {
  padding: 1rem 0.5rem 0.7rem 1.2rem;
  width: 15%;
  background: transparent;
}

.listTablehld thead {
  background: #f2f3f4;
}

td:not(:first-child) {
  border-left: 0px;
}

td:first-child {
  border-right: 0px;
}

td:not(:first-child):not(:last-child) {
  border-right: 0px;
}

.timings-card-view {
  margin-bottom: 0px;
  font-size: 12px;
  display: inline;
  list-style-type: none;
  padding-left: 0px;
}

.candidate-name {
  padding-left: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* or 117% */

  color: #3c3e4d;
}

.interview-title {
  font-size: 16px;
  font-weight: 500;
  display: table-cell;
  vertical-align: middle;
  text-transform: capitalize;
}

.interview-title-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding: 20px !important;
  color: #3c3e4d;
}

.awaiting-acceptance {
  font-size: 12px;
}

.awaiting-acceptance > svg {
  color: #f88f33;
}

.no-of-rounds {
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  background-color: #e6e8ec;
  color: #92929d;
  background: white;
  font-weight: 500;
  display: table-cell;
  vertical-align: middle;
  margin-left: 20px;
}
.no-of-rounds:hover {
  cursor: default;
}
.horizontalThreeDot {
  border: none;
  background: none;
}
.round-completed:hover {
  background: #92929d;
  color: white;
  cursor: pointer;
}

.start-interview {
  color: #3c3e4d !important;
  border: solid #3c3e4d 1px;
  background: white;
}

.start-interview:hover {
  border: solid 1px #e4e4e4;
  background-color: #e4e4e4;
  color: white !important;
}

.wihout_hover {
  background-color: #e4e4e4;
  color: white !important;
}

.start-interview:hover .arrow-icon-show-on-hover {
  display: inline-block;
  /* padding: 10px; */
}

.user-icon-table {
  display: table-cell;
  vertical-align: middle;
}

.scheduled-on-table {
  display: table-cell;
  vertical-align: middle;
}

.chat-table {
  display: table-cell;
  /* vertical-align: middle;  */
}

.copy-link-text {
  padding-left: 5px;
  border: none;
  background: white;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #92929d;
}

.chat-icon-table {
  width: 80%;
}

.copy-link-class {
  padding: 6px 16px;
  background: white;
  font-size: 12px;
  border: none;
}

.copy-link-icon {
  padding-right: 0px;
  height: 18px;
  width: 1.5rem;
}

.no-of-rounds-status {
  padding-right: 10px;
  font-weight: 500;
  font-family: "Roboto";
  color: #ffff;
}
.startInterviewInactive .no-of-rounds-status {
  color: #92929d;
}
.start-interview {
  /* color: white !important; */
  margin-right: 15px;
}

.more-button {
  color: #c4c4c4;
}

.no-view {
  background-color: inherit;
}

.copied {
  background-color: #e4e4e4;
  transition: width 1s ease;
  border-radius: 50px;
}
.no-padding-copy-link .copied {
  padding-right: 15px;
}
.no_of_rounds.start-interview-new {
  border: solid #3c3e4d 1px;
  background: white;
  background-color: white !important;
  border: solid #3c3e4d 1px !important;
  color: #3c3e4d;
  margin-right: 17px;
  padding-top: 5px;
}

.no-of-rounds-status-new {
  color: #3c3e4d;
  /* font-size: 14px; */
  font-weight: 500;
  padding-right: 10px;
}
.candidateChat {
  background: url("../../../../assets/Icons/Interview/New/chatGrey.svg");
  background-color: #f2f3f4;
  background-repeat: no-repeat;
  background-size: center;
  background-position: center;
  padding: 15px 31px;
  width: 15px;
  height: 15px;
  border-radius: 50px;
}
.candidateChat:hover {
  background: url("../../../../assets/Icons/Interview/New/chatWhite.svg");
  background-color: #92929d;
  background-repeat: no-repeat;
  background-size: center;
  background-position: center;
  cursor: pointer;
}
.start-interview-active {
  background: #3c3e4d;
  color: white !important;
}

.green-card {
  background-color: #e1f1dd;
}

.green-cirlce {
  color: #67bb55 !important;
}

.red-card {
  background-color: #fff1f2;
}

.red-circle {
  color: #f44d5e;
}
.green-circle {
  color: #67bb55 !important;
}

.green-circle {
  color: #67bb55 !important;
}

@media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
  .user-image-table-view {
    width: 4vw;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .user-image-table-view {
    width: 4vw;
  }
}

.listTablehld .font-size {
  font-size: 12px !important;
  color: #666666;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

.no-of-rounds.copy-link-class {
  color: #3c3e4d;
}

.middle-align {
  vertical-align: middle;
}

.middle-align:hover .chat-icon-table {
  display: none !important;
}

.middle-align-orange-icon {
  display: none;
  width: 185%;
}

.middle-align:hover .middle-align-orange-icon {
  display: block;
}

.jobRole {
  color: #3c3e4d;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 175px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.positionDelete {
  right: 13px !important;
}
.menu-show {
  left: -159px;
  top: 10px;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

.menu-show {
  background: white;
}

.three-dots-1 {
  background: none !important;
}

#three_dots {
  position: relative !important;
  display: inline-block !important;
}
.card-view {
  padding: 6px !important;
}
.list-view-dropdown {
  position: absolute !important;
  top: 22px;
  left: -124px;
  border-radius: 6px;
  border: solid #c4c4c4 1px;
  background: #ffffff;
}

.disabled-class {
  background-color: #eff0f1;
}

.menu-btn {
  font-size: 12px;
  width: 100%;
  border: none;
  font-family: "Inter";
  padding: 12px 23.5px !important;
  background: #ffffff;
  text-align: left;
}
.menu-btn:hover {
  background: #f2f3f4;
}

.dropdown-li-btn {
  list-style-type: none;
}

.dropdown-ul {
  margin: 0px !important;
}

.calendar-view-button {
  padding: 6px 12px !important;
}

/* Calendar View START */
.calendar-view-container {
  padding: 0;
  background-color: white;
  border-radius: 4px;
  height: 100% !important;
}

.calendar-view-container {
  height: 978px !important;
}

/* For disabling the today/back/next button */

.heading {
  text-align: center;
  font-size: 24px;
  padding-top: 10px;
  border-bottom: solid #d9d9d9 1px;
}

.rbc-month-view {
  border: none;
}

.rbc-header + .rbc-header {
  border: none !important;
}

/* Disable the date ie. 15 APril 2021 */
.rbc-toolbar-label {
  display: none;
}

.rbc-header {
  border-bottom: none;
  font-weight: 400;
  font-size: 18px;
  color: #b1b2b8;
}

.rbc-toolbar-label {
  display: none;
}

.rbc-month-header {
  padding-bottom: 15px;
}

.rbc-event {
  background: white;
  padding: 0px !important;
  /* background-color: #e4e4e4; */
  padding-top: 0px !important;
  /* background-color: #F4F4F4; */
}

.rbc-event-content {
  font-size: 14px;
  color: #3c3e4d;
}

.user-icon-calendar-view {
  width: 12%;
  border-radius: 50%;
  padding: 0px;
}

.name {
  font-size: 12px;
  color: #3c3e4d;
}

.rbc-overlay {
  width: 20%;
}

.rbc-overlay > .rbc-event > .rbc-event-content > div > img {
  width: 8% !important;
}

.rbc-row-bg {
  /* border-top: #c4c4c4 solid 1px; */
  border-left: #c4c4c4 solid 1px;
}

.rbc-month-row {
  border-top: #c4c4c4 solid 1px;
  border-bottom: solid #c4c4c4 0.5px;
}

.rbc-day-bg {
  border-right: solid 1px #c4c4c4;
}

.rbc-header > span {
  text-transform: uppercase;
}

.rbc-selected {
  background-color: #e4e4e4 !important;
}

.more-properties {
  z-index: 999999 !important;
}

#calendar {
  display: none;
}

.rbc-calendar {
  /* display: none; */
  min-height: 600px;
}

/* Calendar Viwe END */

.text-left {
  text-align: left !important;
}

.random-class {
  /* top: 50px;
    left: -30px; */
  border-radius: 5px;
  background: #ffffff;
  left: -30px;
  top: 38px;
}

.random-class-1 {
  /* top: 50px;
    left: -51px; */
  border-radius: 5px;
  background: #ffffff;
  left: -19px;
  top: 38px;
}

.custom-table {
  width: 120% !important;
}

.selected {
  font-size: 14px;
  padding-left: 10px;
  color: #3c3e4d;
}

.no-background-timings {
  background: none !important;
}

.no-padding-copy-link {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.custom-padding-selected {
  padding-right: 0px !important;
  padding-left: 0.5rem !important;
}

.create-button-add {
  background: #f44d5e;
  border-radius: 50px;
  border: none;
  color: #ffffff;
  font-size: 14px;
  margin-left: -5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 1.2rem !important;
}
.roundBorderRadius {
  border-radius: 50px;
}
.alignment-button {
  text-align: right;
}

.add-button-card {
  padding-top: 10rem !important;
}

.custom-height {
  min-height: auto !important;
}

.search-icon {
  width: 8% !important;
  height: auto !important;
}

.verified-icon {
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 100px;
  right: 28px;
}

.completed-image {
  padding-right: 5px;
}
.chat-icon-display {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.chat-icon-display:hover {
  content: url("../../../../assets/Icons/Interview/New/Orange-Msg.svg");
}

.img-fluid.me-2.ChatIcon:hover {
  content: url("../../../../assets/Icons/Interview/New/orange-small.svg");
}

.custom-padding-loader {
  padding-top: 35px !important;
}

.startInterviewActive,
.startInterviewInactive:hover {
  border-radius: 50px;
  background: linear-gradient(286.29deg, #f44d5e 2%, #fc7269 94.83%);
  font-size: 12px;
  border: none;
  padding: 6px 0;
  color: white;
}
.startInterviewInactive:hover {
  padding: 8px 19px;
}
.startInterviewInactive:hover .no-of-rounds-status {
  color: #ffff;
}
.startInterviewInactive:hover .start-btn-img {
  background: url("../../../../assets/Icons/Interview/New/interview.svg");
}
.startInterviewInactive {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  background: #fff;
  border: none;
  border-radius: 50px;
  padding: 8px 19px;
  color: #92929d;
  background: #f2f3f4;
}
.start-button:hover .start-btn-img {
  background: url("../../../../assets/Icons/Interview/New/interview.svg");
}

.start-btn-img {
  background: url("../../../../assets/Icons/Interview/New/greyCamera.svg");
  height: 10px;
  width: 17px;
  display: inline-block;
  margin-left: 17px;
}

.custom-name {
  background: #e3e3e3;
  border-radius: 50px;
  padding-top: 6px !important;
  padding-left: 10px !important;
  padding-bottom: 6px !important;
  padding-right: 10px !important;
}

.padding-left {
  padding-left: 15px !important;
}
.candidateimage {
  width: 30px;
  height: 30px;
  border-radius: 50px;
}
.listTablehld {
  background: #f2f3f4;
  padding: 0px 40px;
  height: calc(94vh - 166px);
  overflow: auto;
}
.listTablehld::-webkit-scrollbar {
  display: none;
}
.listTablehld {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.listTablehld table {
  font-family: "Inter";
}
.listTablehld table tbody tr {
  background: #ffffff;
}
.listTablehld .table > :not(caption) > * > * {
  /* border-bottom-width: 0px; */
  vertical-align: middle;
  border-color: #e4e4e4;
}
.listTablehld table td {
  padding: 0px 0px;
  border-top: 0px solid transparent;
  border-bottom: 2px solid #f2f3f4 !important;
}

.listTablehld table td:first-child,
.listTablehld table th:first-child {
  padding-left: 1.5rem !important;
  border-left: none;
}
.listTablehld table td:first-child {
  font-family: "Inter";
  font-weight: 600;
  color: #3c3e4d;
  padding: 29px 20px;
  border-radius: 10px 0px 0px 10px;
  -moz-border-radius: 1em;
}
.listTablehld table td:last-child {
  width: 155px;
  border-right: none;
  border-radius: 0px 10px 10px 0px;
}
.listTablehld table td:nth-child(5) {
  width: 140px;
}
.noInterviewContainer {
  background: #ffffff;
  height: calc(100vh - 50px);
}
.roundsInCardView {
  border: none;
  border-radius: 50px;
  color: #92929d;
  background: white;
  border: 1px solid #e4e4e4;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  padding: 5px 20px;
}
.CandidateSection {
  display: flex;
  font-size: 12px;
  font-family: "Inter";
  font-style: normal;
  margin: 8px 0px;
  align-items: flex-end;
}
.greenBtnBackground {
  background: #05d871;
  border: none;
  border-radius: 50px;
  padding: 8px 25px;
}
.redBtnBackground {
  background: #f65461;
  border: none;
  border-radius: 50px;
  padding: 8px 25px;
}
.reportIcon {
  width: 15px;
  height: 10px;
  margin-top: -2px;
}
.CandidateTitle {
  color: #92929d;
  font-weight: 400;
}
.candidateName {
  color: #3c3e4d;
  font-weight: 600;
  margin-top: -2px;
}
.HThreeDots {
  margin-top: -20px;
  width: 15px;
}
.NoOfRoundHld {
  width: 100px;
  padding: 10px 0;
  font-size: 12px;
}
.cardViewHld {
  overflow: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.padding-left {
  padding-left: 15px !important;
}

.some-random-class {
  background-image: url("https://i.picsum.photos/id/794/200/300.jpg?hmac=uZge4lPPf2bQz7AS6pyH7_nwhFp9IQ3OPKOpQ33Zypk");
}

.rbc-btn-group {
  display: none;
}

.rbc-header + .rbc-header {
  border: none !important;
}

.rbc-toolbar-label {
  display: none;
}

.rbc-header {
  border-bottom: none;
  font-weight: 400;
  font-size: 18px;
  color: #b1b2b8;
}

.rbc-month-header {
  padding-bottom: 15px;
}

.rbc-event {
  background: #f4f4f4;
  padding: 0px !important;
  padding-top: 0px !important;
}

.rbc-event-content {
  font-size: 14px;
  color: #3c3e4d;
}

.rbc-overlay {
  width: 20%;
}

.rbc-overlay > .rbc-event > .rbc-event-content > div > img {
  width: 8% !important;
}

.rbc-row-bg {
  border-left: #c4c4c4 solid 1px;
}

.rbc-month-row {
  border-top: #c4c4c4 solid 1px;
  border-bottom: solid #c4c4c4 0.5px;
}

.rbc-day-bg {
  border-right: solid 1px #c4c4c4;
}

.rbc-header > span {
  text-transform: uppercase;
}

.rbc-selected {
  background-color: #e4e4e4 !important;
}
.rbc-row-segment {
  padding: 2px 1px 1px 1px !important;
}

/* Remove the grey background of disabled dates */
.rbc-off-range-bg {
  background: none;
}

.rbc-today {
  background: none;
}

.rbc-date-cell.rbc-now {
  color: #f44d5e;
  font-size: 20px;
}

.rbc-date-cell {
  font-size: 20px;
  position: relative;
  top: 50%;
  transform: translateY(160px);
}

.rbc-event-content > div > a {
  text-decoration: none !important;
}

#calendar {
  overflow: auto;
  height: calc(100vh - 290px) !important;
}
.copiedModal {
  background: #05d8711a;
  color: #05d871;
  text-decoration: none;
  border: none;
  padding: 2px 8px;
  border-radius: 5px;
  margin: 0px 38px 0px -46px;
}
.linkCopySection {
  min-height: 28px;
}
.awaitingResponse {
  color: #f88f33;
}
.tabcontent {
  background: #f2f3f4;
  padding-bottom: 1px;
}
.ratingStars {
  min-width: 80px;
}
.masking-data-hld .interview-active {
  filter: blur(8px);
}
.visibility-hidden {
  visibility: hidden;
}
@media (min-width: 1440px) {
  .listTablehld .font-size {
    font-size: 14px !important;
  }
  .listTablehld .interviewStatus {
    font-size: 12px;
  }
}
@media (min-width: 1920px) {
  .interview .tableMenu p {
    font-size: 12px;
  }
}
