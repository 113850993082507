.jobsDropDown {
  position: absolute;
  background: #ffffff;
  background-color: #ffffff;
  box-shadow: 0px 2px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  z-index: 9;
  width: max-content;
}
.jobsDropDown p {
  padding: 10px 20px;
  cursor: pointer;
  color: #666666;
  font-weight: 500;
  font-size: 12px;
}
.jobsDropDown p:hover {
  background: #f2f3f4;
}
