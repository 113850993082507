.resumeCntr {
  /* height: calc(100vh - 63px); */
  font-family: Inter;
}

.resumeCntr.searchBack {
  height: 100vh;
  background: linear-gradient(181deg,
      rgba(255, 146, 139, 0.12) 5.69%,
      rgba(255, 188, 153, 0.12) 42.7%,
      rgba(255, 188, 153, 0.12) 78.56%,
      rgba(255, 188, 153, 0) 110.02%);
}

.resumeSearchContainer {
  max-width: 850px;
  margin: auto;
  text-align: center;
  padding-top: 70px;
}

.resumeSearchContainer .resumeSearchHld {
  background: linear-gradient(to right,
      #a5ccfb,
      #823a7f,
      #f98ba8,
      #ff718e,
      #ff928b,
      #ffbc99);
  padding: 2px;
  border-radius: 50px;
}

.resumeSearchContainer .resumeSearchInput {
  border: none;
  background-color: transparent;
  width: 100%;
}

.resumeSearchContainer .resumeSearchInput:focus-visible {
  outline: none;
}

.resumeSearchContainer .resumeSearchInput::placeholder,
.graySearchHld input::placeholder {
  color: #939393;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.35px;
}

.resumeSearchContainer .resumeSearchInputHld {
  border-radius: 48px;
  height: 50px;
  width: 100%;
  background-color: #fff;
}

.resumeCntr .resumeSearchContainer .headingText {
  color: #3c3e4d;
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  line-height: 150%;
}

.resumeCntr .resumeSearchContainer .headingGradientText {
  background: linear-gradient(97deg,
      #ff718e -19.59%,
      #f98ba8 28.5%,
      #ff928b 86.02%,
      #ffbc99 123.74%,
      #ffbc99 161.45%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.aiSearch {
  height: 20px;
  width: 20px;
}

.resumeSearchContainer .searchBtn {
  border: none;
  width: 40%;
  text-align: center;
  height: 50px;
  border-radius: 50px;
  background: linear-gradient(97deg,
      #ff718e -19.59%,
      #f98ba8 28.5%,
      #ff928b 86.02%,
      #ffbc99 123.74%,
      #ffbc99 161.45%);
  color: #ffffff;
  font-weight: 500;
}

/* user card */

.resumeCntr .userInfoCard {
  border: 1px solid #e2e2ea;
  background: #fff;
  padding: 10px 12px 10px 12px;
  border-radius: 10px;
  min-width: 507px;
  margin-right: 20px;
}

.resumeCntr .userInfoCardWhenUserDetails {
  min-width: 436px;
}

.userInfoCard .userImage {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #d9d9d9;
}

.resumeCntr .userInfoCard .viewResumeBtn {
  border: 1px solid #d9d9d9;
  border-radius: 50px;
  padding: 4px 25px;
}

.resumeCntr .grayBtnText {
  color: #575757;
  font-size: 12px;
  font-weight: 500;
}

.resumeCntr .userInfoCard .bookmarkHld {
  border-radius: 50px;
  border: 1px solid #d9d9d9;
  width: 34px;
  height: 34px;
  line-height: 30px;
  text-align: center;
}

.resumeCntr .userInfoCard .activebookmark {
  background: #ffc24b;
  border: 1px solid #ffc24b;
}

.resumeCntr .userInfoCard .downloadSvg {
  width: 34px;
  height: 34px;
}

.resumeCntr .userInfoCard .bookmarkSvg {
  height: 15px;
  width: 15px;
}

.resumeCntr .userInfoCard .useNameText {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  max-width: 215px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


/* filters */
.resumeFormGroup {
  position: relative;
}

.resumeFormGroup label {
  margin-bottom: 4px;
}

.resumeFormGroup .resumefilterInput,
.resumeCntr .grayBtn {
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  background: #fff;
  height: 36px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.resumeCntr .showMoreBtn {
  background: #d9d9d9;
}

.resumeFormGroup .resumefilterInput {
  width: 140px;
  padding: 10px 8px 10px 8px;
}

.resumeFormGroup .resumefilterInput:focus-visible {
  outline: none;
}

.resumeFormGroup .resumefilterInput::placeholder {
  color: #d9d9d9;
}

.resumeDropdown {
  position: absolute;
  top: 60px;
  width: max-content;
  min-width: 140px;
  border-radius: 6px;
  background: #fff;
  filter: drop-shadow(0px 0px 1px rgba(67, 90, 111, 0.47)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
  padding: 4px;
  z-index: 111;
  max-height: 260px;
  overflow: auto;
}

.resumeDropdown.wideFilter {
  max-width: 300px;
  display: flex;
  flex-wrap: wrap;
}

.resumeDropdown.wideFilter .resumeOptionHld {
  width: 140px;
}

.resumeDropdown .resumeDropdownOption {
  color: #242533;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  padding: 8px;
  width: 100%;
  min-width: 100px;
}

.resumeDropdown .resumeDropdownOption:hover {
  border-radius: 6px;
  background: rgba(217, 217, 217, 0.3);
  cursor: pointer;
}

.resumeFormGroup .dropDownArrow {
  position: absolute;
  bottom: 15px;
  right: 6px;
  width: 9px;
  height: 9px;
}

/* top searchbar */
.topSection {
  background: linear-gradient(179deg,
      rgba(153, 102, 221, 0.23) -211.46%,
      rgba(130, 58, 127, 0.23) -160.15%,
      rgba(249, 139, 168, 0.23) -110.65%,
      rgba(255, 113, 142, 0.23) -62.64%,
      rgb(255, 146, 139, 0.23) -17.64%,
      rgba(255, 188, 153, 0.23) 21.36%,
      rgba(255, 188, 153, 0.23) 45.36%,
      rgba(255, 188, 153, 0) 75.04%);
}

.graySearchHld {
  padding: 10px 16px;
  border-radius: 50px;
  border: 1px solid #d9d9d9;
  background: #fff;
  max-width: 578px;
}

.graySearchHld input {
  border: none;
  outline: none;
}

.resumeFormGroup .resumefilterInput.activeInput,
.user-list-cntr .activeInput {
  border: 1px solid rgba(235, 110, 110, 0.5);
  background: rgba(255, 240, 232, 0.5);
}

.resumeCntr .searchSvg {
  height: 14px;
  width: 14px;
}

.user-list-cntr {
  display: flex;
}

.resumeCntr .user-list-hld {
  height: calc(100vh - 200px);
  overflow: auto;
}

.resumeCntr .userInfoCard .darkBtnText {
  color: #000;
}

.resumeCntr .resumeCardPagination {
  background: #ffffff;
  padding: 24px 8px;
  border: none;
  border-top: 0.5px solid #e2e2e9;
}

.filterbookmarkIcon {
  filter: invert(87%) sepia(5%) saturate(0%) hue-rotate(151deg) brightness(101%) contrast(92%);
}

.activeFilterBookmark {
  filter: none;
}

.resumeCntr .bookmarked-btn {
  border-radius: 6px;
  border: 1px solid #fff5ed;
  background: #ffc24b;
  color: #000;
}

.resumeCntr .bookmarked-btn:focus-visible {
  outline: transparent;
}

.resumeCntr .highlightedText {
  color: #eb6e6e;
  font-weight: 700;
}

.resumeSubscriptionCard {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 24px 40px 0px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
}

.resumeSubscriptionCard .boldText {
  color: #242424;
  text-align: center;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.resumeSubscriptionCard .thinText {
  color: #363835;
  text-align: center;
  font-size: 12px !important;
  font-weight: 500 !important;
}
.resumeSubscriptionCard .upgradeNowBtn{
  background: #FFBB6C;
  padding: 10px 30px;
  text-align: center;
  border: none;
  border-radius: 50px;
}
.resumeSubscriptionCard .upgradeNowBtn, .resumeSubscriptionCard .premiumTag{
  color: #000;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.morefilterSection {
  border-bottom: 0.5px solid #E2E2EA;
}

.resumeCntr .selectedUserCard {
  background-color: #D5F1FF;
  position: relative;
}

.resumeCntr .selectedUserCard::before {
  background-color: #2aaff2;
  content: "";
  width: 2px;
  height: 60%;
  display: block;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translate(-50%, -50%);
  border-radius: 50px;
}

.resumeCntr .selectedUserCard .viewResume {
  background: #43A5D4;
  border: none;
}

.resumeCntr .scrollSection {
  overflow-y: scroll;
  overflow-x: hidden;
}

.resumeCntr .user-list-hidden {
  overflow: hidden;
}

.resumeCntr *::-webkit-scrollbar {
  display: none !important;
}

.resumeCntr * {
  -ms-overflow-style: none;
  scrollbar-width: none !important;
}

.resumeSubscriptionCard .resumeCardPagination {
  max-width: 1054px;
  background: #ffffff;
  padding: 24px 8px;
  border: none;
  border-top: 0.5px solid #e2e2e9;
}
.resumeCntr .premiumTag{
  background-color:rgba(255,187,108,0.23);
  padding: 5px 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
@media (min-width: 1250px) {
  .resumeCntr .candidate-info-cntr {
    width: 58%;
    ;
  }

  .resumeCntr .leftContainer {
    width: 42%;
  }
}