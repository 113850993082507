h1 {
  margin-bottom: 0;
}
body {
  background-color: #ffffff;
  width: 100%;
}
.addNewJob {
  height: 50px;
  columns: #000000;
  background-color: #f2f3f4;
  padding: 15px;
  position: relative;
}
.cityInputBox::placeholder {
  color: #b1b2b7;
}
.cityInputBox:focus {
  outline: none;
  border: 1px solid #e2e2e9;
  box-shadow: none;
}
.cityDropdown .dropDownList {
  padding: 6px 10px;
  cursor: pointer;
}
.cityDropdown {
  border: 1px solid #e2e2e9;
  border-radius: 4px;
  width: 100%;
  background: #ffffff;
  top: 10px;
  z-index: 9;
  overflow: auto;
  height: 170px;
}
.cityDropdown .dropDownList:hover {
  background-color: #f2f3f4;
}
.addJob {
  background-color: #ffffff;
  border-radius: 20px 20px 20px 20px;
  margin: 0 auto;
  /* max-width: 1400px; */
}
.job-Body {
  margin-top: 50px;
}

.addJobGoBack {
  cursor: pointer;
  width: 25px;
  height: 20px;
}

.lines {
  width: 50px;
  margin-top: 16px;
}
.steps {
  display: flex;
  justify-content: center;
  font-size: 14px;
  padding: 10px;
}
#experienceRange input {
  width: 100px;
}
#experienceRange .labelYrs {
  font-size: 12px;
  background-color: #e2e2e9;
  font: Roboto;
  height: 40px;
  width: 51px;
  padding: 10px;
  border-radius: 0px 50px 50px 0px;
  transform: translateX(-20px);
}
#experienceRange #maxYrs {
  margin-left: 10px;
}
#step-2 {
  padding: 2rem 3rem 3rem 3rem;
}
.stepper {
  border-radius: 50%;
  text-align: center;
  height: 32px;
  width: 32px;
  cursor: pointer;
}
.active_step {
  background-color: #3c3e4d;
  color: #ffffff;
}
.inactive_step {
  background-color: #e2e2e9;
  color: #3c3e4d;
}
.steps p {
  padding-top: 5px;
}
#salaryRange {
  display: flex;
  width: 450px;
  align-items: center;
  margin-top: 10px;
}
#salaryRange #minSalary {
  width: 150px;
}
#salaryRange #maxSalary {
  width: 150px;
}
#salaryRange .inputBoxes {
  margin-left: -15px;
}
.dashExperience {
  width: 15px;
  color: #7f7f7f;
  margin-left: -10px;
}
.dashSalary {
  width: 20px;
  color: #7f7f7f;
  margin-left: 20px;
}
.priceLabel {
  margin-left: 20px;
}

.labelRupee {
  background-color: #f2f2f2;
  border: 1px solid #e2e2e9;
  width: 45px;
  padding: 7px 20px;
  color: #3c3e4d;
  border-radius: 20px 0px 0px 20px;
  transform: translateX(18px);
}

.ck-content {
  border-radius: 0 0 5px 5px !important;
  min-height: 200px;
}
.btn-pink {
  background-color: #f44d5e !important;
  color: #ffffff;
  font-size: 16px;
}
.jobsDescriptionEditor .ck.ck-editor__editable_inline {
  border: 1px solid #e2e2e9;
}
.jobsDescriptionEditor
  .ck-rounded-corners
  .ck.ck-editor__top
  .ck-sticky-panel
  .ck-toolbar {
  border: 1px solid #e2e2e9;
  border-bottom: none;
  padding: 6px;
  background: #ffffff;
}
.jobsDescriptionEditor
  .ck.ck-editor__main
  > .ck-editor__editable:not(.ck-focused) {
  border-color: #e2e2e9;
}
/* skill tag input */
.form-group label {
  font-size: 14px !important ;
  margin-top: 20px;
  font-weight: 400;
  margin-bottom: 15px;
}

.skillsArea .skillsTagInput {
  border-radius: 20px;
  background-color: #ffffff;
  padding: 3px 5px;
  border: 1px solid #e2e2e9;
}

.skillsArea .skillsTagInput .skillsTagItem {
  background-color: #ededee;
  color: #000000;
  display: inline-block;
  font-size: 12px;
  border-radius: 30px;
  height: 25px;
  padding: 18px 5px;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}
.skillsArea .skillsTagInput .skillsTagItem .removeTagBtn {
  border: none;
  color: #6666;
  border-radius: 50%;
  font-size: 18px;
  margin: 0px 5px;
}
.skillsArea .skillsTagInput .inputBoxTags {
  border: none;
  outline: none;
  border-radius: 20px;
  height: 25px;
  padding: 0px 5px;
  margin: 5.4px 1px;
}
/* .skillTags .rti--container {
  border-radius: 50px !important;
  padding: 4px 10px;
  border: 1px solid #e2e2e9 !important;
  background-color: #ffffff;
  overflow: hidden;
}
.skillTags .rti--input {
  border: none !important;
  height: 40px;

  margin-left: 10px;
}
.skillTags .rti--tag {
  padding: 10px 10px;
  height: 40px;
  margin: 2px 2px;
  font-size: 14px;
  border-radius: 50px;
}
.skillTags .rti--tag span {
  color: #666666;
}
.skillTags .rti--tag button {
  margin-top: -8px;
  height: 8px;
} */

/* add Question */
.questionsTag .rti--container {
  border: none !important;
  width: 100%;
}
.questionsTag .rti--input {
  width: 100%;
  border: 1px solid #e2e2e9;
  border-radius: 20px;
}
.questionsTag .rti--tag {
  width: 100%;
  justify-content: space-between;
  border: 1px solid #e2e2e9;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 12px 23px;
}
.questionsTag .rti--tag span {
  background-color: #ffffff;
}
.noQuestions {
  border: 1px solid #e2e2e9;
  border-radius: 10px;
  width: 50%;
  margin: 0px auto;
  padding: 20px;
}
.noQuestions label {
  padding-top: 9px;
}

.paraInactive {
  text-align: center;
  color: #b1b2b8;
  font-size: 14px;
  height: 17px;
  font-weight: 400;
  display: none;
}
.addQuestionContainer {
  width: 50%;
  margin: 0 auto;
  padding: 0px 20px;
}
.questions .inputBox {
  border: 1px solid #e2e2e9;
  border-radius: 10px;
  padding: 20px;
  width: 50%;
  margin: 10px auto;
  font-size: 14px;
}
.questions .inputBox p {
  color: #3c3e4d;
}
.questionBox {
  margin: 5px auto;
  padding: 10px 15px;
  width: 50%;
  border-radius: 10px;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  background-color: #ffffff;
}
.questionBox img {
  cursor: pointer;
}

.inputBox input {
  border: none;
  padding: 8px 20px;
  border-radius: 20px;
  width: 100%;
  height: 40px;
  outline: none;
}
.lineInfo {
  font-size: 14px;
  margin-bottom: 20px;
}
.addQuestionBtn {
  border: none;
  font-size: 14px;
  border-radius: 20px;
  width: 100%;
  height: 40px;
  outline: none;
  color: #b1b2b8;
  background-color: #ffffff;
}

.jobPostingBody {
  border-radius: 10px;
  padding: 32px;
  height: calc(100vh - 50px);
}
.addJobNavbarContainer {
  background-color: #3c3e4d;
  border-radius: 20px 0 0 20px;
  padding: 0;
  height: calc(100vh - 150px);
}
select option:first-child {
  color: green;
}

.addJobHeadingContainer {
  position: relative;
  padding: 1%;
  margin: 0;
  border: solid 1px #e5e5e5;
  border-radius: 20px 20px 0px 0px;
}

.addJobHeadingContainer::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 600px;
  background: #484a58;
  left: 50%;
  bottom: -2px;
  border-radius: 1px;
  transform: translate(-50%, 0);
}
.postJobForm {
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  font-size: 14px;
  font-weight: 400;
}

.form1 {
  width: 753px;
}
.form2 {
  width: 753px;
  margin: auto;
}
.nextButton {
  width: 200px;
  height: 40px;
  border: none;
  background-color: #e4e4ea;
  border-radius: 20px;
}

.postJobForm select {
  border-radius: 20px;
  padding: 0px 20px;
  height: 40px;
  border: 1px solid #e2e2e9;
  outline: none;
}
.postJobForm input,
#salaryRange input {
  border-radius: 20px;
  padding: 0px 20px;
  height: 40px;
  border: 1px solid #e2e2e9;
  outline: none;
}
.skillSethld {
  border: 1px solid #e2e2e9;
  border-radius: 20px;
  padding: 8px 20px;
  outline: none;
  background-color: #ffffff;
}
.skillSethld .tag-item {
  background-color: #ededee;
  color: #3c3e4d;
  display: inline-block;
  font-size: 12px;
  border-radius: 30px;
  height: 25px;
  padding: 0px 4px 2px 12px;
  display: inline-flex;
  align-items: center;
  margin: 0.3rem;
}
.skillSethld input{
  all: unset;
}
.postJobForm .css-pa1fhn-control {
  cursor: pointer;
}
.text-pink {
  color: #f44d5e;
}
.addJobHeadingContainer h2 {
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #212529;
}

/* .addJobContentContainer {
  padding: 0 10%;
} */
.addJobSelect .css-e6cq4e-option:active {
  background-color: #f2f3f4;
}
.addNewJobContentContainer {
  /* padding: 0 6%; */
  padding: 0px;
}
.form2 label {
  margin: 12px 10px;
}
/* form navigation styles */
#multi-step-form-container {
  background: #f2f3f4;
  border-radius: 10px;
  height: 100%;
}
#multi-step-form-container .formBodyMultiStep {
  overflow-x: scroll;
}
#multi-step-form-container .formBodyMultiStep::-webkit-scrollbar {
  /*chrome */
  display: none;
}
.text-center {
  text-align: center;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.submit-btn {
  border: 1px solid #0e9594;
  background-color: #0e9594;
}
.mt-3 {
  margin-top: 2rem;
}
.d-none {
  display: none;
}
.form-step {
  padding: 3rem;
}
#step-3 {
  padding: 2rem 3rem;
  height: calc(100% - 66px);
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

ul.form-stepper {
  counter-reset: section;
}
ul.form-stepper .form-stepper-circle {
  position: relative;
}
ul.form-stepper .form-stepper-circle span {
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
  transform: translateY(-50%) translateX(-50%);
}
.form-stepper-horizontal {
  position: relative;
  width: 300px;
  display: flex;
  margin-bottom: 0px;
  justify-content: center;
}
ul.form-stepper > li:not(:last-of-type) {
  margin-bottom: 0.625rem;
  transition: margin-bottom 0.4s;
}
.form-stepper-horizontal > li:not(:last-of-type) {
  margin-bottom: 0 !important;
}
.form-stepper-horizontal li {
  position: relative;
  display: flex;
  flex: 1;
  align-items: flex-start;
  transition: 0.5s;
}
.form-stepper-horizontal li:not(:last-child):after {
  position: relative;
  flex: 1;
  width: 67px;
  height: 1px;
  content: "";
  top: 32%;
}
.form-stepper-horizontal li:after {
  margin-top: 3px;
  background-color: #e2e2e9;
}

.form-stepper-horizontal li.form-stepper-completed:after {
  background-color: #4da3ff;
}
.form-stepper-horizontal li:last-child {
  flex: unset;
}
ul.form-stepper li a .form-stepper-circle {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 0;
  line-height: 1.7rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.38);
  border-radius: 50%;
}
.form-stepper .form-stepper-active .form-stepper-circle {
  background-color: #3c3e4d !important;
  color: #fff;
}
.form-stepper .form-stepper-active .label {
  color: #3c3e4d !important;
}
.form-stepper .form-stepper-active .form-stepper-circle:hover {
  background-color: #3c3e4d !important;
  color: #fff !important;
}
.form-stepper .form-stepper-unfinished .form-stepper-circle {
  background-color: #e2e2e9;
  color: #000000 !important;
}
.form-stepper .form-stepper-completed .form-stepper-circle {
  background-color: #3c3e4d !important;
  color: #fff;
}
.form-stepper .form-stepper-completed .label {
  color: #3c3e4d !important;
}
.form-stepper .form-stepper-completed .form-stepper-circle:hover {
  background-color: #3c3e4d !important;
  color: #fff !important;
}
.form-stepper .form-stepper-active span.text-muted {
  color: #fff !important;
}
.form-stepper .form-stepper-completed span.text-muted {
  color: #fff !important;
}
.form-stepper .label {
  font-size: 1rem;
  margin-top: 0.5rem;
}
.form-stepper a {
  cursor: default;
}
.css-g1d714-ValueContainer {
  height: 40px;
  position: relative;
}
.css-6j8wv5-Input {
  position: absolute;
}

@media only screen and (min-width: 2560px) {
  .addJobHeadingContainer h2 {
    font-size: 28px;
  }
}

/* For jobs description modal*/

.jobPostPreviewModal .modal-dialog {
  max-width: 800px;
}

.jobPostPreviewModal .modal-dialog

/* .jobDescription {
  max-width: 800px;
} */

.totalJobs {
  font-weight: 400;
  font-size: 12px;
  color: #797979;
}

.jobsListContainer {
  border: 0.5px solid #d9d9d9;
  border-top-left-radius: 5px;
}
.jobfreshness {
  font-weight: 400;
  font-size: 10px;
  color: #000000;
}

.jobTitle {
  font-weight: 500;
  font-size: 14px;
  font-family: "inter";
  color: #000000;
}

.jobCompany {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

.jobLocation {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}

.jobLoactionImg {
  width: 11.84px;
  height: 15px;
}

.jobCardContainer {
  padding: 10px 14px;
}

.jobDescriptionHeader {
  background: #f2f3f4;
  position: relative;
}

.jobDescriptionPositionName {
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  line-height: normal;
}

.jobDescriptionCompanyLogo {
  width: 82px;
}

.jobDescriptionHeader {
  background: #f2f3f4;
  position: relative;
  border-top-right-radius: 5px;
  max-height: 67px;
  margin-bottom: 10px;
}

/* .jobDescriptionCompanyDetails{
  position: absolute;
  top: 6px;
} */
.jobDescriptioncompanylogohld {
  border: 1px solid #E2E2E9;
  border-radius: 5px;
  background-color: #ffffff;
}
.jobDescriptioncompanylogohld img {
  width: 80px;
  height: 80px;
}
.jobDescription {
  border: 0.5px solid #d9d9d9;
  border-radius: 5px;
}

.jobDescriptionCompanyName {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.jobDescriptionSavejobImg {
  width: 26.87px;
}
.addNewJob img {
  position: absolute;
  left: 10px;
  cursor: pointer;
}

.jobDescriptionHeader .jobDescriptionApplyBtn {
  all: unset;
  background: #f44d5e;
  border-radius: 80px;
  font-weight: 500;
  height: fit-content;
  font-size: 14px;
  padding: 3px 18px;
  color: #ffffff;
}

.jobDescriptionHeader .jobDescriptionUpdateBtn {
  all: unset;
  background: #000000;
  height: fit-content;
  border-radius: 80px;
  font-weight: 500;
  white-space: nowrap;
  font-size: 14px;
  padding: 3px 18px;
  color: #ffffff;
}

.jobPostPreviewModalClose {
  position: absolute;
  bottom: 10px;
  right: -10px;
  cursor: pointer;
}
.modalJobHeader {
  border: none;
}

.jobDescriptionHeaderDot {
  height: 4px;
  width: 4px;
  background-color: #f44d5e;
  border-radius: 50%;
  vertical-align: middle;
  margin-bottom: 2px;
  display: inline-block;
}

.jobDescriptionHeader .jobDescriptionShareBtn {
  all: unset;
  background: #b2e6f3;
  padding: 4px 16px;
  margin-top: 2px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: 500;
  white-space: nowrap !important;
  font-size: 12px;
  color: #000000;
}

.jobDescriptionDataTitle {
  color: #666666 !important;
}

.jobDescriptionData {
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}

.jobDescriptionCompanyData {
  padding-left: 30px;
  font-weight: 400;
  margin-top: 28px;
  font-size: 12px;
  color: #000000;
}
.jobDescriptionCompanyData > a {
  all: unset;
  font-weight: 400;
  font-size: 12px;
  text-decoration-line: underline;
  color: #000000;
}

.jobDescriptionType {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}

.jobDescriptionType > span {
  font-weight: 400 !important;
  color: #666666 !important;
}
.jobDescriptionType > img {
  vertical-align: sub;
}

.jobDescriptionSkills {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  margin-top: 35px;
  padding-left: 10px;
}

.jobDescriptionSkillsList > span {
  padding: 8px 14px;
  background: #f2f3f4;
  border-radius: 50px;
  font-weight: 500;
  font-size: 12px;
  color: #666666;
}

.JobDescriptionSkillDot {
  height: 4px;
  width: 4px;
  background-color: #666666;
  border-radius: 50%;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 5px;
  display: inline-block;
}

.JobDescriptionSkillList {
  font-weight: 400;
  font-size: 13px;
  color: #666666;
  line-height: 18px;
  margin-left: 15px;
  margin-bottom: 15px;
}

.JobDescriptionSkillTitle {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}
.jobDescriptionPreview > p {
  font-size: 12px;
  color: #111111;
  font-weight: 400;
  line-height: 25px;
}
.jobDescriptionPreview img {
  max-width: 100%;
  height: auto;
}
.jobDescriptionPreview h1,
.jobDescriptionPreview h2,
.jobDescriptionPreview h3 {
  font-size: 18px;
}
.jobDescriptionPreview > h5,
.jobDescriptionPreview > h6 {
  font-size: 15px;
  color: #111111;
  font-weight: 500;
  line-height: 25px;
  text-transform: none;
}
.jobDescriptionPreview > ul li {
  list-style-type: disc;
  font-size: 12px;
}
.jobDescriptionPreview > ol,
.jobDescriptionPreview > ul {
  margin-left: 30px;
}
.jobDescriptionPreview > a {
  color: #f44d5e;
}
.jobDescriptionPreview ol li a {
  text-align: start;
  text-transform: none;
  display: block;
}

.jobDescriptionTechnicalSkillsTitle {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}
.recentlyPostedJobs {
  border: 0.5px solid #d9d9d9;
  border-radius: 5px;
  width: fit-content;
  padding: 4px 14px;
  width: 300px !important;
}

.recentlyPostedJobsTime {
  background: #e7f8e3;
  border-radius: 5px;
  width: fit-content;
  font-weight: 500;
  font-size: 10px;
  color: #000000;
  padding: 0px 10px;
}

.recentlyPostedJobsTitle {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.recentlyPostedJobsCompany {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

.SaveJobsGraySaveImg {
  width: 31px;
  margin-left: auto;
  margin-bottom: auto;
  margin-top: 5px;
}

.recentlyPostedJobsData {
  font-weight: 400;
  font-size: 12px;
  display: flex;
  text-align: center;
  color: #000000;
}

.recentlyPostedJobsContainer .slick-prev::before,
.recentlyPostedJobsContainer .slick-disabled.slick-prev::before,
.recentlyPostedJobsContainer .slick-next::before,
.recentlyPostedJobsContainer .slick-disabled.slick-next::before {
  font-size: 14px;
  cursor: pointer;
  border-radius: 50%;
  color: #666666;
  width: 23px;
  height: 23px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  background-color: #d9d9d9;
}

.JobsCompanyDetailsHeader {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

.JobsCompanyDetailsName {
  font-weight: 500;
  font-size: 14px;
  color: #3a3a3a;
}

.JobsCompanyDetails .JobsCompanyDetailsBtn {
  all: unset;
  background: #f44d5e;
  height: fit-content;
  padding: 7px 30px;
  border-radius: 20px;
  font-weight: 400;
  font-size: 16px !important;
  color: #ffffff !important;
  margin-left: auto;
}

.JobsCompanyDetails {
  border: 1px solid #f2f3f4;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.JobsCompanyDetailsHeader {
  background: #f2f3f4;
  padding: 13px 20px !important;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.JobsCompanyDetailsHeader .companylogo{
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.JobsCompanyDetailsTitle {
  font-weight: 500;
  font-size: 14px;
  padding-right: 20px;
  margin-left: 30px;
  color: #000000;
}

.JobsCompanyDetailsDescription {
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #666666;
}

.jobPostBtn {
  padding: 7px 60px;
}
