.candidatesListContainer {
  background: #ffffff;
  border-top-left-radius: 10px;
  overflow-y: auto;
  height: calc(100vh - 320px);
  padding-bottom: 20px;
}
.applicantOptions .applicantOptionsDropdown {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  right: 35px;
  top: 3px;
  width: 156px;
  background-color: #ffffff;
  color: #666666;
  text-align: left;
  box-shadow: 0px 2px 20px 10px rgb(0 0 0 / 5%);
  border-radius: 5px;
  z-index: 1;
}

.applicantStatusImg {
  width: 20px;
  height: 20px;
}

.applicantOptionsDropdown span span {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  font-family: Inter;
  color: #3c3e4d;
}
.applicantOptionsDropdown span img {
  margin-left: 10px;
}
.applicantOptions .applicantOptionsDropdown .saveIcon {
  border-radius: 50px;
}
.applicantOptions .applicantOptionsDropdown span:hover {
  background-color: #f2f3f4;
}
.candidatesListContainer::-webkit-scrollbar {
  display: none;
}

.candidatesListContainer {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.candidatesProfilePic {
  width: 60px;
  height: 60px;
  border-radius: 50px;
}

.candidatesProfileName {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.candidateSave {
  width: 30px;
}

.candidatesListCard {
  padding: 15px 0px;
  border-bottom: 0.5px solid #e2e2e9;
  border-left: 0.5px solid #e2e2e9;
}

.candidatesProfileHoverBar {
  position: absolute;
  width: 2px;
  height: 20px;
  background: #f44d5e;
  left: -8px;
  top: 20px;
}

.candidatesListCardActive {
  background-color: #fffdf8;
  border-left: 3px solid #F44D5E;
}
.notVisitedcard {
  background: #f2f3f4;
}
.notVisitedcard::after {
  content: "";
  background: #f44d5e;
  position: absolute;
  width: 3px;
  height: 15px;
  left: 0;
  top: 39%;
}
.candidatesListCardHover {
  background: #f2f3f4;
}

.candidatesRankingLogo {
  position: absolute;
  width: 23px;
  left: 5px;
  bottom: -5px;
}

.candidatesLoaction {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}
.shortListedImgHov{
  display: none;
}
.shortListedImgHld:hover .shortListedImgHov{
  display: block !important;
  position: absolute;
  right: 0;
  background-color: #fff;
  border: 0.5px solid rgb(184, 184, 184);
  border-radius: 5px;
  color: rgb(60, 62, 77);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  z-index: 99;
}
.candidatesListPagination {
  background: #ffffff;
  padding: 10px;
  border: none;
  border-bottom: 0.5px solid #e2e2e9;
}

@media (min-width: 1920px) {
  .candidatesLoaction {
    font-size: 15px;
  }
  .candidatesProfileName {
    font-size: 17px;
  }
}
