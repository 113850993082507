/* .AssessmentCntr {
  margin-top: 63px;
} */

/* userTimline CSS */
.userTimeline {
  margin-top: 94px;
}

.userTimeline-body {
  margin-top: 40px;
  box-sizing: border-box;
  border: 1px solid #e2e2e9;
  border-radius: 5px;
  position: relative;
}
.reject-userTimeline-body {
  border: 1px solid #f44d5e;
}
.hire-userTimeline-body {
  border: 1px solid #00bd3f;
}
.userTimeline-body .arrowIcon {
  width: 15px;
  height: 11px;
}
.userTimeline-body button {
  height: 30px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}
.userTimeline-body .report-button {
  background-color: #f44d5e;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  margin: 0px 25px;
  padding: 0px 30px;
}
.userTimeline-body .share-button,
.userReport .share-button {
  font-family: "inter";
  padding: 0px 17px;
  font-size: 12px;
  color: #3c3e4d;
  font-weight: 500;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
}
.userTimeline-body .share-button:hover,
.userReport .share-button:hover {
  background-color: #e2e2e9;
}
.userTimeline-body .copiedButton {
  padding: 0px 20px;
  background-color: #e2e2e9;
}
.userTimeline-body .report-download {
  font-family: "inter";
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}
.userTimeline-body .report-download img {
  margin-top: -6px;
}
.userTimeline-body .dot {
  position: absolute;
  top: 19px;
  left: -43.9px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #3c3e4d;
}
.userTimeline-body .dottedLine {
  position: absolute;
  top: 24px;
  left: -42px;
  width: 3px;
  height: 130%;
  border-right: 1px dashed #3c3e4d;
}
.userTimeline p {
  font-size: 14px;
  font-weight: 600;
  font-family: "inter";
  text-transform: capitalize;
}
.userTimeline .userCompletedTime {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  font-family: "inter";
}
.userTimeline .userCompletedTime img {
  margin-top: -1px;
}
.userTimeline .timelineDot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #666666;
}

.userTimeline li {
  list-style: none;
}

/* Loader for userTimeline */
.userTimeline-body .dateTimeLoader {
  height: 18px;
  width: 238px;
}
.userTimeline-body .testNameLoader {
  height: 18px;
  width: 274px;
}
.userTimeline-body .shareButtonLoader {
  height: 30px;
  width: 87px;
}
.userTimeline-body .viewReportButtonLoader {
  height: 30px;
  width: 178px;
}
.interviewStatushld {
  background: #f7f7f7;
  padding: 10px 16px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 4px;
}
.hiredInterviewStatusHld {
  background: #e5f8ec;
}
.interviewStatushld span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3c3e4d;
}
.rejectedInterviewStatusHld {
  background: #feedef;
}
.rejectedInterviewStatusHld p {
  color: #f44d5e;
}
/* media queries for userTimeline */

@media (min-width: 1450px) {
  .userTimeline .userCompletedTime {
    font-size: 14px;
  }
  .userTimeline p {
    font-size: 14px;
  }
  .userTimeline-body .report-download {
    font-size: 14px;
  }
  .userTimeline-body .share-button,
  .userReport .share-button {
    font-size: 14px;
  }
  .userTimeline-body .report-button {
    font-size: 14px;
  }
}
/* userReport CSS */
.userReport {
  margin-top: 65px;
}

.userReport .scoreBoard {
  border-radius: 10px;
  height: 71px;
  margin-top: 46px;
  overflow: hidden;
}
.userReport .scoreBoard .scoreDisplay {
  text-align: center;
  padding-top: 15px;
  height: 71px;
  width: 100px;
  background-color: #e5f8ec;
  font-family: "inter";
  font-style: normal;
}
.userReport .aboveCutOff {
  border: 0.5px solid #00bd3f;
}
.userReport .scoreBoard .displayAboveCutOff {
  border-right: 0.5px solid #00bd3f;
  background-color: #e5f8ec;
}
.userReport .belowCutOff {
  border: 0.5px solid #f44d5e;
}
.userReport .scoreBoard .displayBelowCutOff {
  border-right: 0.5px solid #f44d5e;
  background-color: #feedef;
}

.userReport .scoreBoard .scoreDisplay .scoreInPercentage {
  font-weight: 900;
  font-size: 18px;
  color: #000000;
}
.userReport .scoreBoard .scoreDisplay .cutOffScore {
  color: #f44d5e;
}
.userReport .scoreBoard .scoreDisplay .score {
  color: #666666;
  font-weight: 500;
  font-size: 12px;
}
.userReport .createInterviewButton {
  background-color: #f44d5e;
  font-family: "Inter";
  font-size: 12px;
  padding: 0px 20px;
  height: 30px;
  font-weight: 500;
  color: #ffffff;
  margin-right: 25px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}
.userReport .userReportMail {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
}
.userReport .viewTimelineButton {
  height: 30px;
  font-family: "Inter";
  background-color: #3c3e4d;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  padding: 0px 30px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
}
.userReport .detailedReport {
  font-family: "inter";
  font-style: normal;
  font-size: 12px;
}
.userReport .detailedReport .description,
.userReport .description {
  color: #000000;
  font-weight: 500;
  font-size: 12px;
  font-family: "Inter";
}
.userReport .description {
  font-size: 14px;
  line-height: 30px;
}
.userReport .sectionScore {
  background: #f44d5e;
  border-radius: 50px;
  font-family: "Inter", sans-serif;
  padding: 6px 25px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.userReport .sectionCutOffScore {
  background: #00bd3f;
}
.userReport .borderLeft {
  border-right: 1px solid #e2e2e9;
}
.userReport .detailedReport .viewReport {
  color: #f44d5e;
  font-weight: 500;
  cursor: pointer;
}
.details p {
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
}

.details .titleNames {
  color: #666666;
}
.details .candidateReportDetails {
  color: #3c3e4d;
}
.details .QnACards {
  border: 1px solid #e2e2e9;
  padding: 15px 24px 7px;
  font-size: 12px;
}
.details .QnACards .QnAResponseStatus {
  padding: 0px;
  width: 15px;
  height: 15px;
  margin-top: 2px;
}
.details .QnACards .QnA .QnaQuestion {
  color: #666666;
  font-weight: 400;
  height: 24px;
}
.details .QnACards .QnA .QnaAnswer {
  color: #000000;
  font-weight: 500;
  height: 24px;
}
.details .skillScore {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}
.details .score .scoreProgressBarBody {
  background-color: #f2f3f4;
  width: 320px;
  height: 6px;
  margin: 12px 0px;
  border-radius: 50px;
  position: relative;
}
.details .score .scoreProgressBarBody .scoreProgressBar {
  background-color: #feb91e;
  position: absolute;
  height: 6px;
  border-radius: 50px;
  top: 0px;
  left: 0px;
}
.userReport .share-button {
  height: 39px !important;
  border-radius: 50px;
  cursor: pointer;
}
.userReport .copiedButton {
  background-color: #e2e2e9;
  padding: 0px 20px;
  color: #3c3e4d;
}

.userReport .statusButton {
  width: 220px;
  border: 1px solid #e2e2e9;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  color: #3c3e4d;
  position: relative;
  border-radius: 50px;
  background-color: #f2f3f4;
}
.assessmentInterviewModal {
  font-family: "Inter" !important;
}

.userReport .statusButton .statusList {
  position: absolute;
  top: 41px;
  right: 0px;
  border-radius: 5px;
  background-color: #ffff;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  box-shadow: 0px 2px 20px 10px rgba(0, 0, 0, 0.05);
  z-index: 9999999;
}
.userReport .statusButton .statusList span {
  text-align: left;
  padding: 9px 32px 11px 18px;
}
.userReport .statusButton .statusList span:hover {
  background-color: #f2f3f4;
}

/* userReport Loader */
.userReport .interviewBtnLoader {
  height: 30px;
  width: 167px;
}
.userReport .timelineBtnLoader {
  height: 30px;
  width: 141px;
  margin-left: 25px;
}
.userReport .boardLoader {
  width: 1265px;
}
.userReport .emailLoader {
  width: 178px;
  height: 18px;
}
.userReport .linkBtnLoader {
  width: 87px;
  height: 30px;
  margin-right: 16px;
}
.userReport .statusBtnLoader {
  width: 220px;
  height: 30px;
}
.QnACards .QnA .QnaLoader {
  height: 20px;
}
.details .skillTypeLoader {
  width: 200px;
  height: 17px;
}
.details .ProgressBarLoader {
  width: 320px;
  height: 16px;
}
.details .skillScoreLoader {
  width: 78px;
  height: 18px;
}
/* media queries for userReport */

@media (min-width: 1450px) {
  .userReport .statusButton {
    font-size: 14px;
    width: 240px;
  }
  .userReport .scoreBoard .scoreDisplay .scoreInPercentage {
    font-size: 20px;
  }
  .userReport .userReportMail {
    font-size: 14px;
  }
  .userReport .statusButton .statusList,
  .details .skillScore,
  .details .QnACards,
  .details,
  .userReport .detailedReport,
  .userReport .viewTimelineButton,
  .userReport .createInterviewButton,
  .userReport .scoreBoard .scoreDisplay .score {
    font-size: 14px;
  }
}
