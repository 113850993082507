.hacakthonInnerCard {
  background: #FBFBFD;
  border: 1px solid #F2F2F2;
  padding-top: 15px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.hackathonDivider{
  height: 1px;
  width: 25%;
  border-top: 0.5px solid #969BA0;
}
.hacakthonInnerCard .borderRight {
  border-right: 0.5px solid #969BA0;

}

.hacakthonInnerCard .winnerProfile {
  width: 43px;
  height: 43px;
  border-radius: 50px;
}

.hacakthonInnerCard .winnerPloygon {
  position: absolute;
  left: -5px;
  bottom: -5px;
}

.hacakthonInnerCard .winnerPloygonText {
  position: absolute;
  left: 4px;
  bottom: 0px;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #000000;
}

.hacakthonInnerCard .topRanketTextBold {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.hacakthonInnerCard .topRanketText {
  font-weight: 500;
  font-size: 10.8px;
  line-height: 150%;
  color: #000000;
}

.viewLeaderboardBtn {
  background: #F44D5E;
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 8.8px;
  line-height: 11px;
  color: #FFFFFF;
}
.dashboardHackthonsCard{
  background: #FFFFFF;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    border-radius: 20px;
}
.dashboardHackathonHeading{
  padding: 25px 25px;
}
.allHackthons .hackathonDataCard {
  padding: 10px 25px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}
.allHackthons .hackathonDataCard:hover {
  background: rgba(244, 245, 249, 0.4);
  border: 1px solid #F4F5F9;
}
.hackthonDate {
  padding: 4px 0px 13px 0px;
}

.avatar {
  width: 35px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
}

.avatar img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.avatar-group {
  display: flex;
  margin-left: 15px;
}

.dashboardLeftHackthonBottomItem .paticipantsNumber {
  font-size: 16px;
  font-weight: 500;
}

.avatar {
  margin-left: -15px;
}

.hidden-avatars {
  width: 35px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -15px;
  margin-right: 23px;
  background-color: #f44d5e;
  color: #fff;
}

.hackthonsTop div {
  cursor: pointer;
}

.viewAll p {
  font-size: 14px;
  color: #a5a6aa;
}

.viewAll img {
  width: 13px;
}

.hackthonYear {
  font-size: 10px;
}

.progressBar {
  border-bottom: 3px solid rgba(200, 247, 220, 0.5);
  width: 100%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  position: relative;
}

.insideProgress {
  border-bottom: 3px solid #34C471;
  ;
  position: absolute;
}

.allHackthons {
  flex-wrap: nowrap;
  overflow: auto;
}

.timeTabHackathon {
  background: #F4F5F9;
  border-radius: 50px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 10.8px;
  line-height: 150%;
  color: #6B7280;
}

.hackthonsIcons {
  display: flex;
  width: 100px;
}

.hackthonTopper {
  width: 30px;
  height: 30px;
}

.dashboardLeftHackthonBottomItem .hackthonYear {
  font-size: 22px;
}

.allHackthons::-webkit-scrollbar {
  display: none;
}

.circles-gallery {
  position: relative;
  height: 34px;
  margin-left: calc(34px * 0.4);
}

.circles-gallery>* {
  position: relative;
  display: inline-block;
  margin-left: calc(34px * -0.4);
  height: 34px;
  width: 34px;
  border: solid 1.5px white;
  aspect-ratio: 1;
  border-radius: 50%;
  z-index: 10;
}

.circles-gallery>*:hover,
.circles-gallery>*:focus-within {
  z-index: +20;
}