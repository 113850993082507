.users {
  border-radius: 10px;
}
.usersTable td {
  padding: 8px;
  font-size: 14px;
}
.usersTable .dashboardUsersfullName {
  color: #3c3e4d;
  font-weight: 500;
  font-size: 14px;
}
.usersTable .userTableThinText {
  font-weight: 500;
  font-size: 13.6086px;
  line-height: 150%;
  color: #6B7280;
}
td img {
  cursor: pointer;
}
.getModal {
  position: relative;
}
.userIcon {
  width: 43px;
  height: 43px;
  background: #F3F4F6;
  border-radius: 50%;
}
.userIcon h6 {
  color: #111827;
}
.usersData p {
  cursor: pointer;
}
.usersData img {
  cursor: pointer;
}

@media only screen and (max-width: 540px) {
  .usersData h5 {
    font-size: 12px;
  }
  .usersData p {
    font-size: 8px;
  }
  .usersData img {
    width: 12px;
    height: 12px;
  }
  .usersTable td {
    font-size: 13px;
  }
}

table {
  border-spacing: 0;
  width: 100%;
}
.iamContainer th {
  margin: 0;
  padding: 1rem 3.55rem;
  border-bottom: 1px solid #a5a6aa;
  font-family: Roboto;
  font-weight: 500;
  /* font-size: 18px; */
  font-size: 14px;
  color: #3c3e4d;
}
.iamContainer table th:nth-child(1),
.iamContainer table th:nth-child(2) {
  padding: 1rem 3rem;
}
.iamContainer td {
  margin: 0;
  padding: 1rem 3.55rem;
  border-bottom: 1px solid #e5e5e5;
  font-family: Roboto;
  /* font-size: 18px; */
  font-size: 14px;
  color: #3c3e4d;
  text-align: left;
}
.iamContainer table td:nth-child(1),
.iamContainer table td:nth-child(2) {
  padding: 1rem 3rem;
  font-weight: 800;
  font-size: 20px;
  font-weight: bold;
  font-size: 16px;
  color: #3c3e4d;
}

/* Custom Bootstrap Modal  */
.removeUserModal {
  width: 400px !important;
}
.removeUserModal .modal-header::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 230px;
  background: #484a58;
  left: 50%;
  bottom: -2px;
  border-radius: 1px;
  transform: translate(-50%, 0);
}

.removeUserModal .modal-title,
.resetPasswordModal .modal-title,
.editUserModal .modal-title,
.addUserModal .modal-title {
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  color: #3c3e4d;
}

.resetPasswordForm .form-group label,
.editUserForm .form-group label,
.addUserForm .form-group label {
  font-size: 14px;
}

.resetPasswordForm .form-input,
.editUserForm .form-input,
.addUserForm .form-input {
  font-size: 14px;
}

.resetPasswordModal {
  width: 500px !important;
}
.resetPasswordModal .modal-header::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 230px;
  background: #484a58;
  left: 50%;
  bottom: -2px;
  border-radius: 1px;
  transform: translate(-50%, 0);
}

.editUserModal {
  width: 600px !important;
}
.editUserModal .modal-header::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 230px;
  background: #484a58;
  left: 50%;
  bottom: -2px;
  border-radius: 1px;
  transform: translate(-50%, 0);
}

.addUserModal {
  width: 400px !important;
}
.addUserModal .modal-header::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 200px;
  background: #484a58;
  left: 50%;
  bottom: -2px;
  border-radius: 1px;
  transform: translate(-50%, 0);
}

.revokeUserDialog .modalButtonSubmit {
  width: 70%;
}
.revokeUserDialog .modalButtonCancel {
  width: 70%;
}
.revokeUserDialog p {
  font-family: Roboto;
  font-size: 16;
  line-height: 1.67;
  color: #3c3e4d;
}
.resetPasswordForm {
  margin-top: 15px;
  padding: 0 40px;
}
.resetPasswordForm .modalButtonSubmit {
  width: 40%;
}
.resetPasswordForm .form-group {
  padding: 5px 0px;
}

.editUserForm {
  margin-top: 15px;
  padding: 0 40px;
}
.editUserForm .modalButtonSubmit {
  width: 40%;
}
.editUserForm .form-group {
  padding: 5px 0px;
}

.tableButton {
  border: none;
  height: 22px;
  width: 20px;
  background: url("/src/assets/Icons/Settings/menu-vertical.png") no-repeat;
}
.tableMenu {
  display: none;
  right: 5%;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  cursor: pointer;
}
.menuActive {
  display: block;
}

.tableMenu div {
  color: black;
  padding: 12px 16px;
  display: block;
}

.tableMenu div:hover {
  background: #f2f3f4;
}

.tableMenu p {
  display: inline;
}
.tableMenu div:last-child:hover {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.tableMenu div:first-child:hover {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.menuIcons {
  padding: 2px;
  margin: 0 3px;
  width: 18px;
  height: 18px;
}

.iamTablePagination {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iamTablePagination button {
  margin: 0px 10px;
  padding: 4px;
  border: none;
  border-radius: 5px;
  color: #f1f1f1;
  background-color: #3c3e4d;
}

.revokeUserDialog div {
  text-align: center;
  padding: 10px 10px;
}

.modalButtonSubmit {
  padding: 8px 14px;
  border: none;
  border-radius: 5px;
  background-color: #f44d5e;
  color: #fff;
  width: 60%;
}
.modalButtonCancel {
  padding: 8px 14px;
  border-radius: 5px;
  border: solid 2px #212529;
  background: #fff;
  width: 40%;
}
.dashboardUsersSectiontable{
  padding-top: 28px;
}
.dashboardUsersSectiontable table {
  border-collapse: separate;
  margin-bottom: 0px;
}
.usersTable tbody tr td {
  border: none;
  border-top: 1px solid #f4f5f9;
}
.dashboardUsersSectiontable .usersTable tbody tr:last-child td {
  border-bottom: 1px solid #f4f5f9;
}
.usersTable tbody tr td:first-child {
  border-left: 1px solid #f4f5f9;
}
.usersTable tbody tr:first-child td:first-child {
  border-top-left-radius: 10px;
}
.usersTable tbody tr td:last-child {
  border-right: 1px solid #f4f5f9;
}
.usersTable tbody tr:first-child td:last-child {
  border-top-right-radius: 10px;
}
.usersTable tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
.usersTable tbody tr td{
  background: transparent;
}
.usersTable tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
.dashboardUsersSectiontable .usersTable tr:nth-of-type(even) {
  background: #f9fafb;
}
@media only screen and (min-width: 2560px) {
  th,
  td {
    font-size: 18px;
  }
  table td:nth-child(1),
  table td:nth-child(2) {
    font-size: 20px;
  }
  .removeUserModal .modal-title,
  .resetPasswordModal .modal-title,
  .editUserModal .modal-title,
  .addUserModal .modal-title {
    font-size: 30px;
  }

  .resetPasswordForm .form-group label,
  .editUserForm .form-group label,
  .addUserForm .form-group label {
    font-size: 18px;
  }

  .resetPasswordForm .form-input,
  .editUserForm .form-input,
  .addUserForm .form-input {
    font-size: 18px;
  }
}
