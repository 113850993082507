.navbar {
  background: #f2f3f4;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid #e2e2ea;
  height: 50px;
  padding: 0 30px;
}
.navbar .intreviewCompanyLogo {
  height: 40px;
  width: 40px;
}
.navbar .interviewHeaderNav {
  width: 54%;
}
.navbar .pagetitle {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  font-family: "Inter";
}
.logo-h {
  position: relative;
  height: 100%;
  cursor: pointer;
}
.navbar .avatar {
  height: 32px;
  width: 32px;
}
.company-logo {
  height: 50px;
}
.logo-h:after {
  position: absolute;
  content: "";
  background: url("/src/assets/Icons/Header/EditLogo.svg") no-repeat center;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  background-color: #f44d5e;
  opacity: 0.4;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  display: none;
}
.logo-h:hover:after {
  display: block;
}
.header .logo {
  width: 143px;
  height: 24px;
  margin-left: 150px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.header .userDetail {
  margin: 0 10px;
  padding: 10px;
}
.userName {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1px;
  cursor: pointer;
  color: #3c3e4d;
}
.header .role {
  margin-top: -3px;
  font-weight: 500;
  font-size: 12px;
  color: darkgray;
}

/* Drop down menu */
.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.dropbtn {
  background: url("/src/assets/Icons/DashboardNew/Dropdown.svg") no-repeat;
  color: black;
  font-size: 16px;
  border: none;
  height: 6px;
  width: 10px;
}
.messageIconContainer {
  padding: 5px 10px;
  border-right: 1px solid #e4e4e5;
}

.notificationIconContainer {
  padding: 10px 10px;
}

.navbar .dropdown-content {
  display: none;
  top: 44px;
  position: absolute;
  background: #ffffff;
  border: 0.2px solid #d8d8d8;
  box-shadow: 0px 12px 18px rgba(8, 35, 48, 0.05);
  border-radius: 5px;
  width: 100%;
  z-index: 1;
  right: 6px;
  min-width: 250px;
  box-shadow: none;
}

.navbar .dropdown-content .userDetailshld {
  background-color: #fbfbfb;
  border-bottom: 1px solid #e2e2ea;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 13px;
}
.navbar .dropdown-content .menuItems {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
  margin: 20px 14px;
  cursor: pointer;
}
.user-email {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #666666;
  word-break: break-all;
  cursor: pointer;
}
.logoutHld {
  background: rgb(216, 216, 216, 0.2);
  padding: 10px 14px;
  color: #f44d5e;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.headerTitleLoader {
  margin-left: 5px;
  height: 24px;
}
.hamburgerHld{
  transition: all 1s ease-in-out;
}
.showDropDown {
  display: block !important;
}
.bg-white {
  background: #ffffff;
}

.dummy-class {
  padding: 0;
}
.dropdown-content .mh-support:hover {
  background-color: transparent;
}
@media only screen and (max-width: 767px) {
  .hamburgerMenu {
    display: block;
  }
  .hamburgerIcon {
    display: block;
  }

  .hamburgerMenu .navLinks:last-child {
    border-bottom-left-radius: 0px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 479px) {
  .header .logo {
    max-height: 20px;
  }
}
