.navContactUsHld {
  background: #f4f5f9;
  padding: 10px 20px;
  text-align: center;
  margin-top: auto;
}
.navContactUsHld span {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}
.sideNav {
  background-color: #ffffff;
  position: fixed;
  padding-left: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  height: auto;
  box-shadow: 0 0 6px #e2e2e9;
  z-index: 9999;
  width: 227px;
  border: 1px solid #e2e2e9;
  border-top: none;
  transition: 0.2s ease-out;
}

.sideNavCollapsed {
  background-color: #ffffff;
  position: fixed;
  padding-left: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  height: auto;
  box-shadow: 0 0 6px #e2e2e9;
  z-index: 9999;
  width: 60px;
  border: 1px solid #e2e2e9;
  border-top: none;
  transition: 0.2s ease-out;
}

.company-logo {
  transition: 0.2s ease-out;
}

.navbarCollapse {
  width: 59px;
  overflow: hidden;
}

.nav-link {
  padding: 0 !important;
  font-size: 0.9rem;
  width: 100%;
  text-align: center !important;
}

.navLinks {
  width: 100%;
  /* display: flex; */
  padding: 5px 0px;
  transition: 0.2s ease-out;
}

.navLinks p {
  margin: 0px 10px;
  color: #626465;
  width: 100px;
  white-space: nowrap;
}

.msgIco {
  margin-left: -10px;
}

.hamburgerIcon {
  display: inline;
  /* background: url("/src/assets/Icons/Header/hamburgerMenu.svg") no-repeat; */
  fill: #666666 !important;
  font-size: 16px;
  border: none;
  height: 25px;
  width: 22px;
  transform: translateX(10px);
  margin-left: -10px;
}

.companylogohld {
  height: 50px;
}

.navItemsHld {
  flex-direction: column;
  align-items: flex-start;
}

.sideNav .navItemsHld {
  padding: 0px 10px;
}
.sideNavCollapsed .navItemActive::before {
  content: "";
  position: absolute;
  left: 0px;
  width: 4.5px;
  height: 32px;
  background-color: #f44d5e;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.navLinks .navItemInactive:hover .sideBarMenu{
  color: #f44d5e;
}
.navLinks .navItemInactive:hover .sideBarIcons ,.sideNavCollapsed .navItemActive .sideBarIcons{
  /* filter: invert(42%) sepia(9%) saturate(4718%) hue-rotate(314deg) brightness(114%) contrast(92%); */
  filter: invert(39%) sepia(19%) saturate(6143%) hue-rotate(329deg) brightness(105%) contrast(91%);
}
.sideNav .navItemActive {
  position: relative;
  border-radius: 4.69px;
  background: linear-gradient(274deg, #F44D5E -5.92%, #F46B4D 114.01%);
  transition: background-color 0.5s ease-in-out;
}

.sideNav .navItemActive::before {
  content: "";
  position: absolute;
  left: -11px;
  width: 4.5px;
  height: 100%;
  background-color: #f44d5e;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

 /* .navLinks .custom-styling:hover p{
  color: #ffffff !important;
  transition: color 0.5s ease-in-out;
} */

.sideNav .iconTowhite.navItemActive img{
  filter: invert(93%) sepia(7%) saturate(0%) hue-rotate(145deg) brightness(164%)
  contrast(112%);
}

.navLinks .nav-link {
  color: white;
  font-weight: 500;
}

.custom-styling {
  padding: 6px 0px;
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s ease-out;
}

.sideBarIcons {
  display: block;
  height: 18px;
  width: 18px;
  margin: 5px 20px;
  color: transparent;
}
.sideBarMenu {
  font-size: 11px;
  font-weight: 500;
}

.sideNav .sideBarMenuActive {
  color: #fff !important;
  
}

.sideBarMenu {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: #575757;
}

.chat_space {
  background-color: #f2f3f4;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  color: #666666;
  cursor: pointer;
}

.chatLabel {
  padding-left: 10px;
  font-size: 12px;
  font-weight: 500;
}

.chatBubble {
  font-size: 12px;
  height: 17px;
  padding: 0px 10px;

  background-color: #f44d5e;
  width: 28px;
  border-radius: 20px;
}

.bubbleOn {
  transform: translate(-40px);
  padding: 0px 5px;
  /* margin-left: 0px; */
}

.navLinks ul {
  margin: 0;
}

.navLinks ul {
  list-style-type: none;
  margin-left: 30px;
}

.navLinks ul li {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  margin: 10px 0;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.navLinks ul li:hover {
  font-weight: 500;
  color: #f44d5e;
  transition: 0.04s;
}

.navLinks ul > li:before {
  content: "-";
  margin-right: 5px;
}

.user-plan-name {
  color: #ed952d;
  background: rgb(255, 159, 75, 0.1);
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  padding: 10px;
}
.premium-user-icon {
  background: rgb(255, 159, 75, 0.1);
  padding: 5px 10px;
  border-radius: 50px;
}
@media only screen and (min-width: 2560px) {
  .sideBarMenu {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1366px) {
  .sideBarMenu {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1024px) {
  /* .sideNav {
    width: 100%;
  } */
}

@media only screen and (max-width: 767px) {
  /* .navLinks a p {
    display: block;
  } */

  .navLinks:first-child {
    border-top-left-radius: 0px;
  }

  .navLinks:last-child {
    border-bottom-left-radius: 0px;
  }
}
