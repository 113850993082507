/* ul{
    padding-left: 0;
} */

label {
    display: block;
}

.login-page{
    background-color: #f3f5f7;
}

/* -------------------Containers-------------------- */

.header-container{
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
}

.bodyContainer{
    /* height: 100vh; */
}
.loginContainer {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 100px);
}


.companies-container p{
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: left;
    color: #3c3e4d;
}

.companyLogoContainer{
    margin: 35px 0 0 0;
    /* display: flex;
    justify-content: space-between; */
}

.companyLogoContainer img{
    /* width: 134px;
  height: 53px; */
  margin: 0 50px 50px 0;
}

/* ---------------Forms-------------------------*/
.form-container-contents{
    background-color: #ffffff;
    border-radius: 10px;
    
}

.form-btn{
    height: 55px;
    border: none;
    cursor: pointer;
    background-color: #f7f8fa;
    width: 50%;
    font-size: 20px;
    font-weight: bold;
    color: #3c3e4d;
}
.login-heading {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.5;
    color: #3c3e4d;
}
.signin-btn{
    border-radius: 10px 0px 0px 10px;  
}

.register-btn{
    border-radius: 0px 10px 10px 0px;
}
.active-btn {
    background-color:#ff6381;
    color: #ffffff;
}

.form-input-check{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.form-group label{
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
  color: #3c3e4d;
}

.form-input{
    height: 40px;
    opacity: 0.5;
    border-radius: 5px;
    border: solid 1.5px #e5e5e5;
    background-color: #ffffff;
    padding-left: 9px;
    margin: 10px 12px 10px 0;
    width: 100%;
}

.inputErrorIcon{
    position: absolute;
    top: 25%;
    right: 4%;
    transform: translate(25%, 0px);
}

.col-6.form-group div .inputErrorIcon{
    right: 7%;
} 

.inputError{
    border: solid 1.5px #ff7c86;
}

.toggleHidePassword{
    background: url('/src/assets/Icons/Group9.svg') no-repeat;
    border: none;
    height: 22px;
    width: 25px;
    position: absolute;
    top: 35%;
    right: 4%;
    transform: translate(35%, 0px);
}
.toggleShowPassword{
    background: url('/src/assets/Icons/Group765.svg') no-repeat;
    border: none;
    height: 22px;
    width: 25px;
    position: absolute;
    top: 35%;
    right: 4%;
    transform: translate(35%, 0px);
}

.submitButton{
    background-color: #3c3e4d;
    color: #ffffff;
    border: none;
    cursor: pointer;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0;
}

.form-input-check > div > a{
    text-decoration: none;
    color: #3c3e4d;
}

.form-input-check > div{
    display: flex;
}
/* Custom Checkbox */
.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    line-height: normal;
    cursor: pointer;
    font-size: 18px;
    color: #979797;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #ffffff;
    border: solid 1px #d8d8d8;
    border-radius: 3px;
}

.custom-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
}

.custom-checkbox input:checked ~ .checkmark {
    background-color: #3c3e4d;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

.custom-checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.forgotPassword{
    background: none;
    border: none;
    color: #3c3e4d;
    font-size: 18px;
}
.form-footer-link > p  {
    color: #3a4851;
    font-size: 16px;
}

.form-footer-link > p > button{
    margin-left: 1px;
    background: none;
    border: none;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 0.9rem;
    color : #ff6381;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;

}
.form-footer-contact > p{
    text-align: center;
    color : #a5a6aa;
    font-size: 0.9rem;
    
}

.emailMessage{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}
.emailMessage > img {
    width: 200px;
    height: 200px;
    margin: 30px
}
.emailMessage > h3 {
    margin: 30px;
    width: 60%;
    font-family: Roboto;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3c3e4d;
}


.loginButton{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 35px;
    width: 120px;
    padding: 0% 2%;
    border-radius: 5px;
    border: solid 1px #c6cce2;
    text-align: left;
    background: #ffffff;
    cursor: pointer;
}
.loginButton > img{
    height: 12px;
    width: 12px;
}
.content-sub-container h2{
    width: 65%;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #3c3e4d;
}
.list-container {  
    font-size: 18px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    color: #3c3e4d;
}
.list-container ul li span{
    top: -3px;
    position: relative;
    margin-left: 6px;
}

.list-container img{
    width: 18px;
    height: 18px;
    margin: 0 20px 1px 0;
}

.resetPassword{
    font-family: 'Roboto', sans-serif;
    line-height: 2;
    letter-spacing: normal;
    padding: 30px;
}

.resetPassword h3{
    text-align: center;
    font-weight: bold;
    color: #3c3e4d;
    font-size: 34px;
    margin: 30px 0;
}

.resetPassword p{
    text-align: center;
    margin: auto;
    color : #a5a6aa;
    font-size: 18px;
}
/*Reset and setup password page */
.passwordSuccess{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}
.passwordSuccess > img {
    width: 80px;
    height: 80px;
    margin: 30px
}
.passwordSuccess > h3 {
    margin: 30px;
    width: 60%;
    font-family: Roboto;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3c3e4d;
}

.passwordSuccess p {
    font-family: Roboto;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #a5a6aa;
}

.passwordSuccess button{
    width: 80%;
}

.invalid{
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #ff7c86;
}

@media only screen and (max-width: 767px) {
    .header-container .row > div {
        width: initial;
    }
}
@media only screen and (min-width: 2560px) {

}
@media only screen and (min-width: 1920px) and (max-width: 2559px) {
}
@media only screen and (min-width: 1600px) and (max-width: 1919px) {
}
@media only screen and (min-width: 1440px) and (max-width: 1599px) {
}
@media only screen and (min-width: 1366px) and (max-width: 1439px) {
}
@media only screen and (min-width: 1280px) and (max-width: 1365px) {
}
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .bodyContainer {
        align-items: flex-start;
    }
    .login-heading {
        font-size: 32px;
    }
}
@media screen and (amzn-kf8) and (device-height:1024px) and (device-width: 758px) {
}

@media screen and (amzn-kf8) and (device-height:1280px)
  and (device-width: 800px) {}
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
    .login-heading {
        font-size: 30px;
    }
    .list-container{
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .list-container ul li {
        padding: 10px 0;
    }
    .bodyContainer {
        align-items: flex-start;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 850px) {
}
@media only screen and (min-width: 640px) and (max-width: 767px) {
}
@media only screen and (min-width: 480px) and (max-width: 639px) {
}
@media only screen and (min-width: 0px) and (max-width: 479px) {
    .bodyContainer {
        align-items: flex-start;
    }
    .login-heading {
        font-size: 24px;
    }
    .list-container{
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .list-container ul li {
        padding: 6px;
    }
    .form-container-contents form{
        padding: 10px 25px !important;
    }
    .custom-checkbox {
        font-size: 14px;
    }
    .list-container ul li span {
        font-size: 15px;
    }
    .resetPassword h3 {
        font-size: 28px;
        margin: 20px 0;
    }
    .forgotPassword {
        font-size: 14px;
    }
    .resetPassword p {
        font-size: 16px;
    }
    .resetPassword  {
        padding: 20px;
    }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 320px) {
    .login-heading {
        font-size: 24px;
    }
    .list-container ul li {
        font-size: 15px;
        padding: 0;
    }
    .custom-checkbox {
        padding-left: 28px;
    }
}
@media only screen and (min-device-width: 321px) and (max-device-width: 375px) {
}
@media only screen and (min-device-width: 376px) and (max-device-width: 425px) {
}