.dashboardHackthonItem {
  margin-top: 15px;
  min-height: 306px;
}

.hackthonImage {
  width: 35px;
  height: 35px;
}

.dashboardHackthonTop {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboardHackthonItem .selectedTag {
  border-radius: 50%;
}

.parentDiv {
  background-color: #d8d8d8;
  width: 130px;
}

.childDiv {
  position: absolute;
  background: #fff;
  box-shadow: 0px 10px 20px 20px rgb(0 0 0 / 5%);
  border-radius: 5px;
  border: 1px solid #c4c4c4;
}

.selected {
  font-size: 8px;
  text-align: center;
  font-weight: 400;
  padding: 6px 10px;
}

.dashboardLeftHackthonImage {
  /* display: flex; */
  align-items: center;
}

.dashboardLeftHackthonBottom {
  display: flex;
  padding: 10px;
}
.dashboardLeftHackthonBottomItem h5 {
  padding-bottom: 8px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.dashboardLeftHackthonBottomItem {
  border: 1px solid #F2F2F2;
  border-radius: 12px;
  margin-right: 19px;
  padding: 12px 10px;
  transition: background 1s ease-in;
}
.dashboardLeftHackthonBottomItem:hover{
  background: linear-gradient(111.05deg, #FFEFEF -3.16%, rgba(242, 242, 242, 0) 128.25%);
  border: 1px solid transparent;
}
.selectHackthon {
  padding: 3px 2px;
  border-radius: 20px;
  border: 1px solid #d8d8d8;
}

.optionValue {
  font-size: 1px;
}

@media only screen and (max-width: 576px) {
  .dashboardLeftHackthonBottomItem h4 {
    font-size: 10px;
    padding: 5px 0px;
  }

  .dashboardLeftHackthonBottomItem p {
    font-size: 10px;
  }

  .bottomHackthon {
    text-align: center;
  }
}

@media only screen and (max-width: 540px) {
  .dashboardHackthonItem h5 {
    font-size: 12px;
  }

  .hackthonImage {
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 380px) {
  .dashboardHackthonItem h5 {
    font-size: 8px;
  }
}

.dashboardNoHackthonStatistics {
  width: 62px;
  height: 68px;
}

.dashboardNoHackthonImage {
  padding-top: 18px;
  padding-bottom: 18px;
}