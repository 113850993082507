.interviewPageCntr {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  height: calc(100vh - 104px);
}
.interviewPaperpage {
  overflow-y: hidden;
}
.interviewPaperpage .gobackIcon {
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.interviewPaperpage .assessmentType {
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  display: inline-block;
}
.interviewSubHeader {
  background: #ffffff;
  border-bottom: 1px solid #e2e2ea;
}
.interviewTimer {
  background: #e6fbf1;
  box-shadow: 0px 4.80209px 19.2084px rgba(0, 0, 0, 0.05);
  border-radius: 24.0105px;
  color: #3c3e4d;
  padding: 7px 16px;
  font-size: 14px;
  margin-left: -50px;
}
.interviewPaperpage .inviteLinkBtn {
  border: 1px solid #e2e2ea;
  border-radius: 50px;
  background: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #92929d;
  padding: 7px 21px;
}
.interviewPaperpage .inviteLinkBtn:hover {
  background: #9598ab;
  color: #ffffff;
}
.interviewPaperpage .inviteLinkBtn:hover > img {
  filter: invert(100%);
  opacity: 1;
}
.interviewPaperpage .inviteLinkBtn img {
  width: 10px;
  opacity: 0.6;
}
.interviewPaperpage .exitBtn {
  background: linear-gradient(286.29deg, #f44d5e 2%, #fc7269 94.83%);
  border-radius: 5px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #ffffff;
  padding: 7px 21px;
}
.backgroundVideoChat {
  background: #313442;
}
.interviewPaperpage .exitBtn img {
  width: 15px;
  margin-left: 5px;
}
.submitInactive {
  background: #92929d !important;
}
.passedStatusContainer input[type="radio"]:checked::before {
  background: radial-gradient(#f44d5e 40%, transparent 50%);
}
.question-Type .sidebar {
  width: 14%;
  transition: 0.35s ease;
  border-right: 1px solid #626473;
  background-color: #282931;
  border-top-left-radius: 20px;
}
.question-Type .sidebarContent {
  width: 86%;
  transition: 0.35s ease;
}
.question-Type .InterviewerSidebarContent {
  width: 63%;
  transition: 0.35s ease;
}
.question-Type .collapsedSidebar {
  width: 7%;
  transition: 0.35s ease;
  background-color: #282931;
  border-top-left-radius: 20px;
}
.question-Type .collapsedSidebarContent {
  width: 93%;
  transition: 0.35s ease;
}
.question-Type .toggleHeading {
  display: flex;
  align-items: center;
  padding: 10px;
}
.tablistHld {
  background-color: #59595f;
  height: 47px;
}
.cm-s-monokai .CodeMirror-gutters,
.CodeMirror-scroll {
  background: #000000 !important;
}
.question-Type .collapsedSidebar .toggleHeading p,
.question-Type .collapsedSidebar .tablist p {
  display: none;
}
.question-Type .collapsedSidebar .tablist,
.question-Type .collapsedSidebar .toggleHeading {
  justify-content: flex-start;
}
.question-Type .toggleHeading .title {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}
.interviewMainSection .tablistHld .tablist .title {
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  color: #fff;
  text-transform: capitalize;
}
.interviewMainSection .tablistHld .tablist:before,
.interviewMainSection .tablistHld .tablist:after {
  content: "";
  background: #3a3b45;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border-width: 10px;
  top: 41px;
  border-style: solid;
  position: absolute;
}

/* clear fix for tabs */
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
}
.interviewMainSection .tablistHld .tablist {
  background: #3a3b45;
  cursor: pointer;
  padding: 11px 1px 11px 8px;
  float: right;
  margin: 0px -1px 0;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  padding-right: 20px;
}
.interviewMainSection .tabCloseImg {
  margin: 0px -10px 0px 8px;
}
.interviewMainSection .tablistHld .tablist:before {
  border-color: transparent #3a3b45 transparent transparent;
  -webkit-transform: rotate(204deg);
  -moz-transform: rotate(204deg);
  -ms-transform: rotate(204deg);
  -o-transform: rotate(204deg);
  transform: rotate(204deg);
  left: -4px;
}
.interviewMainSection .tablistHld .tablist:after {
  border-color: transparent transparent transparent #3a3b45;
  -webkit-transform: rotate(247deg);
  -moz-transform: rotate(247deg);
  -ms-transform: rotate(247deg);
  -o-transform: rotate(247deg);
  transform: rotate(247deg);
  right: -4px;
}

.interviewMainSection .tablistHld .tablist:after {
  border-bottom-right-radius: 6px;
  border-width: 0 1px 1px 0;
}
.interviewMainSection .tablistHld .tablist:before {
  border-bottom-right-radius: 6px;
  border-width: 0 1px 1px 0;
}
.interviewMainSection .tablistHld .tablist:hover,
.interviewMainSection .tablistHld .tablist:hover:before,
.interviewMainSection .tablistHld .tablist:hover:after {
  background: #292c37;
}
.interviewBodyPanel {
  z-index: 3;
}
.interviewMainSection .tabImg {
  width: 20px;
  height: 23px;
}
.interviewMainSection .selectedTab {
  background-color: #13151b !important;
  height: 47px;
  z-index: 1;
}

.interviewMainSection .selectedTab:before {
  border-color: transparent #13151b transparent transparent !important;
  background: #13151b !important;
}
.interviewMainSection .selectedTab:after {
  border-color: transparent transparent transparent #13151b !important;
  background: #13151b !important;
}

.question-Type .tablist img {
  width: 20px;
  height: 20px;
}
.addnewtab {
  background-color: #3a3b45;
  height: 36px;
  margin: 5px 10px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}
.addnewtab span {
  font-size: 18px;
}
.RunCodeBlock {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #494d59;
  height: 5%;
}
.outputSection {
  height: 37px;
  background: #121419;

  position: absolute;
  bottom: 0;
  width: 100%;
}
.textAreaError {
  border: 0.966194px solid #e9505c !important;
}
.textAreaError::placeholder {
  color: #e9505c;
}
.expandedSectionOutput {
  height: auto;
  max-height: 350px;
}
.collapseOutputContainer {
  height: 37px;
  background: #313442;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.collapseOutputContainer img {
  width: 40px;
  height: 20px;
  margin: 0 auto;
}

.interviewPaperpage .runcodebtn {
  transform: translateY(-6px);
  background-color: #e9505c;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  border: none;
  padding: 11.5px 25px;
  border-radius: 87% 13% 100% 0% / 0% 100% 0% 100%;
  cursor: pointer;
  overflow: auto;
}
.interviewPaperpage .runcodebtn:hover {
  border: none;
}
.interviewPaperpage .runcodebtn img {
  width: 20px;
}
.videoChatBlock {
  position: absolute;
  bottom: -2px;
  right: 26px;
  background: #ffffff;
  width: 25%;
  z-index: 99;
}
.expandedvideoChatBlock {
  /* position: absolute;
  bottom: 5%;
  right: 26px; */
  background: #000;
  /* width: 25%;
  height: 25%; */
  z-index: 99;
  height: 200px;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}
.interviewSubmitbtn {
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 50px;
  background: linear-gradient(286.29deg, #f44d5e 2%, #fc7269 94.83%);
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: #ffffff;
  margin-top: 30px;
}
.videoChatBlock .headingHld,
.expandedvideoChatBlock .headingHld {
  text-align: right;
}
.videoChatBlock .headingHld .minimizeIcon,
.expandedvideoChatBlock .headingHld .minimizeIcon {
  width: 14px;
}
.videoChatBlock .headingHld .closeIcon,
.expandedvideoChatBlock .headingHld .closeIcon {
  width: 12px;
}
.toolbox-icon {
  display: none;
}
.hungupBtnHld {
  position: fixed;
  /* left: 86%; */
  transform: translate(-50%, -50%);
  margin: 0 auto;
  bottom: -4px;
  right: 35px;
}
.hungupBtnHld img {
  width: 32px;
}
.outputDisplay {
  padding: 20px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
.outputDisplay p {
  color: #fff;
}
.errorDisplay {
  border: 1px solid #e9505c;
  color: #e9505c;
  border-radius: 4px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
.errorDisplay p {
  color: #e9505c;
}
.UntitledTabContainer {
  height: calc(100vh - 147px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #13151a;
}
.scoreBoardHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13.4487px;
  line-height: 16px;

  color: #ffffff;
}
.mainFeedbackSection {
  background: #292c37;
  position: absolute;
  top: 200px;
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 347px);
}
.mainFeedbackSection::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.mainFeedbackSection .FBtext-Area {
  background: #343743;
  border: none;
  margin-top: 21px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #b7b7ba;
  resize: none;
}
.mainFeedbackSection .FBtext-Area::placeholder {
  color: #b7b7ba;
}
.mainFeedbackSection .FBtext-Area:focus {
  outline: none;
  box-shadow: none;
}
.mainFeedbackSection .FBRatingSection {
  margin: 33px 0px;
  padding-left: 17px;
}
.mainFeedbackSection .FBRatingSection .react-stars span {
  margin: 0px 5px;
}
.mainFeedbackSection .FBRatingSection .FBRatingTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}
.InterviewModuleAssessmentTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
}
.mockHeadingSection {
  padding: 11.5px 23px;
  font-family: "Inter";
  font-style: normal;
  color: #ffffff;
  background: #13151b;
}
.mockHeadingSection span {
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  padding: 0px 12px;
}
.mockHeadingSection span img {
  margin-right: 8px;
}
.interviewQuestionsStatus {
  top: 10px;
  right: 15px;
  border-radius: 5px;
  background-color: #494d59;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding: 8px 10px;
}
.interviewQuestionsStatusbar {
  border-bottom: 2px solid #f9f9f9;
  width: 100%;
  position: relative;
  margin-top: 9px;
  margin-bottom: 30px;
}
.interviewQuestionsStatusbar div {
  border-bottom: 2px solid #05d871;
  position: absolute;
}
.currentQuesHold {
  border-radius: 10px;
  background-color: #13151b;
}
.currentQuesHold p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13.1129px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 30px;
}
.currentQuesHold span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13.1129px;
  line-height: 16px;
  color: #ffffff;
}
.otherUserCursor {
  position: absolute;
  width: 2px;
  height: 20px;
  background: #f44d5e;
  z-index: 9;
}

.interviewStatusModal .notestitle {
  font-weight: 500;
  font-size: 15px;
  color: #3c3e4d;
}

.interviewStatusModal .status0ftheCandidate {
  border: 1px solid #eff0f1;
  border-radius: 5px;
}
.interviewStatusModal .status0ftheCandidate p {
  font-weight: 500;
  font-size: 14px;
  color: #494b58;
  text-align: center;
  width: 34%;
  cursor: pointer;
}
.interviewStatusModal {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  font-size: 14px;
}
.interviewPaperpage canvas {
  border: 1px solid black;
  width: 560px;
  height: 360px;
}
#video {
  border: 1px solid #999;
  width: 98%;
  max-width: 860px;
}
.interviewFeedbackndJitsiHld {
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  z-index: 9;
}
.usersIndication {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
}
.usersIndication div {
  width: 35px;
  height: 35px;
  position: absolute;
  opacity: 0.2;
  border-radius: 50px;
}
.candiateNinterviewerIndication {
  margin-left: -12px !important;
}
.interviewStatusModal .submitbtn {
  background: #e9505c;
  border-radius: 5px;
  color: #ffffff;
  border: 1px solid #e9505c;
  width: 120px;
  padding: 10px;
}
.finalInterviewStatus .modal-content {
  background: #f8f8f8;
  overflow: visible;
}
.selfUserContainer {
  background: #ffffff;
  border: 0.4px solid #d9d9d9;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
}
.selfUserContainer h6 {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #3c3e4d;
}
.selfUserContainer p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #92929d;
}
.finalInterviewStatus .modal-header {
  border: none;
  padding-bottom: 0;
}
.finalInterviewStatus .closeBtn {
  filter: brightness(0%);
  width: 15px;
  height: 15px;
}
.finalInterviewStatus .modal-body .statusSection {
  border: 0.4px solid #d9d9d9;
  border-radius: 5px;
  background: #ffffff;
  text-align: center;
}
.finalInterviewStatus .modal-body .statusSection p:not(:last-child) {
  border-right: 0.4px solid #d9d9d9;
}
.finalInterviewStatus .modal-body .statusSection p {
  font-size: 14px;
}
.finalInterviewStatus .modal-body .statusSection p:hover {
  background: #92929d;
  color: #ffffff;
  cursor: pointer;
}
.finalInterviewStatus .modal-body {
  padding-top: 0;
}
.finalInterviewStatus .modal-body h5 {
  margin-bottom: 38px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
.finalInterviewStatus .showQuestionAssessment {
  color: #ffffff;
  font-size: 14px;
  display: flex;
  cursor: pointer;
}
.successfullCompilation {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #05d871 !important;
}
.showQuestionAssessment img {
  filter: invert(1);
}
@media only screen and (min-width: 2560px) {
  .hungupBtnHld {
    right: 85px;
  }
  .interviewFeedbackndJitsiHld {
    width: 100%;
  }

  .expandedvideoChatBlock {
    height: 230px;
    width: 100%;
    right: 0;
  }
  .mainFeedbackSection {
    top: 220px;
  }
}

.remote-caret {
  position: absolute;
  border-left: black;
  border-left-style: solid;
  border-left-width: 2px;
  height: 1em;
}
.remote-caret > div {
  position: relative;
  top: -0.4em;
  font-size: 13px;
  background-color: rgb(250, 129, 0);
  font-family: serif;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  user-select: none;
  color: white;
  padding-left: 2px;
  padding-right: 2px;
  z-index: 3;
  border-radius: 20%;
}
svg {
  touch-action: none;
  /* position: absolute; */
  top: 0px;
  left: 0;
  /* background-color: #ffffff; */
}

.canvas-container {
  /* position: fixed;
  top: 0px;
  left: 0px; */
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.canvas-layer {
  width: 100%;
  height: 100%;
}

.canvas-shape {
  position: absolute;
  top: 0px;
  left: 0px;
}

.canvas-controls {
  position: absolute;
  top: 0px;
  right: 0px;
}

.canvas-controls > button {
  background: none;
  border: none;
  padding: 24px 20px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: dodgerblue;
  cursor: pointer;
}
.mockInfo {
  flex-wrap: wrap;
}
