.jobsTitleTab {
  font-weight: 300;
}

.totalJobsBoard {
  position: relative;
  /* height: calc(100vh - 270px); */
  border-right: 1px solid #e4e4e5;
  /* overflow: auto; */
  cursor: pointer;
  /* padding: 0px; */
}

.jobTabInsideDescription {
  font-size: 16px;
}
.adminViewJobs {
  margin-top: -25px;
}

.onlyJobsTab {
  height: calc(100% - 150px);
  overflow-y: auto;
}
.companyImageTab {
  width: 47px;
  height: 45px;
  border: 1px solid #e4e4e5;
  border-radius: 5px;
}

.jobDescriptionTextRight {
  border-bottom: 0.5px solid #e4e4e5;
}

.jobTabDescriptionText {
  color: #747a8a;
  font-size: 14px;
}

.jobPagination {
  /* position: sticky; */
  width: 100%;
  background: #f4f4f7;
  bottom: 0;
}

.jobData {
  margin-left: 15px;
}

.jobDescriptionText {
  width: 105px;
  border-bottom: 4px solid #3c3e4d;
}

.totalJobTabDescription {
  height: calc(100vh - 94px);
  overflow: auto;
}

/* .jobTabDetials img{
  width: 15px;
  height: 15px;
} */

.jobTabDescription h6 {
  font-size: 20px;
}

.eachJobData {
  border-bottom: 0.5px solid #e4e4e5;
  padding-top: 4.6px;
  padding-bottom: 4.6px;
}

.jobData h6 {
  font-size: 14px;
  font-weight: 500;
  color: #3c3e4d;
}

.postedName {
  padding-right: 10px;
  font-size: 14px;
}

.postedBy {
  font-weight: 400;
  font-size: 14px;
  color: #747a8a;
}

.jobTabRight {
  border-bottom: 1px solid #e4e4e5;
}

.postedName {
  font-weight: 500;
  color: #747a8a;
}

.jobTabDate p {
  font-size: 12px;
  font-weight: 300;
  color: #3c3e4d;
}

.dateDot {
  width: 5px;
  height: 5px;
  background: #3c3e4d;
  border-radius: 50%;
}

.insideJobCardMenu {
  padding-left: 20px;
  padding-right: 20px;
}

.menuIconJobTab {
  width: 27px;
  height: 27px;
  cursor: pointer;
}

.appliedData {
  width: 150px;
  height: 27px;
  background: #e4e4e5;
  border-radius: 20px;
  margin-right: 10px;
}

.appliedData p {
  font-size: 14px;
  color: #3c3e4d;
}

.eachJobData .jobTabBottomCards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.jobTabDetials {
  display: flex;
  align-items: center;
}

.jobTabBottomCards {
  max-width: 90%;
  margin-left: 70px;
}

.jobTabDetials p {
  font-size: 13px;
  font-weight: 400;
  color: #3c3e4d;
}

.jobAboutInfoTab .jobTitleInfoTab {
  border-bottom: 2px solid #f4f4f5;
  position: relative;
}
.jobAboutInfoTab .jobTitleInfoTab::after {
  position: absolute;
  border-bottom: 3px solid #3c3e4d;
  content: "";
  width: 85px;
}

@media only screen and (max-width: 1275px) {
  .companyImageTab {
    width: 30px;
    height: 30px;
  }
  .jobData h6 {
    font-size: 10px;
  }
  .jobTabDate p {
    font-size: 10px;
  }
  .appliedData {
    width: 80px;
    height: 27px;
    margin-right: 10px;
  }

  .appliedData p {
    font-size: 8px;
  }
  .jobTabArrow {
    width: 15px;
    height: 15px;
  }
  .jobTabDetials p {
    font-size: 10px;
  }
  .jobTabDetials img {
    width: 12px;
    /* height: 15px; */
  }

  .jobTabDetials .jobTabBottomCardsImage {
    width: 12px;
  }
  .jobTabRight h6 {
    font-size: 12px;
  }
  .postedName {
    font-size: 12px;
  }
  .postedBy {
    font-size: 12px;
  }
  .jobTabInsideDescription {
    font-size: 12px;
  }
  .jobAboutInfoTab .jobTitleInfoTab::after {
    width: 70px;
  }
  .jobTabDescriptionText {
    font-size: 12px;
  }
  .jobsTitleTab {
    font-size: 12px;
  }
}
