.interviewPageCntr {
  padding: 0 28px 28px;
  margin-top: 100px;
  border-radius: 30px;
  background-color: #fff;
  height: calc(100vh - 130px);
}
.interviewPageCntr .gobackIcon {
  width: 22px;
  height: 22px;
}
.interviewPageCntr .assessmentType {
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  display: inline-block;
}
.candidateDetails {
  padding: 18px 0;
}
.assessmentSubHeaderNav {
  border-bottom: 1px solid #e2e2ea;
}
.scroll-Off {
  height: calc(100vh - 50px);
  overflow-y: hidden;
}
.assessNav {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3c3e4d;
}
.gobackIconAssess {
  width: 24px;
  margin-left: 30px;
}
.assignmentTypeHead {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}
.candidateDetails li {
  list-style: none;
  display: inline;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
}
.candidateDetails li:after {
  content: "";
  padding: 0;
  border: 0.5px solid #e2e2ea;

  margin: 0 15px;

  font-size: 14px;
}
.candidateDetails li:last-child:after {
  content: none;
}
.question-Type {
  background: #f2f3f4;
  height: calc(100vh - 110px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.assessmentTypeName {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3c3e4d;
  margin-top: 34px;
  margin-bottom: 9px;
}
.assessmentTypeDescription {
  font-family: "Inter";
  font-style: normal;
  max-width: 185px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #92929d;
}
.assessmentTypeHld:hover > .assessmentTypeTxt {
  border-left: 1px solid #fff;
}

.assessmentTypeHld:hover > .assessmentTypeTxt .assessmentTypeDesc {
  color: #fff;
}
.codingImg {
  padding: 30px 30px 30px 12px;
}
.whiteBoardImg {
  padding: 52px;
}
.assessmentImg {
  padding: 40px 40px 40px 50px;
}

.assessmentTypeImg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 222px;
  height: 196px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
}
.assessmentTypeTitle {
  font-size: 14px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
  width: 150px;
}

.assessmentTypeDesc {
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  color: #82848e;
}
.interviewTypeImg {
  background: #292c37;
}
.interviewTypeName {
  color: #ffffff;
}
.interviewTypeDescription {
  color: #ffffff;
}
.rightArrow {
  width: 16px;
  margin-left: auto;
}
.interviewAssessType {
  height: calc(100vh - 104px);
  background: #f7f7f7;
}
.candidateswaitinglobby {
  background: #f4f4f4;
  border-radius: 20px;
  text-align: center;
  height: calc(100vh - 150px);
}
.candidateswaitinglobby img {
  width: 400px;
  height: 400px;
}
.candidateswaitinglobby .heytext {
  font-weight: 300;
  font-size: 24px;
  color: #212529;
}
.candidateswaitinglobby .infoaboutinterviewer {
  font-weight: bold;
  font-size: 26px;
  color: #212529;
}
