.jobsCntr {
  margin-top: 63px;
}
h1 {
  margin-bottom: 0;
}
.jobsData p {
  margin: 0;
  padding: 0;
}

.jobsData {
  background-color: #ffffff;
  border-radius: 20px 20px 20px 20px;
  /* max-width: 1400px; */
  margin: 0 auto;
  position: relative;
}

.jobsDataNavbarContainer {
  background-color: #3c3e4d;
  border-radius: 20px 0 0 20px;
  padding: 0;
  margin-top: 200px !important;
  height: calc(100vh - 150px);
}

.jobsDataHeadingContainer {
  height: 50px;
  background-color: #f2f3f4;
  border-radius: 0px 0px 0px 0px;
  padding: 16px 0px;
}

.jobsDataHeadingContainer h2 {
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #212529;
}

.jobsTabContainer {
  width: 50%;
  display: flex;
  margin: 10px auto;
  border-radius: 15px;
  background-color: #f4f4f4;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  color: #3c3e4d;
}

.jobsData .activeJobTab {
  padding: 8px;
  border-radius: 15px 0 0 15px;
}
.jobCard {
  border: 1px solid #e2e2e9;
  border-radius: 10px;
  width: 575px;
  height: 89px;
  margin: 19px auto;
  padding: 15px;
  position: relative;
  cursor: pointer;
}
.jobCard:hover {
  box-shadow: 1px 4px 11px 6px rgba(0, 0, 0, 0.05);
}

.searchBox {
  display: inline;
  padding: 7px 15px;
  height: 30px;
  width: 100%;
  background: #f2f3f4;
  border: 0.5px solid #ffffff;
  border-radius: 50px;
}
/* .postedJobs {
  /* position: relative; 
} */
.searchBox .formInput {
  all: unset;

  padding: 0px 5px;
}

.optionsDropdown ul {
  list-style-type: none;
}
.optionsDropdown {
  position: absolute;
  top: 17px;
  right: 45px;
  z-index: 99999;
  width: 201px;
  background-color: #ffffff;
  box-shadow: 0px 2px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  align-items: center;
}
.dotOptions .forActive {
  height: 252px;
}
.dotOptions .forDrafts {
  height: 126px;
}
.dotOptions .forClosed {
  height: 126px;
}
.optionsDropdown li span {
  margin-left: 20px;
  font-size: 12px;
}
.optionsDropdown li {
  color: #666666;
  padding: 9px 30px;
}
.optionsDropdown li:hover {
  background-color: #f2f3f4;
}
.optionsDropdown img {
  color: #666666;
  width: 15px;
  height: 15px;
}
.jobCard .dotOptions {
  color: #666666;
  cursor: pointer;
}
.dotOptions .threeDotOptions {
  width: 25px;
  height: 12px;
}

.jobCard .jobCardData .views {
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  font-weight: 500;
}
/* .jobCard .jobCardData {
  margin-left: 28px;
} */
.jobCard .jobCardData .views span {
  margin-right: 9px;
}
.jobCard .jobCardData .applyCount {
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
  color: #3c3e4d;
}
.jobsData .unpublishedJobTab {
  padding: 8px;
  border-radius: 0 15px 15px 0;
}
.jobsData .activeJobTab.tabActive,
.jobsData .unpublishedJobTab.tabActive {
  background-color: #3c3e4d;
  font-family: Roboto;
  color: #fff;
}

.totalJobsSortContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 20px; */
  padding: 0 41px 0 0;
}

.jobsAddJob {
  cursor: pointer;
}

.jobsAddJobText {
  font-size: 16px;
}

.jobsAddJobText .jobsAddJobImage {
  width: 15px;
  height: 15px;
}

.totalJobsSortContainer h4 {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  color: #3c3e4d;
}
.totalJobsSortContainer select {
  padding: 4px 0px 4px 5px;
  margin-left: 10px;
  border-radius: 30px;
  border: solid 1px;
  text-align: left;
  outline: none;
  font-size: 16px;
  color: #3c3e4d;
}

.jobsDataContentContainer {
  /* overflow: auto; */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background: #ffffff;
}

.jobsDataContentContainer::-webkit-scrollbar {
  /*chrome */
  display: none;
}
.jobsDataContentContainer .jobsDataLoader {
  padding: auto;
  margin: auto;
}
.tabsWindow .tabBtnGroup {
  margin-left: 85px;
}
.loaderTab {
  margin-left: 116px;
}

.searchJobs {
  border-left: 1px solid #d8d8d8;
  /* border-right: 1px solid #a5a6aa; */
  border-bottom: 1px solid #d8d8d8;
  padding: 15px 0px 15px 10px;
  position: relative;
  display: flex;
}
.searchJobs input {
  position: relative;
  outline: none;
  width: 100%;
  border: solid 1px #eaeaea;
  border-radius: 50px;
  padding: 10px 0 10px 20px;
}

.searchJobs .searchIcon {
  position: absolute;
  top: 28%;
  right: 25%;
  padding: 6px 18px;
  height: 34px;
  cursor: pointer;
}

.searchJobs select {
  padding: 4px 0px 4px 5px;
  margin-left: 10px;
  border-radius: 30px;
  border: solid 1px;
  text-align: left;
  outline: none;
  font-size: 16px;
  color: #3c3e4d;
}

.jobsAddJobText {
  padding: 10px 30px;
  background-color: #f44d5e;
  color: white;
  border-radius: 5px;
}

.jobsInsideSelectTag {
  margin-right: 10px;
}

.jobsCards {
  height: calc(100vh - 278px);
  padding: 0;
}
.jobTabs .postedJobs {
  overflow: auto;
  height: calc(100vh - 190px);
}
.postedJobs::-webkit-scrollbar {
  display: none;
}

.jobTabs .postedJobs .jobCard .companyLogo {
  width: 67px;
  height: 62px;
}

/* .sortUsers select{
    padding: 8px;
    margin-left: 10px;
    border-radius: 30px;
    border: none;
    text-align: center;
} */

.jobsCardContainer {
  /* border-bottom: 1px solid #a5a6aa; */
  height: calc(100vh - 355px);
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.jobsCardContainer::-webkit-scrollbar {
  /*chrome */
  display: none;
}

.jobDetails {
  height: calc(100vh - 278px);
  /* padding: 0 0 0 50px; */
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border-left: 1px solid #d8d8d8;
}
.jobDetails::-webkit-scrollbar {
  display: none;
}

.noJobs {
  margin-top: 150px;
}
.noJobsIconNew {
  margin-left: -45px;
}
.noJobs .noJobsHeading {
  margin: 47px 0px 10px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  color: #3c3e4d;
}
.noJobs .noJobsTagLine {
  color: #b0b0b4;
  font-weight: 400;
  font-size: 14px;
  margin: 0px 0px 44px;
}
.titleBox {
  width: 100%;
  background: #f2f3f4;
}
.newJobTitle {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}

.noJobs button {
  height: 40px;
  width: 157px;

  border-radius: 20px;
  background-color: #f44d5e;
  border: none;
  font-family: Roboto;
  font-size: 16px;
  line-height: 18.75px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}
.noJobs button img {
  width: 10px;
  height: 10px;
  margin-left: 16px;
  margin-top: -3px;
}

.noJobsImage {
  height: 200px;
}
/* .noJobs button .noJobsIconImg {
  margin-right: 10px;
  width: 15px;
  height: 15px;
} */

.noJobs button {
  font-size: 14px;
}

.noSearchResult {
  margin-top: 180px;
}
.noSearchResult p {
  margin: 30px 0;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  color: #3c3e4d;
}
.noSearchResult img {
  height: 150px;
  width: 130px;
}

/* All Jobs  */
.btn-tabs {
  box-sizing: border-box;
  width: 120px;
  height: 30px;
  background: #ffffff;
  border: 0.5px solid #e2e2e9;
  border-radius: 50px;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 400;
  font-family: Inter;
}
.btn-tabs:hover {
  box-shadow: 1px 4px 13px 6px rgba(0, 0, 0, 0.04);
}
.allJobsHead {
  display: flex;
  justify-content: space-between;
}
.searchHeadJobs input {
  border: none;
  background-color: #f2f3f4;
  border-radius: 50px;
  height: 30px;
  width: 400px;
}
.searchHeadJobs .searchIcon {
  width: 11px;
  height: 11px;
  left: 2;
}
.active-tab {
  background-color: #3c3e4d;
  color: #ffffff;
}
.jobTabs {
  border-radius: 10px;
  margin-top: 18px;
}
.totalJobs {
  text-align: center;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 0px 0px;
  color: #666666;
  height: 36px;
  background-color: #f2f3f4;
}
.allJobsContainer {
  margin: 75px 0px 0px 0px;
}
.sharejob {
  color: #666666;
  text-decoration: none;
}
.sharejob:hover {
  color: #666666;
}
.joblistDropDown {
  right: 25px;
  top: 10px;
}
@media only screen and (min-width: 2560px) {
  .jobsDataHeadingContainer h2 {
    font-size: 28px;
  }
  .totalJobsSortContainer h4 {
    font-size: 24px;
  }
  .totalJobsSortContainer select {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1366px) {
  .noJobs img {
    height: 150px;
    width: 130px;
  }
  .noJobs p {
    font-size: 20px;
  }

  .noSearchResult {
    margin-top: 30px;
  }

  .noSearchResult p {
    font-size: 20px;
  }
  .noSearchResult img {
    height: 130px;
    width: 110px;
  }
}

@media only screen and (max-width: 767px) {
  .jobsDataNavbarContainer {
    display: none;
  }
  .jobsTabContainer {
    font-size: 20px;
  }
}
/*new code */
