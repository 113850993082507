.performanceCardTab {
  padding: 20px 20px;
  border: 1px solid #e2e2e9;
  background: #fff;
}
.performanceCardTabText,
.performanceCardHld .performanceOverview .questionTab {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.performanceCardHld .performanceOverview .questionTab {
  border-radius: 10px;
  background: #e5f8ec;
  height: 38px;
  text-align: center;
  line-height: 38px;
}
.performanceCardHld .isHovered.performanceOverview {
  opacity: 0.5;
  border: 1px solid #77777a;
}
.performanceOverview {
  border: 1px solid #e2e2e9;
  border-top: none;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(40px, auto);
  row-gap: 15px;
  max-height: 215px;
  overflow-x: scroll;
  position: relative;
  z-index: 9999999;
}
.performanceCardHld .performanceOverview .wrongAnswer {
  background: #feedef;
}
.solutionOverview .questionOption {
  color: #000;
  font-size: 11px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 20px;
}
.performanceCardHld .performanceOverview .unattemptedAnswer,
.selectedQuestionOption {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: transparent;
}
.solutionOverview .selectedQuestionOption {
  border-top-right-radius: 0px;
}
.selectedQuestionOption .selctionTab {
  position: absolute;
  top: -22px;
  right: -1px;
  padding: 2px 8px;
  font-size: 11px;
  font-weight: 500;
  line-height: 18px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.solutionOverview {
  position: absolute;
  border: 1px solid #e2e2e9;
  top: -350px;
  right: 0;
  z-index: 999999;
  border-radius: 10px;
  background: #fff;
  height: 400px;
  overflow-x: scroll;
  min-width: 487px;
  max-width: 656px;
  width: max-content;
}
.solutionOverview .questionTitle {
  color: #000;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
}
.solutionOverview .outerCircle {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #d9d9d9;
  background-color: #fff;
  border-radius: 50%;
  padding: 3px;
  text-align: center;
}
.solutionOverview .innerCircle {
  display: block;
  height: 8px;
  width: 8px;
  background-color: green;
  border-radius: 50%;
}
.solutionOverview .incorrectOuterCircle {
  border: 2px solid #f44d5e;
}
.solutionOverview .incorrectOuterCircle .innerCircle {
  background-color: #f44d5e;
}
.solutionOverview .correctOuterCircle {
  border: 2px solid green;
}
.sectionSelection {
  border: 1px solid #e2e2e9;
  font-family: "inter";
  font-size: 12px;
  font-weight: 400;
  color: #3c3e4d;
  position: relative;
  border-radius: 50px;
  min-height: 40px;
}
.sectionSelection .statusList {
  left: 10px;
  width: 95%;
}
.performanceCardBackdrop {
  position: absolute;
  bottom: 0;
  left: -228px;
  background-color: rgba(0, 0, 0, 0.4);
  height: calc(100% + 200px);
  width: calc(100vw + 10px);
  z-index: 99999;
}
.QuestionDescriptionHld {
  border-radius: 10px;
  border: 1px solid #e2eef6;
  background: #f1f9fe;
  color: #000;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}