.signUpSection .carouselContainer {
  width: 75%;
  margin: 0 auto;
  text-align: left;
}
.signUpSection .carouselContainer p {
  line-height: 29px;
}
.signUpSection .loginInputHld input {
  border: none;
  padding: 0px 0px 0px 10px;
  width: 100%;
}
.signUpSection .loginInputHld {
  border: 1px solid #b4bac3;
  border-radius: 5px;
  padding: 12px;
  display: flex;
  align-items: center;
}
.signUpSection .loginInputHld input:focus-visible {
  outline: none;
}
.signUpSection label {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #454b54;
}
.signUpSection .signUpLeftContainer {
  background: #3c3e4d;
  text-align: center;
}

.signUpSection .signUpHeadText {
  color: #f44d5e;
}
.signUpSection .successEmail {
  height: 80px;
  width: 80px;
}
.genpact_logo{
  height: 35px;
}
