.hackathonWorkExperience {
  padding: 16px 17px;
  color: #000000;
}

.hackathonWorkExperienceHeader {
  background: #ffffff;
  border: 0.5px solid #e2e2e9;
  padding: 11px 9px;
  border-radius: 5px;
}

.hackathonWorkExperience > p {
  line-height: 24px;
}

.hackathonEducation {
  padding: 16px 17px;
}

.currentCompanyData {
  color: #000000;
}

.hackathonDataVerticalBar {
  width: 0px;
  height: 16px;
  background: #000000;
  border: 0.5px solid #3c3e4d;
  border-radius: 5px;
}

.hackathonResumeDownload {
  background: #f2f3f4;
  padding: 15px;
}

.hackathonResumeDownload > button {
  display: block;
}
.hackathonResumeDownload .activeTab {
  position: absolute;
  top: 20px;
  right: 5%;
  padding: 5px 10px;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.32px;
  border-radius: 50px;
  border: none;
  z-index: 999;
  background: linear-gradient(274deg, #F44D5E -5.92%, #F46B4D 114.01%);
}