.requestHackathonModalBody{
  font-family: 'Inter' , sans-serif;
}

.requestHackathonModalClose {
  position: absolute;
  right: 20px;
  top: 20px;
}

.requestHackathonModalInput {
  all: unset;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #DDD;
  padding: 11px 18px;
  height: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
}

.requestHackathonModalInput::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #BBB;
}

.requestHackathonModalHeaderDiv {
  display: block;
  border: 0.5px solid #b6b6b6;
}

.requestHackathonModalTextArea {
  all: unset;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 10px 15px 10px 30px;
  height: fit-content;
  color: #666666;
  border: 1px solid #e2e2e9;
  border-radius: 5px;
}
.requestHackathonModalError {
  border: 1px solid #f89b9c;
}

.requestHackathonModalSubmit > img,
.requestHackathonModalErrorMessage > img {
  vertical-align: initial;
}

.requestHackathonModalErrorMessage {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}

.requestHackathonModalSubmit {
  margin-top: 30px;
}

.requestHackathonModalBody {
  padding: 30px;
  padding-right: 0px;
}

.requestHackathonModalBody > div > div > span {
  color: #f44d5e;
}
