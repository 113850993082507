hr {
  margin: 0;
}
.jobForm {
  /* margin: 30px 0; */
  padding: 0px 30px;
}

.jobForm hr {
  margin: 2% 0;
}
.jobForm .form-group,
.salaryRange {
  padding: 0.5% 25%;
}

.jobForm label {
  color: #3c3e4d;
  font-size: 18px;
  font-weight: 400;
}

.jobForm .form-group label,
.jobForm .form-input {
  font-size: 16px;
}
.jobForm .form-input {
  margin: 10px 12px 10px 0;
}

.jobForm .form-input:focus::placeholder {
  color: transparent;
}

.jobForm .jobsCkEditor,
.jobForm .rti--container {
  margin: 10px 0px;
}

.jobForm .rti--container {
  border-radius: 5px;
}

.jobForm .salaryRange {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
}

/* .salaryRange .salaryRangeInputContainer{
    
} */
.salaryRange label {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #3c3e4d;
}
.salaryRange input {
  width: 100%;
  text-align: right;
}

.input-group-text {
  padding: 0.375rem 0.375rem !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.jobForm .buttonSubmit {
  padding: 8px 14px;
  border: none;
  border-radius: 5px;
  background-color: #f44d5e;
  color: #fff;
  width: 40%;
}
.jobForm .buttonCancel {
  padding: 8px 14px;
  border-radius: 5px;
  border: solid 2px #212529;
  background: #fff;
  width: 40%;
}

/* Ck editor */
.ck.ck-editor {
  margin-bottom: 10px !important;
}

.ck.ck-toolbar {
  border-radius: 5px 5px 0 0 !important;
}
.ck.ck-content {
  border-radius: 0 0 5px 5px !important;
  min-height: 200px;
}
/*Tags input custom css */

.rti--input.go3450369076 {
  padding-left: 9px !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border-radius: 5px;
}
.go309598777 {
  margin-bottom: 10px;
  border: solid 1px #e5e5e59e !important;
}
.rti--container.go309598777:focus-within {
  /* border-color: #888888 !important; */
  box-shadow: none;
}
.go309598777 span {
  background-color: #ededee;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3c3e4d;
  padding: 2px 9px;
  border-radius: 50px;
}
.go309598777 button {
  color: #3c3e4d;
}

.submitSuccessMessage {
  padding: 30px;
}

.submitSuccessMessage h4 {
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  color: #3a3a3a;
  margin: 40px 0 20px 0;
}

.submitSuccessMessage p {
  font-family: Roboto;
  font-size: 18px;
  color: #a5a6aa;
  margin-bottom: 30px;
}

/*custom modal */
.submitSuccessModal {
  width: 400px !important;
}

.submitSuccessModal .modal-header {
  border-bottom: none;
}

/* .addJobTotalSkillsSection{
  height: 40px;
} */

@media only screen and (min-width: 2560px) {
  .jobForm .form-group label,
  .jobForm .form-input {
    font-size: 18px;
  }
}

.addJobFormScroller {
  overflow: auto;
  height: calc(100vh - 206px);
}

.addJobPreview {
  /* padding-left: 30px; */
  /* margin: 30px 0px; */
  border-left: 0.5px solid #e4e4e5;
}

.addJobPreviewTextSection {
  background-color: #f7f6f9;
}

.addJobShowTitle {
  font-size: 18px;
  font-weight: 500;
  color: #3c3e4d;
}

.addJobShowTitleBefore {
  font-size: 18px;
  font-weight: 500;
  color: #a5a6aa;
}

.addJobPreviewText {
  font-weight: 700;
  font-size: 20px;
  color: #3c3e4d;
}

.addJobPostedBy {
  font-weight: 300;
  font-size: 14px;
  color: #747a8a;
}

.addJobExpirence {
  font-weight: 400;
  font-size: 16px;
  color: #747a8a;
}

.addJobExpirenceBefore {
  font-weight: 400;
  font-size: 16px;
  color: #a5a6aa;
}

.addJobDetials .eachAddJobDetials {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.eachAddJobDetials {
  max-width: 90%;
  margin-left: 31px;
}

.addJobAboutInfoTab .addJobTitleInfoTab {
  border-bottom: 2px solid #f4f4f5;
  position: relative;
}
.addJobAboutInfoTab .addJobTitleInfoTab::after {
  position: absolute;
  border-bottom: 3px solid #3c3e4d;
  content: "";
  width: 57px;
}

.addJobAboutInfoTabDescription .addJobTitleInfoTabDescription {
  border-bottom: 2px solid #f4f4f5;
  position: relative;
}
.addJobAboutInfoTabDescription .addJobTitleInfoTabDescription::after {
  position: absolute;
  border-bottom: 3px solid #3c3e4d;
  content: "";
  width: 117px;
}

.addJobAboutInfoTabPostedBy .addJobTitleInfoTabPostedBy {
  border-bottom: 2px solid #f4f4f5;
  position: relative;
}
.addJobAboutInfoTabPostedBy .addJobTitleInfoTabPostedBy::after {
  position: absolute;
  border-bottom: 3px solid #3c3e4d;
  content: "";
  width: 115px;
}

.addJobTabInsideDescription {
  font-size: 22px;
  font-weight: 500;
  columns: #3c3e4d;
}

.addJobTabInsideDescriptionPostedBy {
  font-size: 22px;
  font-weight: 500;
  columns: #3c3e4d;
}

.addJobTabInsideDescriptionDescription {
  font-size: 22px;
  font-weight: 500;
  columns: #3c3e4d;
}

.addJobPreviewSkills {
  border: 1px solid #e4e4e5;
  /* padding: 10px; */
  margin-bottom: 20px;
  border-radius: 30px;
  margin-right: 10px;
  color: #3c3e4d;
  font-size: 14px;
  font-weight: 400;
}

.addJobPreviewSkillsBefore {
  border: 1px solid #e4e4e5;
  border-radius: 30px;
  margin-right: 10px;
  color: #a5a6aa;
  font-size: 16px;
  font-weight: 400;
}

.addJobDescriptionInnerText {
  color: #3c3e4d;
}

.addJobPreviewTotalSkills {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1150px) {
  .eachAddJobDetials {
    margin-left: 11px;
  }
}
