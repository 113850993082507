.AssessmentCntr {
  margin-top: 50px;
  font-family: "Inter";
}
.assessmentSetting {
  font-family: "Inter";
}
.assessmentSettingTag {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}
.assessmentSettingNavbar {
  background: #f2f3f4;
  border-right: 1px solid #e2e2e9;
  height: calc(100vh - 94.5px);
}
.testDetails .formInput {
  border-radius: 50px;
  border: 1px solid #e2e2e9;
  font-weight: 400;
  font-size: 12px;
  color: #000000 !important;
  padding: 10px;
  font-family: "Inter";
  padding-left: 17px !important;
  width: 100%;
  background: #f2f3f4;
}

.testDetails .formInput::placeholder {
  font-weight: 400;
  font-size: 12px;
}
.testDetails .formInput:focus-visible {
  outline: 1.5px solid #e2e2e9;
  border-radius: 50px;
}
.assessmentModalInputField .formInput {
  font-weight: 400;
  font-size: 12px;
  color: #000000 !important;
  padding: 7px;
  font-family: "Roboto";
  padding-left: 17px !important;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
}
.assessmentModalInputField .formInput::placeholder {
  font-weight: 400;
  font-size: 12px;
  color: #b1b2b8;
}
.assessmentModalInputField .formInput:focus-visible {
  outline: 1.5px solid #d9d9d9;
  border-radius: 50px;
}
.assessmentSettingTestNameInp ::placeholder {
  padding-left: 12px;
}
.assessmentSettingNavbarTags {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  background: transparent;
  border-bottom: 1px solid #e2e2e9;
  padding: 15px 20px;
  cursor: pointer;
}
.assessmentSettingNavbar :focus-within {
  background: red;
}
.assessmentSettngSaveBtn .primaryBtnActive {
  font-size: 14px;
  font-family: "Roboto";
  padding: 8px 40px;
}
.assessmentSettngSaveBtn .secondaryOutlineBtn {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  padding: 8px 30px;
}
.assessmentSettngAddBtn .secondaryOutlineBtn {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  padding: 8px 130px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 12px;
  color: #3c3e4d;
}
.assessmentTestDetailsLabel {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}
.assessmentSettingMinuteLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}
.assessmentSettingGeneralDivider {
  border: 0.5px solid #e2e2e9;
}
.assessmentSettingScoreDescription {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  line-height: 15px;
}
.assessmentSettingScoreNote {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #009ffb;
}
.assessmentSettingNavbarTagsActive {
  background: #ffffff;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: #3c3e4d;
  border-bottom: 1px solid #e2e2e9;
  padding: 15px 20px;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #00bd3f;
  border: none;
}
.form-check-input {
  width: 0.5em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #c4c4c4;
  background-repeat: no-repeat;
  background-size: auto;
  border: none;
  appearance: none;
}
/*.form-switch .form-check-input {
  width: 30px;
  margin-left: -2.5em;
  background-image: url("../../../assets/Icons/Assessment/assessmentToggle.svg");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
  height: 14px;
  background-position: unset;
}
.form-switch .form-check-input:focus {
  background-image: url("../../../assets/Icons/Assessment/assessmentToggle.svg");
  background-position-y: inherit;
}*/

.form-check-input:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.assessmentSettingCandidateDetails {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #e2e2e9;
  border-radius: 2px;
  margin: 0;
  padding: 6px;
  font: inherit;
  color: currentColor;
  width: 14px;
  height: 14px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
.candidateViewForm {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  text-decoration-line: underline;
  color: #f44d5e;
  cursor: pointer;
}
.enterpriseAssessmentSettingCandidateDataTextArea {
  background: #f2f3f4;
  border: 1px solid #e2e2e9;
  border-radius: 5px;
  padding: 10px;
  width: 99%;
  height: 85px !important;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  font-family: Inter;
}

.enterpriseAssessmentSettingCandidateDataTextArea::placeholder {
  font-weight: 400;
  font-size: 12px;
  color: #b1b2b8;
}

.assessmentSettingCandidateDetails::before {
  content: "";
  width: 8px;
  height: 8px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  background: #ffff;
}

.assessmentSettingCandidateDetails:checked {
  background: #3c3e4d;
  border: none;
}

.assessmentSettingCandidateDetails:checked::before {
  transform: scale(1);
}

.assessmentAddCustomFields .formInput {
  background: transparent;
  border-radius: 5px;
  background: #f2f3f4;
  color: #666666 !important;
}
.assessmentAddCustomFields .primaryBtnActive {
  background: #3c3e4d;
  border: 1px solid #3c3e4d;
  padding: 5px 40px;
  font-size: 12px;
}
.assessmentAddCustomFields .secondaryOutlineBtn {
  padding: 5px 25px;
}

.assessmentAddCustomFields .formInput:focus-visible {
  outline: 1.5px solid #d9d9d9 !important;
  border-radius: 10px !important;
}
.assessmentSettingEditor
  .ck.ck-editor__main
  > .ck-editor__editable:not(.ck-focused) {
  border-color: #d9d9d9 !important;
}

/* //Modal// */
.assessmentModalGreetTag {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #3c3e4d;
}
.assessmentModalTestName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #f44d5e;
}
.assessmentModalTestDuration {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}
.assessmentModalTestTime {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}
.assessmentModalSKillTag {
  background: #f2f3f4;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding: 3px 10px;
  color: #666666;
}
.assessmentModalIntroduction {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}
.assessmentModalInputLabel {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}
.assessmentModalButton .primaryBtnActive {
  padding: 7px 30px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}
.assessmentModalInputSection {
  background: #f2f3f4;
  height: calc(100vh - 200px);
  overflow: auto;
  border-bottom-right-radius: 10px;
}
.assessmentModalMandatoryLabel {
  color: #f44d5e;
}
.assessmentCandidateFormForScroll {
  height: calc(100vh - 140px);
  overflow: auto;
  max-width: 100%;
  overflow-x: hidden;
}
.assessmentCandidateFormForScroll::-webkit-scrollbar {
  /*chrome */
  display: none;
}
.assessmentSettingModal {
  position: relative;
  flex: 1 1 auto;
  padding: 0px 16px 0px 40px !important;
}
.assessmentModalHeader {
  font-size: 18px;
  margin-left: 20px;
}
.assessmentSettingModalBody :focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}
.assessmentSettingModalBody .btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: 1;
}
@media (min-width: 1200px) {
  .assessmentSettingModalBody .modal-xl {
    max-width: 1140px;
  }
}
/* 
Modal */

.assessmentSettingEditor {
  font-size: 12px !important;
}
.errMessage {
  color: #f44d5e;
  font-size: 12px;
}
.resumehld {
  min-height: 100px;
  background-color: #f9f9f9;
  border-radius: 10px;
  border: dashed 2px #dfdfdf;
  width: 100%;
  text-align: center;
}
.resumehld::placeholder{
  font-size: 14px;
}
