.mockQuestion{
    margin: 0 0 25px 0;
}

.mockAnswerList p {
    margin: 10px 0;
}
.mockQuestion p, .mockQuestion p{
    font-family: Roboto;
  font-size: 14px;
  text-align: left;
  color: #3c3e4d;
}