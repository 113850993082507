h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
.mockCard {
  border-bottom: 1px solid #d8d8d8;;
  /* border-right: 1px solid #a5a6aa; */
  padding: 12px 25px;
  /* cursor: pointer; */
  position: relative;
}

.mockCard.activeCard {
  border-top: none;
  border-bottom: 1px solid #d8d8d8;
  background-color: #f3f5f7;
  border-right: none;
}

.mockInfoContainer {
  display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 12px 0 0px 0
}

.mockTitle h2 {
  /* font: 400 18px "Roboto", sans-serif; */
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #3a3a3a;
}

.mockInfo {
  display: flex;
  align-items: center;
}
.mockInfo img {
  height: 15px;
  width: 15px;
  object-fit: contain;
  margin: auto 10px auto 0;
}
.mockInfo p{
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  color: #3a3a3a;
  word-break: break-word;
}

.mockInfo span{
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
    color: #3a3a3a;
    word-break: break-word;
    margin: auto 10px auto 0;
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="radio"]::before {
  border: 2px solid gray; 
  height: 20px; 
  width: 20px; 
  border-radius: 50%; 
  display: block; 
  content: " "; 
  cursor: pointer; 
  visibility: visible;
}

input[type="radio"]:checked::before { /* selected */
  background:  radial-gradient(#3c3e4d 40%, transparent 50%);
}

/* .mockSelectContainer input{
  height: 20px;
    width: 20px;
    cursor: pointer;
} */


@media only screen and (min-width: 2560px) {
 
  
}