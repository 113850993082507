.invoicePopup {
  size: a4;
  margin: 50px auto 40px;
  max-width: 800px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 20px 5px;
}
.invoicePopup .modal-content {
  border: none;
}
.invoicePopup .invoicePopup-hld {
  padding: 60px 50px 80px;
}
.invoicePopup .modal-content .light-content {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  color: #111111;
}
.invoicePopup .modal-content .gray-content {
  color: #a5a7a9;
  font-weight: 500;
}
.invoicePopup .modal-content .dark-content {
  font-weight: 600;
}
.invoicePopup .modal-content .darkest-content {
  font-weight: 700;
}
.invoicePopup .billing-to {
  border-radius: 7px;
  background: #f9fbfa;
  padding: 10px 0;
}
.invoicePopup .table .table-head {
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  color: #a5a7a9;
}
.invoicePopup .divider {
  background: #a5a7a9;
  height: 1px;
  width: 100%;
  margin: 10px 0;
}
.invoicePopup .table .table-text {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  color: #111111;
}
.invoicePopup .total-amount {
  font-size: 14px;
  font-weight: 700;
  font-family: Inter;
  color: #111111;
}
.invoicePopup .downloadInvoice-btn {
  border-radius: 50px;
  background: #f44d5e;
  border: #f44d5e;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 14px;
  position: absolute;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  left: 50%;
  bottom: 20px;
}
