.candidate-info-cntr {
  background: #f2f3f4;
  width: 70%;
  padding: 17px;
  position: relative;
}
.candidate-info-cntr .user-profile-picture {
  width: 63px;
  height: 63px;
  border-radius: 150px;
}
.candidate-info-cntr .user-name {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
}
.candidate-info-cntr .user-job-details-text {
  color: #666;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}
.candidate-personal-details {
  border-radius: 5px;
  background: rgba(33, 140, 192, 0.05);
  padding: 5px 4px 5px 14px;
}
.candidate-personal-details .mh-profile-btn {
  background: #43a5d4;
  border: #43a5d4;
  border-radius: 50px 10px 10px 50px;
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 30px;
}
.candidate-personal-details .mh-profile-btn .external-link {
  filter: invert(97%) sepia(97%) saturate(5%) hue-rotate(163deg)
    brightness(103%) contrast(100%);
  vertical-align: baseline;
  margin-left: 4px;
}
.candidate-personal-details ul,
.education-details-list {
  list-style: none;
  display: inline-flex;
  margin: 0;
  padding: 0;
  align-items: center;
}
.candidate-personal-details ul li,
.education-details-list li {
  color: #3c3e4d;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}
.candidate-personal-details ul li:first-child::after {
  content: "";
  border-radius: 50px;
  background: #43a5d4;
  width: 3px;
  display: inline-table;
  height: 3px;
  vertical-align: middle;
  margin: 0 10px;
}
.candidate-personal-details .copy-hld {
  background: rgba(33, 140, 192, 0.23);
  border-radius: 50px;
  width: 40px;
  height: 26px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.user-ctc-details {
  list-style: none;
  margin: 0;
  padding: 10px 0;
}
.user-ctc-details li {
  color: #666;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
}
.user-ctc-details li::after {
  content: "";
  background: #e2e2e9;
  width: 1px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px;
}
.user-ctc-details li:last-child::after {
  display: none;
}
.user-ctc-details li span {
  color: #000;
}
.assess-btn {
  border-radius: 50px;
  border: 1px solid #3c3e4d;
  background: #fff;
  padding: 8px 24px;
  color: #3c3e4d;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}
.start-interview-btn {
  border-radius: 50px;
  background: #3c3e4d;
  padding: 8px 24px;
  border: 1px solid #3c3e4d;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}
.about-section {
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  margin: 20px 0;
}
.about-section .section-heading {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}
.about-section .section-description {
  color: #666;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
}
.skillset-hld {
  display: flex;
  flex-wrap: wrap;
}
.skillset-hld .skills-text {
  border-radius: 50px;
  background: #f2f3f4;
  padding: 6px 20px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.education-details-list {
  list-style: none;
}
.education-details-list li {
  display: inline-flex;
}
.education-details-list li:first-child::after {
  content: "";
  background: #3c3e4d;
  width: 1px;
  height: 15px;
  vertical-align: middle;
  display: inline-block;
  margin: 0 10px;
}
.year-of-passing {
  color: #666;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}
.activityInfo {
  display: flex;
  justify-content: flex-start;
  height: 12px;
  width: 140px;
}
.activityInfoText {
  line-height: 5px;
  font-size: 10px;
  font-weight: 500;
  text-transform: capitalize;
  font-family: Inter;
  color: #000000;
  margin-right: 6px;
}
.bagde-hld {
  display: inline-block;
  height: 73px;
  position: relative;
}
.bagde-hld .badge-bg {
  height: 65px;
  width: 65px;
}
.bagde-hld .badgeType {
  position: absolute;
  top: 9px;
  color: #000000;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  font-weight: 700;
  font-size: 7px;
  text-transform: uppercase;
}
.bagde-hld .badgeTier {
  background: linear-gradient(
    121.13deg,
    #dc4e5a 20.17%,
    #e2595b 31.41%,
    #f57f5f 71.17%,
    #fd8e61 92.78%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: absolute;
  top: 24px;
  font-weight: 600;
  font-size: 8px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
.bagde-hld .user-badge-img {
  position: absolute;
  top: 46px;
  width: 43px;
  left: 10px;
  height: 43px;
}
.candiate-cross{
  background-color: #3C3E4D;
  position: absolute;
  top: 0;
  right: 30px;
  padding: 5px 15px 15px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.candiate-cross img{
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(331deg) brightness(1000%) contrast(100%);
}