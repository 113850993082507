.loadingRow {
  background-color: #faf9f9;
}
.loadinghead {
  width: 40%;
  height: 15px;
}
.loadingButton {
  height: 30px;
}
.sectionTd input {
  background: #f2f3f4;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #666666;
  padding: 0px 2px;
  width: 40%;
}
.sectionTd {
  height: 21px;
}
.sectionTd .checked {
  height: 21px;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-backdrop.fade {
  opacity: 0;
}
.show {
  border-radius: 0px;
}
/* .AssessmentCntr {
  margin-top: 63px;
} */
.questionSummaryHld {
  font-family: "Inter";
}
.questionListContainer {
  height: calc(100vh - 50px);
}
/* question Summary start */
.questionSummaryHld table tr td,
.questionSummaryHld table thead th {
  padding: 12px 10px;
  border-bottom: none;
}
.questionSummaryHld table th:nth-child(2) {
  width: 16%;
}
.questionSummaryHld table tr:last-child td {
  border-bottom: 0.5px solid #d9d9d9;
}
.questionSummaryHld table tr td:last-child {
  width: 60px;
  padding: 12px 20px;
}
.questionSummaryHld table tr td:nth-child(5),
.questionSummaryHld table tr td:nth-child(7) {
  padding: 12px 30px;
}
.questionSummaryHld table tr {
  border: 0.5px solid #d9d9d9;
  border-bottom: none;
}
.questionSummaryHld table tr:first-child td:first-child {
  border-top-left-radius: 6px;
}
.questionSummaryHld table tr:first-child td:last-child {
  border-top-right-radius: 6px;
}
.questionSummaryHld table .tableMenu,
.sharedListItem .tableMenu {
  display: block;
  right: 30px;
  top: 0px;
  position: absolute;
  width: 160px;
  background: #ffffff;
  box-shadow: 0px 2px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  z-index: 1;
  cursor: pointer;
  padding: 0px 0px;
}
.sharedListItem .tableMenu {
  right: 15px;
}
.questionSummaryHld .tableMenu div,
.sharedListItem .tableMenu div {
  padding: 10px 16px;
}
.questionSummaryHld .tableMenu div:hover,
.sharedListItem .tableMenu div:hover {
  background: #f2f3f4;
}
.questionSummaryHld table .tableAction,
.sharedListItem .tableAction {
  border: none;
  background: none;
  width: 100%;
}
.questionSummaryHld .tagBtn {
  color: #3c3e4d;
  line-height: 18px;
  padding: 6px 16px;
  background: #f2f3f4;
  min-width: fit-content;
  border-radius: 50px;
}
.questionSummaryHld .skillTagsColapsed {
  overflow-x: hidden;
}
.questionSummaryHld .skillTagsExpanded {
  display: flex;
  flex-wrap: wrap;
}
.questionSummaryHld .tagAction {
  background: rgb(245 245 245 / 19%);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(10px);
  color: #acadad;
  position: absolute;
  right: -10px;
  width: 110px;
  top: 0px;
  padding: 6px 20px;
  border-radius: 50px;
}
.emailDetails {
  background: #f6f6f6;
  border: 0.5px solid #d9d9d9;
  border-left: none;
  border-right: none;
}
.questionSummaryHld .collapseBtn {
  padding: 6px 16px;
  margin-left: auto;
}
.questionSummaryHld table tr td:first-child,
.questionSummaryHld table thead th:first-child {
  padding-left: 35px;
  padding-right: 0px;
}
.questionSummaryHld .tagBtnBordered {
  border: 0.5px solid #d9d9d9;
  color: #3c3e4d;
  border-radius: 50px;
  padding: 6px 16px;
  font-weight: 600;
}
.questionSummaryHld .headingText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
}
.questionSummaryHld .btnActive {
  background-color: #f44d5e;
  line-height: 18px;
  border: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 50px;
  padding: 10px 30px;
}
.questionSummaryHld .tableTdText {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #3c3e4d;
}
.questionSummaryHld .userAction {
  position: relative;
}
/* question Summary end */

/* shareWithCandidate start */

.verifyEmailMoadal {
  width: 360px;
}
.shareWithCandidateModal {
  font-family: "Inter";
}
.shareWithCandidateModal .rightContainer {
  border-left: 1px solid #f2f3f4;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.invitedParticiantsList::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.invitedParticiantsList {
  max-height: 562px;
  min-height: 562px;
  overflow-y: scroll;
  border-bottom: 1px solid #f4f4f4;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.shareWithCandidateModal .sharedListItem {
  border-bottom: 1px solid #f2f3f4;
  padding: 12px 16px;
}
.shareWithCandidateModal .modalHeadings,
.verifyEmailMoadal .modalHeadings,
.shareWithCandidateModal .shareListHeader {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}
.shareWithCandidateModal .modal-body,
.verifyEmailMoadal .modal-body {
  padding: 0px 0px;
}
.paragraphBlack {
  color: #000000;
}
.shareWithCandidateModal .shareListHeader {
  background: #f2f3f4;
  padding: 12px 16px;
}
.shareWithCandidateModal .modal-title,
.verifyEmailMoadal .modal-title {
  font-family: "Inter";
  font-weight: 500;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
}
.shareWithCandidateModal .dropzoneArea {
  padding: 30px 30px;
  width: 100%;
  background-color: #f2faff;
  text-align: center;
  border: 0.5px dashed #009ffb;
  border-radius: 4px;
}
.shareWithCandidateModal {
  max-width: 850px;
}
.ModalQuestionPreview .modal-dialog {
  max-width: 700px;
}
.shareWithCandidateModal .modal-content {
  border: none;
}
.shareWithCandidateModal .userEmailInput {
  background: #f2f3f4;
  border: 1px solid #d9d9d9;
  padding: 5px 0px 80px 10px;
  width: 100%;
  border-radius: 4px;
  height: 140px;
  overflow: auto;
}
.shareWithCandidateModal .userEmailInput input {
  background: none;
  border: none;
}
.shareWithCandidateModal .userEmailInput input:focus-visible,
.sectionTd input:focus-visible {
  outline: none;
}
.shareWithCandidateModal .userEmailInput input::placeholder {
  color: #babbbb;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  width: 100%;
}
.shareWithCandidateModal .userEmailInput input {
  color: #000000;
  font-weight: 400;
  font-size: 12px;
  width: 97%;
}
.copyUserdetailsIcon {
  float: right;
}
.inviteParticipant {
  margin: 40% auto;
}
.shareWithCandidateBtn,
.formSubmitButton {
  line-height: 18px;
  background-color: #f44d5e;
  border: 1px solid #f44d5e;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 50px;
  padding: 10px 30px;
}
.shareWithCandidateCancelBtn {
  line-height: 18px;
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  border-radius: 50px;
  padding: 10px 30px;
}
.candidateShareBtn {
  width: 120px;
}
/* shareWithCandidate end */

/*Question Set List Start*/
.QuestionSetListHeader {
  margin: 5px 25px;
}

.SearchQuestionSetInput {
  padding: 2px 15px 5px 15px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
}

.SearchQuestionSetInput > input {
  all: unset;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
}

.SearchQuestionSetInput > input::placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #b1b2b8;
}
.QuestionSetListHeader .secondaryColoredBtn > img {
  width: 15px;
}
.ListOfQuestionSet::-webkit-scrollbar {
  display: none;
}
/*Question Set List End*/

/* Question List Start*/

.QuestionListHeader {
  background: #f2f3f4;
  padding: 10px 28px 10px 20px;
}
.QuestionListHeader .secondaryColoredBtn {
  padding-left: 30px;
  padding-right: 30px;
}

.SearchQuestionListInput {
  padding: 7px 15px 7px 15px;
  border: 1px solid #d9d9d9;
  background: #ffffff;
  border-radius: 50px;
}

.SearchQuestionListInput > input {
  all: unset;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
}

.SearchQuestionListInput > input::placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #b1b2b8;
}

.QuestionListContainer .secondaryColoredBtn > img {
  width: 15px;
  height: 11px;
  margin-bottom: 2px;
}

.QuestionListContainer .QuestionListTable {
  flex: 0 0 auto;
  width: 75.666%;
  border: 1px solid #e2e2ea;
}

.QuestionListCount {
  padding: 8px 28px 8px 20px;
  border-bottom: 1px solid #f2f3f4;
}

.QuestionListSortBy {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: #3c3e4d;
}

.QuestionListTableBody {
  overflow: auto;
  height: calc(100vh - 140px);
  padding-bottom: 40px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.QuestionListTableBody::-webkit-scrollbar {
  /*chrome */
  display: none;
}

.QuestionListTableData {
  padding: 7px 28px 10px 20px;
  border-bottom: 1px solid #e2e2ea;
}

.QuestionListTableData:hover {
  background: #f8f9f9;
}
.QuestionListTitle {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  max-width: 66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.QuestionListTitle > span > ol {
  padding-left: 17px;
}
.QuestionListEasyTypeBtn {
  padding: 4px 8px;
  background: #f1fff6;
  border: 0.5px solid #00bd3f;
  border-radius: 5px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #00b547;
}

.QuestionListAdvancedTypeBtn {
  padding: 4px 8px;
  background: #fee9ed;
  border: 0.5px solid #d6313a;
  border-radius: 5px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #d6313a;
}

.QuestionListIntermediateTypeBtn {
  padding: 4px 8px;
  background: #fff3e1;
  border: 0.5px solid #feb91e;
  border-radius: 5px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #cc860f;
}

.AddQuestionBtn {
  all: unset;
  padding: 0px 7px 2px 7px;
  background: #f44d5e;
  border-radius: 5px;
}
.disabledQestionBtn {
  background: #d9d9d9;
  border: #d9d9d9;
}
.disabledRemoveQestionBtn {
  opacity: 0.4;
}
.QuestionListSkillData {
  padding: 3px 10px;
  background: #f2f3f4;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}
.QuestionListTableData:hover .QuestionListSkillData {
  background: #d8d8d8;
}
.QuestionListFilter {
  background: #f2f3f4;
  border-top: 1px solid #e2e2ea;
  height: calc(100vh - 103px);
  overflow: auto;
  padding: 9px 18px;
  flex: 0 0 auto;
  width: 24.3333%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.QuestionListFilter::-webkit-scrollbar {
  /*chrome */
  display: none;
}
.QuestionListFilterClearBtn {
  all: unset;
  border: 1px solid #92929d;
  border-radius: 50px;
  font-weight: 400;
  padding: 2px 13px;
  font-size: 12px;
  line-height: 18px;
  color: #92929d;
}
.CategoryQuestionListLable {
  font-family: "Inter";
  font-weight: 500;
  padding-right: 15px;
  font-size: 12px;
  line-height: 18px;
  color: #3c3e4d;
}
.rangeSliderText {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  color: #3c3e4d;
}
.CategoryQuestionListTag {
  padding: 3px 9px;
  margin: 5px;
  background: #d6d7d8;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}

.arrowRotateCollapse {
  transform: rotate(180deg);
}
.QuestionListSkillTagCheckbox {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #e2e2e9;
  border-radius: 2px;
  margin: 0;
  margin-right: 16px;
  font: inherit;
  color: currentColor;
  width: 14px;
  height: 14px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.QuestionListSkillTagCheckbox::before {
  content: "";
  width: 0.57em;
  height: 0.57em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  background: #ffff;
}

.QuestionListSkillTagCheckbox:checked {
  background: #3c3e4d;
  border: none;
}
.QuestionListSkillTagCheckbox {
  background: #ffffff;
}

.QuestionListSkillTagCheckbox:checked::before {
  transform: scale(1);
}
.QuestionListSkillTagCheckboxDiv {
  display: flex;
  align-items: center;
  padding-top: 15px;
}
.QuestionListFilterDivider {
  border: 0.5px solid #e2e2ea;
  margin: 20px -18px;
}
.addquestionsfromlist {
  position: absolute;
  bottom: 20px;
  left: 50%;
}
/* Question List End*/

.selectedSections {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
  cursor: pointer;
}
.selectAllBtn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #3c3e4d;
  cursor: pointer;
}
.questionSetTitle {
  color: #000000;
  font-weight: 500;
}
.shareWithCandidateModal p {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  color: #3c3e4d;
}
.shareWithCandidateModal .csvBtn {
  border: none;
  font-family: Roboto;
  color: #fff;
  border-radius: 5px;
  margin: 30px auto 0;
  background-color: #3c3e4d;
  font-size: 12px;
  padding: 8px 35px;
}
.leftarrowbtn {
  background-color: #000000;
  border: 1px solid #000000;
  color: #ffffff;
  border-radius: 50px;
  padding: 6px 22px;
}
.rightarrowbtn {
  background-color: #f44d5e;
  border: 1px solid #f44d5e;
  color: #ffffff;
  border-radius: 50px;
  padding: 6px 22px;
}
.skillsHld {
  max-width: 440px;
  overflow: auto;
}
/* .moreOptionsImg {
  
} */

.toggle-label {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.toggleInput {
  opacity: 0;
  width: 0;
  height: 0;
}

.togglehld {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2c3e50;
  transition: 0.3s;
  border-radius: 30px;
}
.togglehld:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 2.6px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

.toggleInput:checked + .togglehld {
  background-color: #f44d5e;
}

.toggleInput:checked + .togglehld:before {
  transform: translateX(29px);
  right: 31px;
  left: unset
}


