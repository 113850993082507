.upcomingTab {
  width: 150px;
  height: 40px;
  /* background-color: red; */
  cursor: pointer;
}

.completedTab {
  width: 150px;
  height: 40px;
  cursor: pointer;
  /* background-color: blue; */
}

.interviewTotalTab {
  border-bottom: 1px solid #d9d9d9;
}

.interviewTabTotalInterviews {
}

.interviewsBorder {
  border-bottom: 1px solid #d9d9d9;
}
