.my-subscription-header {
  background: #ffffff;
  padding: 10px 0;
  border-bottom: 1px solid #f2f3f4;
  position: relative;
}
.my-subscription-header h5 {
  color: #000;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.subscription-list-page {
  padding: 20px;
  background-color: #f2f3f6;
  height: 100%;
}
.subscription-list-page .user-subscription-hld {
  background: #fff;
  border: 1px solid #f2f3f4;
  padding: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
}
.subscription-list-page .subscription-plan-text {
  color: #666;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.user-subscription-hld .subscription-status {
  color: #666;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  background: #f2f3f6;
  padding: 2px 10px;
  border-radius: 50px;
}
.user-subscription-hld .subscription-active-status {
  background: #e6fbf1;
  color: #05d871;
}
.user-subscription-hld .subscription-details-hld {
  display: inline-flex;
  align-items: center;
  margin-top: 14px;
}
.user-subscription-hld .subscription-plan-name {
  color: #3c3e4d;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
}
.user-subscription-hld .subscription-time-period {
  color: #666;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.subscription-details-hld .subscription-details::after {
  content: "|";
  color: #e5e7eb;
  font-weight: 400;
  padding: 0 10px;
  font-size: initial;
}
.subscription-details-hld .subscription-details:last-child:after {
  content: none;
}
.user-subscription-hld .cancel-btn {
  border-radius: 50px;
  border: 1px solid #e4e4e4;
  background: #fff;
  padding: 4px 10px;
  color: #666;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.user-subscription-hld .downloadInvoice-btn {
  background: rgba(138, 201, 249, 0.12);
  border-radius: 50px;
  color: #4ca4e7;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  border: transparent;
  padding: 6px 16px;
}
.user-subscription-hld .upgrade-btn {
  border: transparent;
  background: #ffbb6c;
  border-radius: 50px;
  padding: 6px 16px;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
}
.user-subscription-hld .company-logo {
  border-radius: 5px;
  border: 1px solid #e4e4e5;
  width: 40px;
  height: 40px;
}
.invoices-cntr .filter-btn {
  border-radius: 50px;
  background: #f2f3f4;
  box-shadow: 0px 10px 10px 0px rgba(8, 35, 48, 0.02);
  border: none;
  padding: 4px 18px;
  color: #92929d;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.invoices-cntr .active-filter-btn {
  background-color: #ff6f73;
  color: #ffffff;
}
.invoices-cntr .filter-btn-right {
  float: right;
  position: relative;
  top: -23px;
}
.invoices-cntr .filter-dropdown {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 20px 10px rgba(0, 0, 0, 0.05);
  position: absolute;
  right: 20px;
  top: 46px;
  padding: 20px;
  width: 231px;
  z-index: 99;
}

.invoices-cntr .filter-dropdown .filter-head {
  color: #92929d;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}

.invoices-cntr .filter-dropdown .newAssessmentFilterRadio {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  color: #242533;
}
.invoices-cntr .filter-dropdown .newAssessmentFilterRadio:hover {
  border-radius: 6px;
  background: rgba(217, 217, 217, 0.3);
}
