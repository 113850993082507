.headerTitle .logoTitle {
  height: 40px;
}
.headerTitle .title {
  font-size: 30px;
  color: #f44d5e;
  font-weight: 800;
}
.headerTitle p {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  line-height: 18px;
}
.headerLogo img {
  height: 160px;
}
.Search {
  border: 1px solid #dadce0;
  border-radius: 50px;
  margin-left: 38px;
}
.Search img {
  height: 22px;
}
.Search input {
  width: 95%;
  border: none;
  margin-left: 10px;
  font-size: 14px;
}
.Filter {
  height: fit-content;
  background-color: #000000;
  padding: 5px 0;
  border-radius: 50px;
}
.Filter button {
  margin-left: 5px;
  border: none;
  border-radius: 50px;
  font-size: 12px;
  color: #ffffff;
}
.Filter p {
  font-size: 12px;
  color: #ffffff;
}
.Filter img {
  margin-left: 4px;
  height: 16px;
}
.searchResult h3 {
  font-size: 22px !important;
  margin-left: 10px;
  color: 000000 !important;
  font-weight: 300 !important;
}
.highlight {
  background-color: yellow;
}
.skillAssessment h3 {
  margin-left: 10px;
  font-weight: 600 !important;
  font-size: 22px !important;
  color: 000000 !important;
}
.skillAssessment p {
  margin-left: 10px;
  font-weight: 400;
  font-size: 14px;
  color: 000000;
}
.skillBadge {
  height: 80px;
}
.divSkill {
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.divSkill h4 {
  font-size: 16px !important;
  font-weight: bold !important;
}
.divSkill p {
  color: #666666;
  font-size: 12px;
  margin-left: 0px;
}
.divSkill h6 {
  font-size: 12px;
  color: #000000;
}
.divSkill h6 img {
  height: 20px;
  margin-right: 5px;
}
.divSkill h5 {
  background-color: #e7e7e7;
  padding: 5px 15px;
  font-size: 12px !important;
  color: #000000;
}
.divSkill button {
  background-color: white;
  padding: 5px 15px;
  font-size: 12px;
  color: #000000;
  border: 2px solid black;
  border-radius: 50px;
}
.divSkill button img {
  height: 12px;
  margin-left: 5px;
}
.divSkill :hover {
  box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: solid 1px #dadce0;
}
.divSkill :hover button {
  border: 2px solid #f04f5f;
  background-color: #f04f5f;
  color: white;
  border: none;
  border-radius: 50px !important;
}
.clearCSS :hover {
  box-shadow: none !important;
  border-radius: 0px !important;
  border: none !important;
}
.moreBtn button {
  background-color: white;
  padding: 3px 35px;
  border: solid 1px #c4c4c4;
  color: #303030;
  font-weight: 600;
  border-radius: 50px;
}
.moreBtn button img {
  margin-left: 5px;
}
.interviewPrep h3 {
  margin-left: 10px;
  font-weight: 600 !important;
  font-size: 22px !important;
  color: 000000 !important;
}
.divInterview :hover button {
  border: 2px solid #f04f5f;
  background-color: #f04f5f;
  color: white;
  border: none;
  border-radius: 50px !important;
}
.divInterview :hover {
  box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: solid 1px #dadce0;
}
.divInterview button img {
  height: 12px;
  margin-left: 5px;
}
.divInterview {
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.divInterview h4 {
  font-size: 14px !important;
  font-weight: bold !important;
}
.divInterview p {
  width: max-content;
  background-color: #e7e7e7;
  padding: 1px 18px;
  font-size: 12px;
}
.beginner {
  color: #5cb85c !important;
  background-color: #eff5ef !important;
}
.intermediate {
  color: #f3850c !important;
  background-color: #fef6ec !important;
}
.advance {
  color: #d2463f !important;
  background-color: #fbecec !important;
}
.divInterview h6 {
  margin: auto;
  font-size: 12px;
  color: #000000;
}
.divInterview h6 img {
  height: 20px;
  margin-right: 5px;
}
.divInterview h5 {
  margin: auto;
  background-color: #e7e7e7;
  padding: 5px 15px;
  font-size: 12px !important;
  color: #000000;
}
.divInterview button {
  background-color: white;
  padding: 5px 15px;
  font-size: 12px;
  color: #000000;
  border: 2px solid black;
  border-radius: 50px;
}
.examsPrep h3 {
  margin-left: 10px;
  font-weight: 600 !important;
  font-size: 22px !important;
  color: 000000 !important;
}
.divExam button img {
  height: 12px;
  margin-left: 5px;
}
.divExam {
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.divExam h4 {
  font-size: 14px !important;
  font-weight: bold !important;
}
.divExam p {
  width: max-content;
  background-color: #e7e7e7;
  padding: 1px 18px;
  font-size: 12px;
}
.divExam h6 {
  margin: auto;
  font-size: 12px;
  color: #000000;
}
.divExam h6 img {
  height: 20px;
  margin-right: 5px;
}
.divExam h5 {
  margin: auto;
  max-width: max-content;
  background-color: #e7e7e7;
  padding: 5px 15px;
  font-size: 12px !important;
  color: #000000;
}
.divExam button {
  background-color: white;
  padding: 5px 15px;
  font-size: 12px;
  color: #000000;
  border: 2px solid black;
  border-radius: 50px;
}
.divExam h2 {
  margin: auto;
  font-size: 12px;
  color: #000000;
}
.divExam h2 img {
  height: 20px;
  margin-right: 5px;
}
.divExam :hover {
  box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: solid 1px #dadce0;
  border-top: none !important;
  border-bottom: none !important;
}
.mockContainer {
  background: #000000;
  height: calc(100vh - 147px);
}
.mockSubContainer {
  height: calc(100vh - 199px);
  overflow: hidden;
  padding-bottom: 40px;
}
.questionSectionAssess {
  height: calc(90vh - 199px);
  overflow-y: auto;
}
.divExam :hover button {
  border: 2px solid #f04f5f;
  background-color: #f04f5f;
  color: white;
  border: none;
  border-radius: 50px !important;
}
.myMocks h3 {
  margin-left: 10px;
  font-weight: 600 !important;
  font-size: 22px !important;
  color: 000000 !important;
}
.skillBadgeShow {
  margin: auto;
  height: 60px;
}
.activeMocks img {
  margin: 3px 20px;
  height: 20px;
}
.finishedMocks h6 {
  max-width: max-content;
  font-size: 10px;
  padding: 6px 21px;
  background-color: #c4c4c4;
  opacity: 0.8;
  border-radius: 0 50px 50px 0;
  color: #000000;
  text-transform: none;
}
.activeMocks {
  border-radius: 5px 5px 0 0;
  border: 1px solid #d6d6d6;
}
.finishedMocks h5 {
  font-size: 16px;
  padding: 6px 21px;
  color: #000000;
}
.activeMocks p b {
  color: #3a3a3a;
}
.activeMocks p {
  margin-right: 30px;
  padding: 3px 0px;
}
.resumeMocks {
  background-color: #000000;
  color: #ffffff !important;
  height: 30px;
  border-radius: 50px 0 0 50px;
}
.progBarMocks {
  height: 2px;
  background-color: #5cb85c;
  margin-bottom: 0px;
}
.slick-slide {
  padding: 0 10px;
}
.Filter .dropdown-menu-right {
  top: 10px !important;
  left: 25px !important;
  box-shadow: 10px 10px 20px 15px rgba(0, 0, 0, 0.05);
}
.Sorting {
  height: fit-content;
  background-color: white;
  padding: 5px 0;
  border: none;
  color: #000000;
  font-weight: 400;
  font-size: 15px;
}
.Sorting img {
  height: 35px;
}
.Sorting .dropdown-menu-right {
  box-shadow: 10px 10px 20px 15px rgba(0, 0, 0, 0.05);
  font-size: 15px;
}
.resultFailed {
  border-radius: 5px;
  margin: auto;
  background-color: white;
}
.resultPassed {
  margin-top: 114px;
  background-color: #13151a;
  color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}
.resultPassedHeader {
  background: #292c37;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding: 16px;
  margin-bottom: 15px;
  z-index: 5;
}
.blurBackground {
  width: 70px;
  height: 40px;
  transform: translateX(60px);
  z-index: 0;
}
.blurRed {
  box-shadow: 0px 0px 70px 18px rgba(244, 77, 94, 1);
}
.blurGreen {
  box-shadow: 0px 0px 70px 18px rgba(5, 216, 113, 1);
}
.scoreInPercentBg {
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  width: 110px;
  height: 113px;
  z-index: 1;
  transform: translateX(-35px);
}
.scoreInPercentMain {
  position: absolute;
  top: 9.3px;
  left: 8.45px;
  width: 96px;
  height: 94px;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
}
.bgRed {
  background: url("../../../../assets/Icons/Interview/New/redBack.svg");
}
.bgGreen {
  background: url("../../../../assets/Icons/Interview/New/greenBack.svg");
}
.mainRed {
  background: url("../../../../assets/Icons/Interview/New/redHld.svg");
  color: #f44d5e;
}
.mainGreen {
  background: url("../../../../assets/Icons/Interview/New/greenHld.svg");
  color: #05d871;
}
.failedBottom {
  border-radius: 0px 0px 5px 5px !important;
}
.failedTitled {
  border-radius: 5px 5px 0px 0px !important;
  border-bottom: 1px solid #e8e8e8;
}
.resultPassed h4 {
  font-size: 18px;
  font-weight: 600;
}
.resultPassed h2 {
  font-size: 24px;
  color: #5cb85c;
  font-weight: 900;
}
.resultPassed h6 {
  color: #000000;
  font-size: 12px;
  text-transform: none;
}
.resultPassed .scores {
  border-right: 2px solid #000;
  background-color: #fff;
}
.resultPassed p {
  margin: 0px;
  padding: 0px;
  color: #000;
  font-size: 14px;
  text-transform: none;
}
.resultPassed .scores h1 {
  color: #000;
  font-size: 30px;
}
.resultPassed img {
  height: 180px;
}
.Share-Black h6 {
  text-transform: none;
  color: black;
}
.Share-Black img {
  margin: 0 5px;
  height: 30px;
}
.mocksTitle {
  background-color: #f6f6f6;
  border: 1px solid #d6d6d6;
  border-right: none;
  border-left: none;
}
.rightBtns {
  background-color: #f6f6f6;
  border: 1px solid #d6d6d6;
  border-left: none;
}
.questionList {
  background-color: #f6f6f6;
  border: 1px solid #d6d6d6;
  border-right: none;
}
.questionList img {
  height: 12px;
}
.questionList h6 {
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  margin-top: 8px;
}
.mocksTitle h5 {
  margin-top: 13px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.outofMocks {
  background-color: #f6f6f6;
  border-radius: 5px;
}
.outofMocks p {
  color: #000000;
}
.outofMocks .progMocks {
  height: 5px;
  background-color: #c4c4c4;
  border-radius: 50px;
}
.progMocks .progMocks-inside {
  height: 5px;
  background-color: #e9505c;
  border-radius: 50px;
}
.pauseMocks {
  cursor: pointer;
  background-color: #000000;
  border-radius: 5px;
}
.pauseMocks p {
  padding: 8px 0;
  color: #ffffff;
}
.timeMocks {
  background-color: #f6f6f6;
  border-radius: 5px;
}
.timeMocks h3 {
  font-size: 26px !important;
}
.timeMocks p {
  color: #000000;
}
.exitMocks {
  cursor: pointer;
  background-color: #e9505c;
  border-radius: 5px;
}
.exitMocks p {
  padding: 8.5px 0;
  color: white;
}
.questionset-conatiner {
  border-radius: 5px;
  max-height: 550px;

  overflow-x: hidden;
  background-color: #13151b;
  padding: 9px 35px;
}
.questionset-conatiner p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}
.questionsList {
  border-bottom: 1px solid #d6d6d6;
}
.questionset-conatiner::-webkit-scrollbar {
  display: none;
}
.questionArea {
  background-color: white;
  border-radius: 5px;
}
.questionArea .Questions h6 {
  font-size: 14px;
  text-transform: none;
}
.questionArea .Questions {
  border-bottom: 1px solid #e8e8e8;
}
.questionArea .Answers input {
  margin-top: 15px;
  margin-left: 20px;
}
.Answers label {
  margin-left: 20px;
  font-size: 14px;
  color: #000000;
  font-weight: 500;
}
.submitBtn button {
  border: none;
  border-radius: 5px;
  background-color: #e9505c;
  color: white;
  padding: 8px 25px;
}
.submitBtn img {
  margin-left: 10px;
  height: 13px;
  width: 22px;
  margin-top: -2px;
}
.resultFailed h4 {
  font-size: 18px !important;
  font-weight: 600 !important;
}
.resultFailed h2 {
  font-size: 24px;
  color: #e9505c;
  font-weight: 900;
}
.resultFailed h6 {
  color: #000000;
  font-size: 12px;
  text-transform: none;
}
.resultFailed .scores {
  border-right: 2px solid #ffffff;
  background-color: #000;
}
.resultFailed p {
  margin: 0px;
  padding: 0px;
  color: #ffffff;
  font-size: 14px;
  text-transform: none;
}
.resultFailed .scores h1 {
  color: #ffffff;
  font-size: 30px;
}
.resultFailed img {
  height: 80px;
}
.questionBlock {
  width: 31px;
  height: 31px;
  background: #292c37;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.questionBlock-review {
  border-radius: 5px;
  margin: 10px;
  max-height: 35px;
  aspect-ratio: 1;
  background-color: #fbecec !important;
  cursor: pointer;
}
.blockQuestionList {
  overflow-y: scroll;
  height: 100px;
}
.blockQuestionList::-webkit-scrollbar {
  display: none;
}
.submitBtnLeft {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #e9505c;
  color: white;
  margin: 10px 10px;
}
.submitBtnLeft img {
  margin-left: 10px;
  height: 13px;
  margin-top: -2px;
}
.submitBtnLeft button {
  border: none;
  border-radius: 5px;
  background-color: #e9505c;
  color: white;
  padding: 8px 25px;
}
.submitBtn {
  border: none;
  background: #e9505c;
  border-radius: 50px;
  padding: 8px 47px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  cursor: pointer;
}
.prevBtn {
  border: none;
  border-radius: 50px !important;
  background-color: #343741;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  padding: 8px 47px;

  margin-right: 35px;
}
.prevBtn img {
  margin-right: 10px;
  height: 13px;
  width: 22px;
  margin-top: -2px;
  transform: rotate(180deg);
}
.greyBtn {
  border: none;
  border-radius: 5px !important;
  background-color: #dfdfdf;
  color: #000000 !important;
  font-weight: 700 !important;
  padding: 8px 25px;
}
.totalScore {
  padding: 10px 0;
  margin: 0 170px !important;
  color: #f3850c !important;
  border-radius: 50px;
  background-color: #fef6ec;
}
.modal {
  z-index: 1032;
}
.modal-backdrop {
  position: fixed;
  z-index: 1031;
  background-color: black !important;
  opacity: 0.5 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.modal-content {
  position: relative;
}
.codeBlockMain {
  background-color: #3c3e4d;
  padding: 5px 10px;
  border-radius: 10px;
}
.codeBlockAnswer {
  height: 200px;
  border: none;
  background-color: #494d59;
}
.codeComplier {
  margin-right: 30px !important;
  padding: 3px 35px;
  color: #ffffff;
  background-color: #e9505c;
  border: 1px solid #e9505c;
  border-radius: 5px !important;
}
.codeBlock {
  overflow: auto;
  height: 38vh;
}
.codeBlock p {
  color: #000000;
  font-weight: 600;
}
.submitSection {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #000000;
}
.reviewBtn {
  border: none;
  border-radius: 5px !important;
  background-color: #276cc7;
  color: white;
  padding: 8px 25px;
}
.mock-radio input[type="radio"]:checked::before {
  background: radial-gradient(#fff 40%, transparent 50%);
}

.interviewerQuestionStatushead .questionsleft {
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}
.interviewerQuestionStatushead .questionsoutof {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #b7b7ba;
  margin-right: 8px;
}
.interviewerQuestionStatushead .questionsCurrentStatus {
  background: #292c37;
  border-radius: 5px;
  color: #b7b7ba;
  font-size: 12px;
  line-height: 15px;
  padding: 6.5px 15px;
  margin: 0px 6px;
}
.interviewerQuestionStatushead .questionsCurrentStatus span {
  font-weight: 600;
  margin-left: 20px;
}
.interviewerQuestionStatushead .question_status {
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
}
.blockQuestionList .questionNumberStatus {
  position: absolute;
  bottom: -12px;
  left: 5.5px;
  width: 20px;
  height: 20px;
}
.interviewerRadiobutton {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  /* border: 1px solid #5cb85c; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.interviewerRadiobutton div {
  width: 15px;
  height: 15px;
  /* background: #5cb85c; */
  border-radius: 50px;
}
.userSelectionCntrCorrect {
  border: 1px solid #05d871;
  box-sizing: border-box;
  border-radius: 4px;
}
.userSelectionCntrWrong {
  border: 1px solid #e9505c;
  box-sizing: border-box;
  border-radius: 4px;
}

.candidateSelectionTxt {
  position: absolute;
  right: 0;
  top: -31px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 15px;
  padding: 7px 13px 7px 17px;
  border-radius: 50px 10px 0px 0px;
  background: #292c37;
}
.scorehld {
  margin: 0px 130px;
}
