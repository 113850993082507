h1 {
  margin-bottom: 0;
}

.mocks {
  background-color: #ffffff;
  border-radius: 20px 20px 20px 20px;
  /* max-width: 1400px; */
  margin: 0 auto;
}

.mocksNavbarContainer {
  background-color: #3c3e4d;
  border-radius: 20px 0 0 20px;
  padding: 0;
  height: calc(100vh - 150px);
}

.mocksHeadingContainer {
  position: relative;
  padding: 20px;
  margin: 0;
  border: solid 1px #e5e5e5;
  border-radius: 20px 20px 0px 0px;
}

.mocksHeadingContainer h2 {
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #212529;
}

.mocksCards {
  height: calc(100vh - 137px);
  padding: 0;
  background-color: #fff;
}
.mockSubHeader {
  display: flex;
  justify-content: space-between;
  background-color: #484a58;
  align-items: center;
  padding: 20px;
}

.mockSubHeader h2 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.mockSubHeader button {
  padding: 8px 25px;
  border-radius: 5px;
  background-color: #f44d5e;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  border: none;
}
.mockSubHeader button.disabled{
  background-color: #f3f5f7;
  color: #212529;
}
.mocksDetails {
  height: calc(100vh - 145px);
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border-left: 1px solid #d8d8d8;
  padding: 0;
  background-color: #fff;
}
.mocksDetails::-webkit-scrollbar {
  display: none;
}

.mockDetailsHeader {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  background-color: #f3f5f7;
  border-right: solid 1px #d8d8d8;
  font-family: Roboto;
  font-size: 14px;
  color: #3c3e4d;
}

.mockDuration,
.mockSCore {
  display: flex;
  align-items: center;
}

.mockDuration {
  padding: 0 20px;
  border-right: solid 1px black;
}
.mockSCore {
  padding-left: 20px;
}

.mockSCore p {
  font-weight: 500;
  margin-left: 10px;
}

.mockQuestionList {
  padding: 20px 20px;
}

.mockDuration img {
  height: 18px;
  margin: 0 10px;
}

.noAssessments {
  padding: 150px 90px 50px;
}

.noAssessments .noAssessmentsIcon {
  height: 200px;
}

.noAssessments h5 {
  margin: 40px 0;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  color: #3c3e4d;
}

.noAssessments p {
  margin: 30px 0 60px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.89;
  color: #b0b0b4;
}

.noAssessments button {
  padding: 8px 25px;
  border-radius: 5px;
  background-color: #f44d5e;
  border: none;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}

.noAssessments button img {
  margin-right: 15px;
  height: 20px;
}
.breadcrumbs {
  position: absolute;
  font-size: 14px;
  color: #c4c4c4;
}
.mockssCardContainer {
  height: calc(100vh - 184px);
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
@media only screen and (min-width: 2560px) {
  .mocksHeadingContainer h2 {
    font-size: 28px;
  }
}
