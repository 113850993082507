:root {
  --color-black: #000000;
  --color-light: #666666;
}

.UserPlanWelcomeScreenModal {
  max-width: 700px;
  font-family: "Inter";
}

.UserPlanWelcomeScreenModal .modal-content {
  border-radius: 20px !important;
  border: none;
}

.userPlanScreenModalBody {
  padding: 30px;
}

.UserPlanWelcomeScreenModal .userName,
.planModal .modalHeading {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: var(--color-black);
}

.UserPlanWelcomeScreenModal .popper-img {
  vertical-align: baseline;
  margin-left: 6px;
}

.UserPlanWelcomeScreenModal .UserPlanWelcomeScreenText {
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: var(--color-light);
  margin-bottom: 40px;
}

.UserPlanWelcomeScreenModal .howToStart {
  background: linear-gradient(
    269.74deg,
    #ed952d 32.93%,
    #ffb636 77.29%,
    #ed952d 139.84%
  );
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: #ffffff;
  padding: 10px;
  margin: 20px 0 10px;
}

.benefitsHld {
  background: rgba(255, 159, 75, 0.1);
  margin: 40px 0 20px;
  border-radius: 5px;
}

.benefitsHld ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
}

.benefitsHld ul li {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #363835;
  margin-bottom: 20px;
  padding-left: 20px;
}

.benefitsHld ul li .tick-icon {
  margin-right: 6px;
}

.PlansBtn {
  border: none;
  border-radius: 50px;
  padding: unset;
  background: transparent;
  margin: 20px 0 0px;
  margin-right: 12px;
}

.planName {
  border: 0.5px solid #a5a6aa;
  display: block;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 8px;
  color: var(--color-black);
  transition: transform 0.1s ease;
  font-weight: 600;
  font-size: 14px;
  background: #ffffff;
  width: 190px;
}

.arrow-right {
  display: none;
}

.planName .arrow-right {
  padding-left: 6px;
}

.PlansBtn:hover .planName {
  transform: translateY(-0.33em);
}

.PlansBtn:hover .arrow-right {
  display: inline;
}

.PlansBtn:hover {
  background: #000000;
}

.PlansBtn:active .planName {
  transform: translateY(0);
}

.benefitsHld .benefitsHead {
  background: #000000;
  display: inline-block;
  border-radius: 0 0 50px;
  padding: 8px 30px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 20px;
}

.closeModalHld {
  position: absolute;
  right: 21px;
  z-index: 999999;
  top: 18px;
  cursor: pointer;
}

.closeModalHld .close-icon {
  height: 30px;
  color: #969ba0;
}

/* free plan modal */
.modal-dialog.freePlanPopup {
  max-width: 1093px;
}

.modal-dialog.basicPlanModal {
  max-width: 875px;
}

.modal-dialog.professionalPlanModal {
  max-width: 330px;
}
.planModal .modal-content,
.user-current-plan .modal-content {
  border-radius: 20px !important;
  border: none;
}
.planModal .faq-accordion {
  height: 470px;
  --bs-accordion-border-width: none;
  overflow-y: auto;
}
.planModal .faq-accordion .accordion-item {
  border-bottom: 1px solid #d9d9d9;
}

.planModal .modalBtn {
  border: none;
  padding: 8px 30px;
  border-radius: 50px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #363835;
  width: 155px;
}

.planModal .currentPlan {
  background: rgba(192, 191, 191, 0.2);
}

.planModal .basicPlan {
  background: #000;
  color: #fff;
}

.planModal .professionalPlan {
  background: #ffbb6c;
}

.planModal .expertisePlan {
  background: #f44d5e;
  color: #fff;
}

.planModal .planModalText,
.plansHld .planModalText {
  color: #363835;
  font-size: 12px;
  font-weight: 500;
  line-height: 130%;
}
.planModal .planModalText ul,
.plansHld .planModalText ul {
  list-style-type: none;
  padding-left: 0;
}
.planModal .planModalText ul li,
.plansHld .planModalText ul li {
  display: flex;
  line-height: 16px;
  margin-bottom: 15px;
}
.planModal .planModalText ul li::before,
.plansHld .planModalText ul li::before {
  content: "\2713";
  font-family: system-ui;
  font-weight: 600;
  display: block;
  margin-right: 16px;
  font-size: medium;
}
.planModal .planModalText ul li:first-child,
.plansHld .planModalText ul li:first-child {
  font-weight: 700;
}

.planModal .subheadingText,
.plansHld .subheadingText {
  color: #242424;
  font-size: 17px;
  font-weight: 500;
  line-height: 130%;
}
.premium-btn .subheadingText {
  font-size: 14px;
}
.premium-btn .pricingText {
  font-size: 20px !important;
}
.planModal .pricingText,
.plansHld .pricingText {
  color: #242424;
  font-size: 28px;
  font-weight: 700;
  line-height: 130%;
}
.planList {
  margin-bottom: 14px;
}
.planModal .close-icon-hld {
  position: absolute;
  top: 0%;
  right: 0px;
  padding: 0;
  width: fit-content;
}
.planModal .close-icon {
  height: 24px;
  width: 30px;
  color: #969ba0;
}
.planList svg {
  height: 16px;
  width: 16px;
}
.planModal .tabText {
  color: #000;
  font-size: 10px;
  font-weight: 600;
  line-height: 130%;
  padding: 2px 4px;
  border-radius: 50px;
}
.user-plan-popup {
  width: 395px;
}
.user-plan-popup h1 {
  color: #242424;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
}
.user-plan-popup ul {
  padding-left: 0;
  list-style-type: none;
}
.user-plan-popup ul li p {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}
.user-plan-popup .upgrade-now {
  background: #ffbb6c;
  border-radius: 50px;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  border: none;
  margin: 0 auto;
  padding: 8px 12px;
  display: block;
  width: 100%;
}
.subscription-period-options {
  background: rgba(217, 217, 217, 0.4);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 207px;
  margin: 0 auto;
  padding: 7px 2px;
  cursor: pointer;
}
.subscription-period-options .subscription-period-text {
  color: #242424;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding: 6px 10px;
  line-height: normal;
}
.subscription-period-options .subscription-period-text-active-hld {
  border-radius: 4px;
  background: #fff;
}
.freePlanScreenModalBody.modal-header {
  border: none;
}
.freePlanScreenModalBody.modal-header .btn-close {
  padding-top: 30px;
}
.add-subscription-popup .modal-header {
  border-bottom: none;
}
.disabledprimarybtn {
  background: #747a8a;
  border: #747a8a;
}
.selected-premium-hld {
  background: rgba(255, 159, 75, 0.1);
  border-radius: 10px;
  padding: 4px 10px;
  margin-top: 16px;
}
.add-subscription-popup .selected-plan-name {
  color: #242424;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.add-subscription-popup .selected-plan-time-period {
  color: #666;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}
.frequently-asked-questions-btn {
  background: #f1f9fe;
  border: #f1f9fe;
  border-radius: 50px 50px 0 0;
  padding: 6px 38px;
  color: #4ca4e7;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.planModal .goBackfromfaq {
  background: #f44d5e;
  color: #ffffff;
}
.planModal .accordion-button {
  color: #363835;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  padding: 20px 0;
}
.planModal .accordion-body {
  color: #363835;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
}
.planModal .accordion-button:not(.collapsed)::after,
.planModal .accordion-button::after {
  background-image: none;
}
.planModal .accordion-button::after {
  content: "\2795";
}
.planModal .accordion-button:not(.collapsed)::after {
  content: "\2012";
  font-size: 34px;
  line-height: 20px;
  padding-left: 5px;
}
.planModal .accordion-button:not(.collapsed) {
  background-color: transparent;
}
.planModal .accordion-button:focus {
  box-shadow: none;
}
.planModal .left-arrow {
  width: 25px;
}
.close-button-hld .btn-close {
  position: absolute;
  right: 20px;
  top: 4px;
  z-index: 9;
}
.planModal .btn-close:focus {
  box-shadow: none;
}
.subscription-period-options .annual-benefits {
  background: #ffeedb;
  color: #d57d16;
  border-radius: 2px;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  padding: 2px 4px;
}
.subscription-period-options
  .subscription-period-text-active-hld
  .annual-benefits {
  background: rgba(82, 180, 77, 0.12);
  color: #2bc155;
}
