.apiTableRowFirst .apiTableCol {
  text-align: center;
}
.apiTableRowFirst {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #c1bfbf;
}

.apiTableRowSecond .apiTableCol {
  text-align: center;
}

.apiTableRowSecond {
  background: #faf9f9;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  align-items: center;
}
.apiTableRowSecond .apiString{
  word-break: break-all;
}
.btn-genrateApi {
  border: none;
  background-color: #f44d5e;
  color: #fff;
  border-radius: 5px;
  padding: 10px 30px;
}
.btn-genrateApi:hover {
  background-color: #e03c4d;
}
