.hackathonCalendar .react-calendar-heatmap .color-scale-1 {
  fill: #91e7ab;
  stroke-width: 5;
  ry: 2;
}
.hackathonCalendar .react-calendar-heatmap .color-scale-2 {
  fill: #09c269;
  stroke-width: 5;
  ry: 2;
}
.hackathonCalendar .react-calendar-heatmap .color-scale-3 {
  fill: #009f53;
  stroke-width: 5;
  ry: 2;
}
.hackathonCalendar .react-calendar-heatmap .color-scale-4 {
  fill: #046d3c;
  stroke-width: 5;
  ry: 2;
}

.hackathonCalendar .react-calendar-heatmap .color-empty {
  fill: #f2f3f4;
  stroke: #e2e2e9;
  stroke-width: 1;
  ry: 2;
}
.hackathonCalendar {
  background: #ffffff;
  border: 1px solid #e2e2e9;
  border-right: 0.5px solid #e2e2e9;
  border-left: 0.5px solid #e2e2e9;
  padding: 35px 17px;
}

.hackathonCalendar .react-calendar-heatmap-weekday-label {
  x: -10px !important;
}

.hackathonCalendar .react-calendar-heatmap-month-label,
.hackathonCalendar .react-calendar-heatmap-weekday-label {
  font-weight: 500;
  font-size: 10px;
  fill: #3c3e4d;
}
