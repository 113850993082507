/* .userAllDetails{
  
} */

.userWorkExperience .title,
.userEducation .title {
  border-bottom: 1px solid #a5a6aa;
  position: relative;
}

.userWorkExperience .title h4,
.userEducation .title h4 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0;
  color: #3c3e4d;
}

.userWorkExperience .title::after,
.userEducation .title::after {
  position: absolute;
  border-bottom: 3px solid #3c3e4d;
  content: "";
  width: 135px;
}

.jobInfo ul,
.educationInfo ul {
  padding: 0 20px;
  color: #a5a6aa;
}

/* .jobInfo ul li, .educationInfo ul li{
    color:#a5a6aa;
} */

.jobInfo h6,
.educationInfo h6 {
  margin: 10px 0;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  color: #3c3e4d;
}

.jobInfo p,
.educationInfo p {
  margin: 10px 0;
  font-family: Roboto;
  font-size: 15px;
  color: #979797;
}

.userResume {
  padding-bottom: 20px;
  overflow: auto;
  height: calc(100vh - 392px);
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.userResume::-webkit-scrollbar {
  display: none;
}

.userResume canvas {
  width: 100% !important;
  height: 100% !important;
}

@media only screen and (min-width: 2560px) {

  .userWorkExperience .title h4,
  .userEducation .title h4 {
    font-size: 24px;
  }

  .userInfo p {
    font-size: 14px;
  }

  .userInfo span {
    font-size: 14px;
  }
}