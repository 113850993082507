.interviewAssess {
  background: #f4f4f4;
}
.mainInterviewInvert {
  height: calc(100vh - 220px);
  overflow-y: auto;
}
.interviewAssess .questionSetBody {
  background: #ffffff;
}
.interviewAssess .interviewAssessSubHeader {
  padding: 10px 19px;
  border-bottom: 1px solid #e2e2ea;
}
.interviewAssess .interviewAssessSubHeader h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #3c3e4d;
}
.innerAssessType {
  top: 10px;
  left: 15px;
}
.interviewAssess .interviewAssessSubHeader button {
  border-radius: 50px;
  background: linear-gradient(286.29deg, #f44d5e 2%, #fc7269 94.83%);
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 7.5px 32px;
  border: none;
}
.interviewAssess .containerAssess {
  position: sticky;
  top: 0px;
  padding-top: 8px;
  background: #ffffff;
}
.interviewAssess .assessCardContainer {
  height: calc(100vh - 215px);
  overflow-y: auto;
}
.interviewAssess .assessDetailsHeader {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  background: #ececec;
  box-shadow: 0px 4px 120px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin: 8px 12px;
}
.interviewAssess .assessQuestionsCount {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #3c3e4d;
}
.interviewAssess .assessDuration {
  display: flex;
  align-items: center;
  padding: 0 20px;
  /* border-right: solid 1px black; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #3c3e4d;
}
.interviewAssess .assessDuration img {
  width: 14px;
  height: 14px;
}
.interviewAssess .assessScore {
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #3c3e4d;
}
.interviewAssess .questionSkills {
  margin-left: 31px;
}
.interviewAssess .questionSkills .tagsSkills,
.interviewAssess .questionSkills .skillsTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #3c3e4d;
}
.interviewAssess .questionSkills .tagsSkills {
  background: #f2f3f4;
  border-radius: 4px;
  margin: 0px 8px;
  padding: 7px 10px;
}

/* mocks  CSS */
.activeQuestionSet {
  background: #fffcf5;
}
.activeQuestionSetBar {
  position: absolute;
  top: 18px;
  left: 0px;
  height: 39px;
  width: 5px;
  background: #feb91e;
  border-radius: 0px 10px 10px 0px;
}
.questionSetBox {
  padding: 25px 5px 25px 25px;
}
.questionSetTitle h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3c3e4d;
}
.assessQuestion {
  font-family: "Inter";
  font-style: normal;
  border: 1px solid #e2e2ea;
  border-radius: 5px;
  padding: 8px 25px;
  margin-bottom: 15px;
}
.questionHeading {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #3c3e4d;
  padding: 15px 0px;
  word-break: break-all;
}
.assessAnswerList {
  font-size: 14px;
  line-height: 15px;
  color: #666666;
}
.assessAnswerList p {
  margin: 19px 0px;
}
