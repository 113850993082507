.hackathonUserInterviewDetails {
  background: #f2f3f4;
  padding: 18px;
}

.hackathonUserInterviewDetails > div > div {
  display: inline-grid;
  font-weight: 400;
  font-size: 12px;
  color: #747a8a;
}
.hackathonUserInterviewDetails > div > div > div {
  font-weight: 500;
  color: #3c3e4d;
}
.hackathonDownloadCode {
  border:none;
  background-color: black;
  height: fit-content;
}
