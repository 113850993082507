.confirmationModal .modal-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.confirmationModal .bodytext {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}
.confirmationModal .cancelbtn {
  padding: 8px 30px;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #acadad;
  border-radius: 50px;
  background: #ffffff;
}
.confirmationModal .actionbtn {
  background: #f44d5e;
  border-radius: 50px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #f44d5e;
  padding: 8px 30px;
}
.confirmationModal .btn-close:focus {
    box-shadow: none
}
