body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* General CSS */

.Header-heading {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
.cardHeading {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}
.formLabel {
  font-size: 14px;
  color: #333;
  font-family: Inter;
  font-weight: 500;
}
.formInput {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.formInput::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #b1b2b8;
}
.sidebarActiveText {
  font-size: 12px;
  font-weight: 600;
  color: #f44d5e;
}
.sidebarInactiveText {
  font-size: 12px;
  font-weight: 500;
  color: #666666;
}
.paragraph p,
.paragraph {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}
/* CTA Button style */
.primaryBtnActive {
  background-color: #f44d5e;
  border: 1px solid #f44d5e;
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  border-radius: 50px;
  padding: 10px 30px;
}
.primaryBtnInactive {
  background-color: #e4e4ea;
  border: 1px solid #e4e4ea;
  color: #3c3e4d;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  padding: 10px 30px;
}
.secondaryColoredBtn {
  background-color: #f44d5e;
  border: 1px solid #f44d5e;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  border-radius: 50px;
  padding: 6px 20px;
}
.secondaryColoredBtn img {
  width: 10px;
}
.secondaryOutlineBtn {
  background-color: #ffffff;
  border: 1px solid #3c3e4d;
  color: #3c3e4d;
  font-size: 12px;
  font-weight: 500;
  border-radius: 50px;
  padding: 6px 20px;
}
.secondaryFilledBtn {
  border-radius: 10px;
  background: linear-gradient(274deg, #F44D5E -5.92%, #F46B4D 114.01%); 
  border: none;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 20px;
}
.activeHackathonBtn {
  background-color: #2bbd10;
  border: 1px solid #2bbd10;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 50px;
  padding: 8px 30px;
}
.inactiveHackathonBtn {
  background-color: #ff6f73;
  border: 1px solid #ff6f73;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 50px;
  padding: 8px 30px;
}

/* Popup button */

.primaryPopupbtn {
  background: #3c3e4d;
  border: none;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  border-radius: 50px;
  padding: 6px 30px;
}
.primaryPopupbtn img {
  width: 15px;
}
.secondaryPopupbtn {
  background-color: #000000;
  border: 1px solid #000000;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 50px;
  padding: 6px 30px;
}
.AssessmentsecondaryPopupbtn {
  background-color: #f2f3f4;
  border: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
  border-radius: 50px;
  padding: 6px 30px;
}
.AssessmentsecondaryPopupbtn:hover {
  background-color: #e2e2e9;
  color: #3c3e4d;
}
.activeTab {
  background-color: #3c3e4d;
  border: 1px solid #3c3e4d;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 50px;
  padding: 6px 30px;
}
.inactiveTab {
  background-color: #ffffff;
  border: 1px solid #e2e2e9;
  color: #3c3e4d;
  font-size: 12px;
  font-weight: 600;
  border-radius: 50px;
  padding: 6px 30px;
}
.tags {
  background: #ededee;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #b1b2b8;
  padding: 8px;
}
.cursor-pointer {
  cursor: pointer;
}
.content-page {
  margin-left: 227px;
  transition: margin-left 0.3s ease-out;
}
.collapsed-content-page {
  margin-left: 60px;
  transition: margin-left 0.3s ease-out;
}
.tag-item > .button {
  width: 22px;
  background: none;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  line-height: inherit;
  color: #3c3e4d;
  -webkit-appearance: button;
  font-size: 22px;
  opacity: 0.7;
}

@media (min-width: 1920px) {
  .paragraph,
  .paragraph p,
  .secondaryFilledBtn,
  .sidebarInactiveText,
  .secondaryOutlineBtn {
    font-size: 15px;
  }
  .activeHackathonBtn,
  .cardHeading {
    font-size: 17px;
  }
  .formInput,
  .formInput::placeholder {
    font-size: 17px;
  }
  .activeTab {
    font-size: 15px;
  }
}
.rc-time-picker-panel {
  z-index: 9999999 !important;
}
.lms-heading h1 {
  color: #202224;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
}
