.jobs {
  margin-top: 15px;
  max-height: 367px;
  overflow-y: scroll;
}

.hackthonsTop {
  padding-bottom: 28px;
}

.yourJobsLeft .yourJobStatus {
  padding: 5px 13px 7px 13px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.yourJobsLeft .yourJobStatusExpired {
  background: rgba(244, 77, 94, 0.2)
}

.yourJobsLeft .yourJobStatusActive {
  background: #DCFAE9;
  color: #34C471;
}

.yourJobPosted {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #6B7280;
  margin-bottom: 12px;
}

.yourJobsRight {
  background: #F4F5F9;
  padding: 4px 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  cursor: pointer;
}

.yourJobsRight p {
  min-width: 80px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.yourJobsRight:hover {
  background-color: #000000;
}

.yourJobsRight:hover p {
  color: #ffffff;
}

.yourJobTitle {
  padding: 10px 0px 5px 0px;
}

.years {
  margin-right: 12px;
}

.arrowIcon {
  width: 20px;
  height: 20px;
}

.jobCardOne {
  margin-right: 20px;
  border: 1px solid #F4F5F9;
  border-bottom: none;
}

.jobCardOne:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.jobCardOne:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom: 1px solid #F4F5F9;
}

.totalJobCards {
  overflow: auto;
  /* width: calc(100vw-200px); */
}

.cardYears {
  margin-right: 35px;
}

.totalJobCards::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 540px) {
  .hackthonsTop p {
    font-size: 8px;
  }

  .hackthonsTop img {
    width: 12px;
    height: 12px;
  }

  .cardYears {
    margin-right: 80px;
  }

  .cardTime {
    margin-right: 65px;
  }

}