.dashboardScheduleRow {
  border: 1px solid #F4F5F9;
  border-bottom: none;
}

.dashboardScheduleRow:hover {
  background: rgba(244, 245, 249, 0.4);
}

.dashboardScheduleRow .interviewTitleText ,.scheduleCandidateName{
  min-width: 180px;
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dashboardScheduleTable {
  padding-top: 28px;
}

.schedulePrimaryBtn,
.scheduleSecondayBtn {
  border: none;
  background: #F4F5F9;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #7E8390;
  font-size: 12px;
  font-weight: 500;
  border-radius: 50px;
  padding: 6px 20px;
}

.schedulePrimaryBtn:hover,
.dashboardScheduleTable .scheduleSecondayBtn {
  background-color: #f44d5e;
  color: #FFFFFF;
}

.dashboardScheduleRow:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dashboardScheduleRow:last-child {
  border-bottom: 1px solid #F4F5F9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.userSchedule {
  min-height: 130px;
  margin-top: 15px;
}

.scheduleMarginRight {
  margin-right: 20px;
}

.sheduleDivider {
  height: 14px;
  width: 1px;
  background: #000000;
  display: inline-block;
}

.scheduleStatus {
  border-radius: 10px;
  padding: 3px 10px;
  background: #DCFAE9;
}

.schedulePending {
  background: #FFF2E6;
}

.scheduleTimeText {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.scheduleCandidateName {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}

.ScheduleMessageIcon {
  width: 57px;
  height: 32px;
}

.ScheduleLinkIcon {
  height: 28px;
  width: 27px;
}

.dashboardScheduleSectionMore {
  width: 3px;
  height: 15px;
}

.show {
  display: block;
  border-radius: 5px;
}

.three-dots-1 {
  background: none !important;
}

.list-view-dropdown {
  position: absolute !important;
  top: 22px;
  left: -180px;
  border-radius: 6px !important;
  border: none;
  background: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
  z-index: 1;
  cursor: pointer;
}

.dashboardScheduleTable .menu-btn:first-child:hover {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.dashboardScheduleTable .menu-btn:last-child:hover {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.list-view-dropdown .dropdown-ul {
  padding-left: 0px;
}

.list-view-dropdown .dropdown-ul li {
  color: #000;
  text-align: left;
}

.viewAll {
  cursor: pointer;
}

#three_dots {
  position: relative !important;
  display: inline-block !important;
}

.finalRow {
  border: none;
}

.link-icon-display:hover {
  content: url("../../../../assets/Icons/Dashboard/clickableIcon.svg");
}

@media only screen and (max-width: 540px) {
  .scheduleTop h5 {
    font-size: 12px;
  }

  .scheduleTop p {
    font-size: 8px;
  }

  .scheduleTop img {
    width: 12px;
    height: 12px;
  }
}