.setupOrganizationContainer {
  font-family: "Inter";
}
.settingsPage .leftSection {
  z-index: 111;
}
.settingsPage .rightSection {
  z-index: 0;
}
.settingsPage .settingsContainer {
  margin-top: 50px;
}
.settingsPage .settingsHeader {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  background: #f2f3f4;
}
.settingsPage .settingsContentContainer {
  background: #ffffff;
  height: calc(100vh - 50px);
}

.settingsPage .settingsContentContainer .settingsTab {
  border-right: 1px solid #e2e2e9;
  width: 16%;
}
.settingsPage .settingsContentContainer .settingsTab .tab,
.settingsPage .settingsContentContainer .settingsTab .settingActive {
  border-bottom: 1px solid #e2e2e9;
}
.settingsPage .settingsContentContainer .settingsTab .settingActive {
  background: #f8f8f9;
}
.settingsPage .settingsContentContainer .settingsTab p {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  width: 80px;
  text-align: center;
  display: inline-block;
}
.settingsPage .settingsContentContainer .settingsTab .tabImg {
  width: 15px;
}
.setupOrganizationContainer {
  margin: auto;
  height: calc(100vh - 120px);
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* padding: 0 20px;  */
}

.setupOrganizationContainer::-webkit-scrollbar {
  display: none;
}
/* .iamContainer {
  height: calc(100vh - 118px);
  overflow: hidden;
} */
.iamContainer::-webkit-scrollbar {
  display: none;
}
@media (min-width: 1500px) {
  .setupOrganizationContainer {
    max-width: 1400px;
  }
}
