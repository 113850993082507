th {
  font-size: 14px;
}

thead th {
  border: none;
}
.NoOfRoundHld {
  width: 100px;
  padding: 10px 0;
  font-size: 12px;
}
.listTablehld table td:first-child,
.listTablehld table th:first-child {
  padding-left: 1.5rem !important;
  border-left: none;
}
.timings-card-view .interviewScheduleDate {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #92929d;
}
.acceptancyAwait {
  padding: 2px 13px 4px 7px;
  color: #f88f33;
  background: url("../../../assets/Icons/Interview/New/orangeAcceptancy.svg")
    no-repeat;
}
.no-of-rounds {
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  background-color: #e6e8ec;
  color: #92929d;
  background: white;
  font-weight: 500;
  display: table-cell;
  vertical-align: middle;
  margin-left: 20px;
}
.no-of-rounds:hover {
  cursor: default;
}
.acceptancyDecline {
  padding: 2px 23px 4px 7px;
  color: #f44d5e;
  background: url("../../../assets/Icons/Interview/New/redAcceptancy.svg")
    no-repeat;
}
.interviewsBoard {
  height: calc(100vh - 165px);
  /* border-right: 1px solid #e4e4e5; */
  overflow: auto;
  position: relative;
}
.acceptancyDecline {
  padding: 2px 23px 4px 7px;
  color: #f44d5e;
  background: url("../../../assets/Icons/Interview/New/redAcceptancy.svg")
    no-repeat;
}
.acceptancyAccept {
  padding: 2px 23px 4px 7px;
  color: #05d871;
  background: url("../../../assets/Icons/Interview/New/greenAcceptancy.svg")
    no-repeat;
}
.completedInterviewsTable {
  padding-right: 0px;
}

.interviewTabTable {
  height: calc(100% - 60px);
  /* overflow: scroll; */
}

tbody tr:hover {
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.1);
}

.interviewTabTable .interviewTabEachRow {
  padding: 9.8px 8px;
}

.interviewPagination {
  position: absolute;
  width: 100%;
  background: #efedf1;
  bottom: 0px;
}

tbody td {
  border: none;
  border: 1px solid #e4e4e4;
}

.interviewTabComapnyImage p {
  color: #3c3e4d;
  font-weight: 500;
  font-size: 14px;
}

.interviewTabComapnyImage img {
  width: 39px;
  height: 37px;
  border: 1px solid #e4e4e5;
}

.interviewTabInterviewTitle p {
  color: #3c3e4d;
  font-weight: 500;
  font-size: 14px;
}

.interviewTabCandidateName {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e3e3e3;
}

.interviewTabCandidateName p {
  font-weight: 700;
  font-size: 14px;
  color: #3c3e4d;
}
.interviewTabCandidateNameOptional {
  font-weight: 400;
  font-size: 14px;
  color: #3c3e4d;
}

.interviewTabInterviewName p {
  font-weight: 400;
  font-size: 14px;
  color: #3c3e4d;
}

.interviewTabInterviewOn p {
  font-weight: 400;
  font-size: 12px;
}

.interviewTabInterviewOn small {
  font-weight: 400;
  font-size: 12px;
}

.interviewTabRound {
  width: 120px;
  height: 34px;
  background: #e4e4e4;
  border-radius: 30px;
}

.interviewTabRound p {
  font-weight: 500;
  font-size: 14px;
  color: #3c3e4d;
}

.interviewTabEdit {
  width: 30px;
  height: 30px;
  border: 1px solid #3c3e4d;
  border-radius: 50%;
}

.interviewTabEditIcon {
  width: 12px;
  height: 12px;
}

.interviewTabDeleteButton {
  width: 170px;
  height: 30px;
  border: 1px solid #3c3e4d;
  border-radius: 30px;
  cursor: pointer;
}
.interviewTabDeleteButton p {
  font-weight: 500;
  font-size: 14px;
  color: #3c3e4d;
}

.completedCandidateInterviewStatus {
  font-size: 14px;
  font-weight: 500;
}
