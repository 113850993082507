/* question preview popup */
.ModalQuestionPreview .modal-dialog {
  max-width: 857px;
}
.ModalQuestionPreview .secondaryColoredBtn > img,
.ModalQuestionPreview .secondaryPopupbtn > img {
  width: 15px;
}
.ModalQuestionPreview .modal-content {
  border: 0.5px solid #d9d9d9;
  background: #ffffff;
}
.ModalQuestionPreview .QuestionModalHeader {
  padding: 17px 15px;
  display: block;
}
.ModalQuestionPreview .QuestionTitle {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}
.ModalQuestionPreview .QuestionDiffculty {
  font-family: "Inter";
  font-weight: 400;
  font-size: 10px;
  border-radius: 5px;
  margin-left: 11px;
  padding: 4px 8px;
}
.ModalQuestionPreview .ModalQuestionDetails {
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}
.ModalQuestionPreview .ModalQuestionDetails .skillsPreview {
  background-color: #f2f3f4;
  padding: 3px 10px;
  margin-left: 5px;
  border-radius: 4px;
}
.ModalQuestionPreview .EasyQuestion {
  color: #00b547;
  background-color: #f1fff6;
  border: 0.5px solid #098f4b;
}
.ModalQuestionPreview .IntermediateQuestion {
  background: #fff3e1;
  border: 0.5px solid #feb91e;
  color: #cc860f;
}
.ModalQuestionPreview .AdvancedQuestion {
  color: #d6313a;
  background: #fee9ed;
  border: 0.5px solid #d6313a;
}
.ModalQuestionPreview .ModalQuestionDetails p {
  margin: 18px 30px 0px 0px;
}
.ModalQuestionPreview .QuestionDescription {
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin: 15px 0px 24px;
}
.ModalQuestionPreview pre {
  background: #f2f9fe;
  border: 1px solid #e4edf6;
  border-radius: 5px 0px 5px 5px;
  padding: 9px 14px;
}
.ModalQuestionPreview .QuestionHeading {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}
.ModalQuestionPreview .QuestionDescriptionPoints {
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}
.ModalQuestionPreview .testcaseHeadings {
  color: #666666;
  font-weight: 500;
  font-size: 12px;
  font-family: "Inter";
  margin-top: 18px;
}
.ModalQuestionPreviewOption {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}
.ModalQuestionPreviewDivider {
  margin-left: -16px;
  margin-right: -16px;
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}

.ModalQuestionPreviewOption input[type="radio"]:checked::before {
  /* selected */
  background: radial-gradient(#f44d5e 40%, transparent 50%);
  border: 1px solid #f44d5e;
  border-radius: 50px;
}
.ModalQuestionPreviewOption input[type="radio"]::before {
  border: 1px solid #d9d9d9;
  border-radius: 50px;
}

/* media queries for modal  questionPreview */
.QuestionSetCardContainer {
  padding: 20px 0px 18px 20px;
  margin: 0px 20px 15px 20px;
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  border-radius: 10px;
  position: relative;
}
.QuestionSetCardDec {
  font-weight: 400;
  font-size: 12px;
  color: #3c3e4d;
}
.QuestionSetRecommmendedFlag {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #00b34f;
  background: #f0fff6;
  padding: 0px 15px 0px 0px;
}

.AssessmentCheckbox {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #e2e2e9;
  border-radius: 2px;
  margin: 0;
  padding: 6px;
  font: inherit;
  color: currentColor;
  width: 14px;
  height: 14px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.AssessmentCheckbox::before {
  content: "";
  width: 8px;
  height: 8px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  background: #ffff;
}

.AssessmentCheckbox:checked {
  background: #f44d5e;
  border: none;
}

.AssessmentCheckbox:checked::before {
  transform: scale(1);
}

.QuestionSetRecommmendedFlagDiv {
  width: 0;
  height: 0;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 15px solid #ffffff;
}

.AssessmentQuestionSetCardVerticalBar {
  width: 0px;
  height: 20px;
  border: 0.5px solid #666667;
}
.Skillsheading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #3c3e4d;
}
.AssessmentQuestionSetSkillsTag {
  background: #f2f3f4;
  border-radius: 4px;
  padding: 3px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}
.QuestionSetCardDescription {
  margin: 20px 0px 0px 30px;
}
.QuestionSetCardDescription .seprator {
  border-right: 0.5px solid #d9d9d9;
}
.QuestionSetCardDescription .secondaryColoredBtn img {
  width: 18px;
}
/*BuildYourAssessmentSection*/

.BuildYourAssessmentSectionDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.BuildYourAssessment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 150px);
}
.BuildYourAssessmentMhBtn > img {
  width: 10px;
  height: 10px;
}

.BuildYourAssessmentText {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.BuildYourAssessmentBtn {
  all: unset;
  align-items: center;
  background: #3c3e4d;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  padding: 7px 30px;
  color: #ffffff;
}

.BuildYourAssessmentDescription {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #b0b0b4;
}

.BuildYourAssessmentMhBtn {
  all: unset;
  align-items: center;
  background: #f44d5e;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  padding: 7px 30px;
  color: #ffffff;
}

.BuildYourAssessmentBtn > img {
  width: 10px;
  height: 10px;
}

/*Participants Filter */

.ParticipantsFilter {
  background: #f2f3f4;
  padding: 9px 18px;
}

.ParticipantsFilterClearBtn {
  all: unset;
  border: 1px solid #92929d;
  border-radius: 50px;
  font-weight: 400;
  padding: 2px 13px;
  font-size: 12px;
  line-height: 18px;
  color: #92929d;
}

.SearchParticipantsLable {
  font-weight: 500;
  font-size: 12px;
  color: #3c3e4d;
}

.SearchParticipantsInput {
  padding: 2px 15px 5px 15px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
}

.SearchParticipantsInput > input {
  all: unset;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
}

.SearchParticipantsInput > input::placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #b1b2b8;
}

.AssessmentCandidateStatus::before {
  width: 15px !important;
  height: 15px !important;
  background-color: #ffffff;
  border: 1px solid gray !important;
}

.ParticipantsFilterDropdown {
  padding: 7px 20px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
}

.ParticipantsFilterDropdown > span {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #666666;
}

/*Participants Filter */
.addQuestionFrom {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 7px 30px 7px 15px;
  width: fit-content;
}

.addQuestionFrom:hover,
.addQuestionFromOwnDropdown > div:hover,
.addQuestionFromMHDropdown > div:hover {
  background: #f2f3f4;
  cursor: pointer;
}

.addQuestionFrom > span {
  font-weight: 500;
  font-size: 14px;
  color: #3c3e4d;
  font-family: "Inter";
}

.addQuestionFromMHDropdown {
  background-color: #ffffff;
  width: 53%;
  border-radius: 5px;
  top: 70px;
  left: 0px;
  margin-left: -16px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter";
  color: #3c3e4d;
}
.addQuestionFromMHDropdown > div,
.addQuestionFromOwnDropdown > div {
  border-radius: 5px;
  padding: 15px 0px 15px 13px;
  margin: 5px 18px 0px 18px;
}
.addQuestionFromOwnDropdown {
  background-color: #ffffff;
  border-radius: 5px;
  top: 70px;
  width: 53%;
  right: 0px;
  margin-right: -16px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #3c3e4d;
}

.AddQuestionModal > div {
  max-width: 600px;
}
.AddQuestionModal {
  padding-bottom: 25vh;
}

/*Add Question Modal*/

/* Select - Role Modal*/

.noRoleFound > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  padding-bottom: 13px;
  font-size: 12px;
  line-height: 15px;
  color: #c2c2c2;
}
.noRoleFound > div {
  background: #f2f3f4;
  border: 1px dashed #d9d9d9;
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 9px;
  margin: 9px 13px;
}

.assessmentSelectRoleModalLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.assessmentSelectRoleModal .formInput {
  background: #f2f3f4;
  border: none;
  color: #666666;
}
.assessmentSelectRoleModal .formInput:-moz-focusring {
  outline: none;
}
.assessmentSelectRoleModal .formInput:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.AssessmentSelectRoleDropDown {
  background: #f2f3f4;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
  padding: 10px 20px;
}
.assessmentSelectRoleModal {
  padding: 0px 25px 100px;
}

.assessmentSelectrRoleDropDownField {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2px 20px 10px rgb(0 0 0 / 5%);
  border-radius: 5px;
  display: grid;
  right: 30px;
  top: 80px;
  z-index: 9;
}
.assessmentJobrolelist  p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  padding: 12px 110px 8px 20px;
  border-radius: 5px;
}
.assessmentJobrolelist {
  overflow: auto;
  height: 200px !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.assessmentJobrolelist::-webkit-scrollbar {
  display: none;
}
.assessmentJobrolelist p:hover {
  background: #f2f3f4 !important;
  cursor: pointer;
}
.assessmentSelectRoleModalButton .primaryBtnActive {
  padding: 5px 20px;
  font-family: "Inter";
}
.assessmentSelectRoleModalBody .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: none;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.assessmentSelectRoleModalBody :focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}
.assessmentSelectRoleModalBody .btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: 1;
}
.assessmentSelectRoleModalBody .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba (0, 0, 0, 0.2);
  border-radius: 10px;
  outline: 0;
  padding: 8px;
}
.assessmentSelectRoleModalButtonImg {
  vertical-align: unset;
}
@media (min-width: 992px) {
  .assessmentSelectRoleModalBody .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
/*Select - Role Modal */

/*customDropdown start */

.customDropdown {
  width: 220px;
  border: 1px solid #e2e2e9;
  font-family: "inter";
  font-size: 12px;
  font-weight: 400;
  color: #3c3e4d;
  position: relative;
  border-radius: 50px;
  min-height: 40px;
}

.statusList {
  position: absolute;
  top: 42px;
  right: 16px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 20px 10px rgb(0 0 0 / 5%);
  z-index: 99999999;
  overflow-y: scroll;
}
.customDropdown .statusList::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.statusList .dropdownItem {
  text-align: left;
  padding: 10px 88px 11px 19px;
}
.statusList .dropdownItem:hover {
  background-color: #f2f3f4;
}
/*customDropdown end */

/*custom tagsSearchBar start */
.tagsSearchBar {
  display: flex;
  gap: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
  padding: 5px 20px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.tagsSearchBar::-webkit-scrollbar {
  display: none;
}
.tagsSearchBar .tagsInput {
  border: none !important;
  padding: 0px 5px;
}
.tagsSearchBar .tag {
  padding: 3px 9px;
  background: #f2f3f4;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  height: 24px;
  font-weight: 400;
  font-size: 12px;
  align-items: center;
  line-height: 15px;
  color: #666666;
  display: flex;
}
.tagsSearchBar .tag img {
  height: 6px;
  width: 6px;
}
.tagsSearchBar .tagsInput:focus-visible {
  outline: none;
}
/*custom tagsSearchBar end */
/*Assessment Tabs*/
.AssessmentTabsHeader {
  padding: 5px;
  border-bottom: 1px solid #f2f3f4;
}
.AssessmentTabsTags {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #666666;
}
.assessmentQuestionTagCount {
  background: #666666;
  border-radius: 50px;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  padding: 4px 10px;
}
.assessmentParticipantsTagCount {
  background: #ffbb62;
  border-radius: 50px;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  padding: 4px 10px;
}
.AssessmentTabsCopyLinkBtn {
  background: #ffffff;
  border-radius: 50px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  color: #3c3e4d;
  border: none;
  padding: 5px 20px;
}
.AssessmentTabsTryBtn {
  background: #3c3e4d;
  border-radius: 50px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  border: none;
  padding: 5px 20px;
}
.AssessmentTabsArchiveBtn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  background: #f44d5e;
  border-radius: 50px;
  color: #ffffff;
  border: none;
  padding: 5px 20px;
}
.AssessmentTabsOptions {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: grid;
  right: 0px;
  top: 32px;
  cursor: pointer;
  z-index: 1;
  min-width: 130px;
}
.AssessmentTabsOptions > span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  padding: 8px 60px 8px 20px !important;
}
.AssessmentTabsOptions p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  padding: 8px 8px 4px;
}
.AssessmentTabsHeader .active {
  position: relative;
}
.AssessmentTabsHeader .active::after {
  position: absolute;
  content: "";
  left: -5px;
  bottom: -8.5px;
  width: 120%;
  height: 4px;
  background-color: #f44d5e;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

/* Assessment List*/

.newAssessmentList .primaryBtnActive {
  border-radius: 50px;
  padding: 3px 30px;
  font-size: 14px;
}
.AssessmentListRadioBtn > div > input[type="radio"]::before {
  border: 1px solid #d9d9d9;
}
.AssessmentListRadioBtn > div > input[type="radio"]:checked::before {
  border: 1px solid #3c3e4d !important;
}
.newAssessmentListInputContainer {
  display: inline;
  padding: 5px 15px;
  background: #ffffff;
  border: 0.5px solid #d9d9d9;
  border-radius: 50px;
}
.newAssessmentListAssessmentSearch {
  display: inline;
  padding: 2px 15px;
  background: #f2f3f4;
  border-radius: 50px;
}
.newAssessmentListInputContainer .formInput {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #b1b2b8;
}
.newAssessmentListInputContainer .formInput::placeholder {
  font-weight: 500;
  font-size: 12px;
  color: #b1b2b8;
}
.assessmentListInput {
  all: unset;
}
.newAssessmentListTabs .selectedTab {
  color: #000000;
  font-weight: 500;
}
.newAssessmentListTabs {
  border-top: 1px solid #f2f3f4;
  border-bottom: 1px solid #f2f3f4;
}
.newAssessmentListTabs > div {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}
.newAssessmentListFilters {
  background: #f2f3f4;
  height: calc(100vh - 100px);
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.newAssessmentListFilters::-webkit-scrollbar {
  display: none;
}

.newAssessmentListFiltersButton {
  border: 1px solid #92929d;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #92929d;
  border-radius: 50px;
  padding: 2px 12px;
}
.newAssessmentListRole {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #3c3e4d;
}
.newAssessmentFilterRadio {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  padding: 8px 60px 8px 20px;
}
.AssessmentTabsOptions :hover {
  background: #f2f3f4 !important;
}

/*Assessment Tabs */

.newAssessmentListCustomCheckBox {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #e2e2e9;
  border-radius: 2px;
  margin: 0;
  padding: 6px;
  font: inherit;
  color: currentColor;
  width: 18px;
  height: 18px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  background: #ffffff;
}

.newAssessmentListCustomCheckBox::before {
  content: "";
  width: 8px;
  height: 8px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  background: #ffff;
}

.newAssessmentListCustomCheckBox:checked {
  background: #3c3e4d;
  border: none;
}

.newAssessmentListCustomCheckBox:checked::before {
  transform: scale(1);
}

/* Assessment List*/
.allAssessmentlisthld {
  height: calc(100vh - 150px);
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.allAssessmentlisthld::-webkit-scrollbar {
  display: none;
}

/* collapsible list start  */
.collapsibleList {
  font-family: "Inter";
  position: absolute;
  bottom: 0;
}
.collapsibleList .listContainer.collapsed {
  height: 0px;
}
.collapsibleList .listContainer {
  height: auto;
  transition: height 0.6s ease-out;
  background-color: #ffff;
}
.collapsibleList .ListHeader {
  background: #3c3e4d;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.05);
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.collapsibleList .ListHeader .listHeadItems {
  padding: 12px;
}
.collapsibleList .ListHeader .closeBtn {
  background: rgba(146, 146, 157, 0.2);
  padding: 10px 0px;
  width: 40px;
  text-align: center;
}
/* collapsible list start  */

/*For Loader*/
.loaderForLabel {
  width: 25%;
  height: 22px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 20px;
}
.loaderForInput {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.loaderForButton {
  width: 20%;
  margin-bottom: 10px;
  margin-top: 5px;
  height: 30px;
  margin-right: 20px;
}
.loaderForDescription {
  width: 100%;
  height: 65px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.loaderForCheck {
  width: 14px;
  height: 14px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.loaderForImage {
  position: relative;
  width: 6%;
  height: 12%;
}
/*For Loader*/

/* Bulk Upload Modal */
.bulkquesuploadModal {
  width: 580px !important;
}
.bulkquesuploadModal .modal-header {
  justify-content: center;
}
.bulkquesuploadModal .modal-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}
.bulkquesuploadModal .closeIcon {
  position: absolute;
  right: 20px;
}
.bulkquesuploadModal .stepsToUpload {
  padding: 1rem 2rem 1rem;
  border-bottom: 1px solid #d9d9d9;
}
.bulkquesuploadModal .stepsToUpload p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #3c3e4d;
  margin-bottom: 0;
  line-height: 30px;
}
.bulkquesuploadModal .uploadCSVQuestionsCntr {
  background: #f6f6f6;
  text-align: center;
}
.bulkquesuploadModal .uploadCSVQuestionsCntr p {
  font-size: 14px;
  color: #3c3e4d;
}
.bulkquesuploadModal .uploadCSVQuestionsCntr p a {
  font-size: 14px;
  font-weight: 500;
  color: #f44d5e;
}
.bulkquesuploadModal .uploadCSVQuestionsCntr .uploadQuesCsv {
  background-color: #f44d5e;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border-radius: 50px;
  width: 25%;
}
.bulkquesuploadModal .uploadCSVQuestionsCntr .disabled {
  background-color: #e9ecef;
  color: #3c3e4d;
}
.bulkquesuploadModal .uploadCSVQuestionsCntr button img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  vertical-align: text-bottom;
}
.bulkquesuploadModal .uploadCSVQuestionsCntr .dropzoneArea {
  padding: 20px 30px;
  height: 140px;
  width: 350px;
  background-color: #ffffff;
  text-align: center;
  border: dashed 1px#3c3e4d;
  border-radius: 5px;
  margin: 0 auto 20px;
}
.bulkquesuploadModal .uploadCSVQuestionsCntr .csvBtn {
  margin: 10px auto 0;
  background-color: #3c3e4d;
  border: none;
  font-family: Roboto;
  font-size: 12px;
  color: #ffffff;
  padding: 8px 35px;
  border-radius: 5px;
}
.errortext {
  color: #f44d5e;
  font-size: 12px;
  margin-top: 6px;
}
.premium-limitations {
  color: #ed952d;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  background: #fff6ee;
  padding: 10px 20px;
  border-radius: 10px;
}
.upgrade-plan-hld {
  filter: drop-shadow(
    0px 3.954360246658325px 19.771800994873047px rgba(0, 0, 0, 0.05)
  );
  margin: 0 20px;
  padding: 6px 20px 6px;
  border-radius: 10px 10px 0 0;
  align-items: center;
}
.limit-crossed-plan-hld {
  background: #fff2f3;
}
.under-limit-plan-hld {
  background: #fff6ee;
}
.upgrade-plan-hld .limitation-content {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-items: center;
  display: flex;
}
.upgrade-plan-hld .limit-crossed {
  color: #f44d5e;
}
.upgrade-plan-hld .under-limit {
  color: #ed952d;
}
.upgrade-plan-hld .limitation-content .circle-check {
  font-size: 20px;
}
.upgrade-plan-hld .upgrade-btn {
  background: #111827;
  color: #ffffff;
  color: #fff;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  border: none;
  border-radius: 50px;
  padding: 6px 22px;
}

.negative-margin-top-10 {
  margin-top: -10px;
}
.masking-data-hld .QuestionSetCardContainer {
  filter: blur(8px);
}

.mask-data {
  background: rgba(17, 24, 39, 0.23);
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  margin: 0px 20px 15px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask-data .mask-data-upgrade-hld {
  border-radius: 10px;
  background: #fafafb;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto 40px;
  padding: 20px;
}
.mask-data-upgrade-hld p {
  color: #6b7280;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}
.mask-data-upgrade-hld .Upgrade-btn {
  background: #ffbb6c;
  border-radius: 50px 0 0 50px;
  padding: 4px 20px;
  border: #ffbb6c;
  color: #111827;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
}
.mask-data button img {
  width: 13px;
}
.mask-data-upgrade-hld .border-radius-50 {
  border-radius: 50px;
}
.mask-data-upgrade-hld .font-14-500{
  font-size: 14px;
  font-weight: 500;
}