* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f3f5f7;
}

.dashboardCard {
  background: #FFFFFF;
  box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
  border-radius: 20px;
  padding: 25px 25px;
  box-shadow: none;
  transition: box-shadow 0.3s;
}

.dashboardInterviewHld .dashboardCard {
  padding: 15px 20px 8px 20px;
}

.dashboardCard.dashboardHackthonItem:hover {
  transition-delay: 0.1s;
  box-shadow: 0px 15px 40px #F0D9E1;
}

.dashboard .dashboardHeading {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
}

.dashboard .dashboardViewAll {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #969BA0;
}

.dashboard .dashboardNormalText {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #6B7280;
}

.dashboard .dashboardBoldText {
  font-weight: 600;
  font-size: 14.4px;
  line-height: 150%;
  color: #343434;
}

.dashboard .dashboardSubHeadText {
  font-weight: 500;
  font-size: 14.4px;
  line-height: 140%;
  color: #111827;
}

.dashboardNothingFoundText {
  color: #747a8a;
  font-size: 14px;
  font-weight: 400;
  min-height: 42px;
}

.bodyContainer {
  padding: 28px;
  background-color: #f3f5f7;
  height: 100%;
}

.body-margin-top {
  margin-top: 50px;
}

.dashboard {
  background: #F3F4F6;
  font-family: 'Inter', sans-serif;
  margin: 0 auto;
}

.dashboard .dashBoardLoaderCard {
  padding: 15px;
  border: 1px solid #e4e4e5;
  border-radius: 10px;
  display: flex;
}

.dashboard .dashBoardLoaderCardTitle {
  height: 24px;
  width: 10%;
}

.dashboard .dashBoardLoaderCardDivTitle {
  height: 16px;
}

.dashboard .dashBoardLoaderCardDes {
  height: 16px;
  width: 10%;
}

.goodMorning {
  padding-top: 15px;
}

.dashboardUserName {
  padding-top: 10px;
  font-weight: 300;
  font-size: 28px;
}

.dashboardConetentBox:hover .dashboardMarkIcon {
  content: url("../../../assets/Icons/Dashboard/WhiteTick.svg");
}

.dashboardContent {
  padding-top: 20px;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #3c3e4d;
}

.dashboardScroller::-webkit-scrollbar {
  display: none;
}

.dashboardScroller {
  overflow: auto;
  height: calc(100vh - 50px);
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.dashboardText {
  padding-top: 15px;
  color: #747a8a;
  font-size: 16px;
}

.dashboardTotalContentBox {
  display: flex;
  flex-direction: column;
}

.dashboardConetentBox {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 300px;
  height: 50px;
  border: 1px solid #e4e4e5;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.dashboardMarkIcon {
  width: 30px;
  height: 30px;
  margin: 0px 10px;
}

.dashboardContentBoxData {
  font-size: 16px;
  line-height: 19px;
  color: #747a8a;
  margin: 0px 0px 0px 10px;
}

.dashboardConetentBox:hover {
  background-color: #f44d5e;
}

.dashboardConetentBox:hover .dashboardContentBoxData {
  color: #ffffff;
}

.dashboardConetentBox:hover .dashboardMarkIcon {
  color: #ffffff;
}

.ballons-image {
  width: 125px;
  height: 125px;
  margin: auto;
  border-bottom: 3px solid #c2c8d3;
}

.dashboardHeadingContainer {
  position: relative;
  padding: 20px;
  margin: 0 0 20px 0;
  border: solid 1px #e5e5e5;
  border-radius: 0px 20px 0px 0px;
}

.dashboardHeadingContainer::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 600px;
  background: #484a58;
  left: 50%;
  bottom: -2px;
  border-radius: 1px;
  transform: translate(-50%, 0);
}

.dashboardHeadingContainer h2 {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #212529;
}

.recentHackathons,
.totalRegistration,
.topCities {
  padding: 0 25px;
}

.hamburgerButton {
  padding-top: 22px;
  padding-right: 2px;
}

.recentHackathonsHeader,
.totalRegistrationHeader,
.topCitiesHeader {
  margin: 20px 0 30px;
}

.recentHackathonsHeader h3,
.totalRegistrationHeader h3,
.topCitiesHeader h3 {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c3e4d;
}

.swiperPagination {
  position: relative;
  width: 14%;
}

.swiperPagination a {
  left: 0;
  position: absolute;
  font-size: 18px;
  text-align: center;
  color: #212529;
  text-decoration: none;
}

.swiper-button-next,
.swiper-button-prev {
  border: solid 1px;
  border-radius: 50%;
  width: 25px !important;
  height: 25px !important;
  color: #000000 !important;
  /* top: 0 !important;
  margin-top: 0 !important; */
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 10px !important;
}

.paginationBtns {
  position: absolute;
  right: 0;
  width: 50%;
  display: flex;
  justify-content: space-around;
  top: 10px;
  height: 100%;
}

.dashboardContentContainer {
  height: calc(100vh - 262px);
  overflow: auto;
}

.dashboardTotalSearchBar {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding-bottom: 10px;
}

.dashboardTotalSearchBar h4 {
  margin-top: 10px;
}

.dashboardSearchBar {
  position: relative;
  width: 514px;
  height: 10px;
  background: #ffffff;
  box-shadow: 0px 5px 30px 20px rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  padding-top: 5px;
}

.dashboardSearchBar input {
  position: relative;
  outline: none;
  width: 100%;
  border: solid 1px #eaeaea;
  /* border-radius: 50px; */
  padding: 10px 0 10px 20px;
  font-size: 14px;
}

.dashboardSearchBar .searchIcon {
  position: absolute;
  top: 50%;
  right: 5%;
  padding: 6px 16px;
  height: 34px;
  cursor: pointer;
}

.dashboardFiltersTab {
  background: #F4F5F9;
  border-radius: 6px;
  padding: 4px;
}

.dashboardViewAllImgHld {
  background: #F4F5F9;
  border-radius: 6px;
  padding: 8px 14px;
}

.dashboardFiltersInnerTab img {
  height: 12px;
  width: 12px;
}

.dashboardFiltersTab .dashboardFiltersInnerTab {
  padding: 5px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #969BA0;
  cursor: pointer;
}

.dashboardFiltersActive {
  background: #FFFFFF;
  border-radius: 6px;
  color: #000000;
}

@media only screen and (min-width: 2560px) {
  .dashboardHeadingContainer h2 {
    font-size: 30px;
  }

  .recentHackathonsHeader h3,
  .totalRegistrationHeader h3,
  .topCitiesHeader h3 {
    font-size: 26px;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .swiperPagination {
    width: 30%;
  }
}

@media only screen and (max-width: 916px) {
  .dashboardTotalSearchBar {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .dashboardSearchBar {
    margin-bottom: 30px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 590px) {
  .dashboardSearchBar {
    width: 300px;
    margin-top: 20px;
  }

  .searchIcon {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .swiperPagination {
    width: 100%;
  }

  .dashboardNavbarContainer {
    display: none;
  }
}

@media only screen and (min-width: 0px) and (max-width: 479px) {
  .dashboardHeadingContainer {
    padding: 10px;
  }

  .dashboardHeadingContainer h2 {
    font-size: 20px;
  }

  .dashboardHeadingContainer::after {
    width: 99px;
  }
}