.companiesBoard {
  /* background-color: red; */
  height: calc(100vh - 90px);
  border-right: 1px solid #e4e4e5;
  padding-right: 0px;
  /* overflow: auto; */
}

.totalTabs {
  height: calc(100% - 65px);
  overflow-y: scroll;
}

/* .totalTabs::-webkit-scrollbar {
  display: none;
} */

.eachCompany {
  padding: 6.5px 16px;
  background-color: #fbfbfb;
  cursor: pointer;
}

.CompanyTabEmailImage {
  width: 31px;
  height: 31px;
}

.eachCompany:hover {
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-bottom: none;
}

.email-emailId {
  margin-right: 10px;
}

.emailContainer {
  margin-right: 60px;
  margin-top: 5px;
}

.eachCompanyImage {
  margin-right: 25px;
}

.userContainer {
  margin-top: 5px;
}

/* .usersBoard { */
/* background-color: black; */
/* min-height: 73vh; */
/* } */
.usersBoard .table-responsive-xl {
  height: calc(100% - 157px);
  overflow: auto;
}
.totalCompanies {
  font-weight: 300;
}
.email {
  color: #747a8a;
  font-size: 11.68px;
  font-weight: 300;
}

.email-id {
  color: #747a8a;
  font-size: 11.68px;
  font-weight: 400;
}

.deleteCompanySection {
  border-right: 1px solid #e4e4e5;
  border-bottom: 1px solid #e4e4e5;
}

.creativeVenus {
  color: #3c3e4d;
  font-weight: 600;
  font-size: 13.63px;
}
.companyImage {
  width: 58.39px;
  height: 58.39px;
  border: 1px solid #e4e4e5;
  border-radius: 5px;
}

.deleteCompany {
  padding: 10px;
  border: 1px solid #f44d5e;
  border-radius: 5px;
  width: 120px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.deleteCompany p {
  font-size: 12px;
  color: #f44d5e;
}

.userFirstLastIcon {
  font-size: 13px;
}

.companyCheckbox {
  width: 27px;
  height: 27px;
  margin-top: 5px;
}

.menuSection {
  background-color: #ffffff;
  box-shadow: none;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}

.companyTableMenu {
  padding-right: 15px;
}

.userFirstLastName {
  color: #3c3e4d;
  font-weight: 500;
  font-size: 12px;
}

.userRemainingData {
  font-size: 12px;
  color: #a5a6aa;
}

.insideMenu p {
  font-size: 14px;
  font-weight: 400;
  color: #a5a6aa;
}

.amUsers {
  background: #f5f5f5;
  /* margin-right: 12px; */
}

.deleteCompanyButton {
  padding-right: 10px;
}

.companyTable {
  border-radius: none;
}

.companyTable th {
  font-size: 14px;
  font-weight: 400;
  color: #a5a6aa;
}

.getModal .companyTabUserTable {
  font-size: 12px;
}

.companyTabTableHead p {
  font-size: 12px;
}

.deleteCompanySection h6 {
  font-size: 18px;
  color: #3c3e4d;
}

.amUsers h6 {
  font-size: 18px;
  color: #3c3e4d;
}

.deleteSectionBottom {
  background-color: #ffffff;
  padding: 5px 25px;
  border-radius: 20px;
  cursor: pointer;
}
.deleteSectionBottom p {
  font-size: 14px;
  font-weight: 500;
  color: #f44d5e;
}
.usersBoard .premium-btn {
  background: rgba(255, 159, 75, 0.1);
  border-radius: 50px;
  border: transparent;
  padding: 6px 16px;
  color: #e89808;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 600;
}
.company-premium-icon {
  background: #ffffff;
  border-radius: 9.5px;
  padding: 4px;
}

@media only screen and (max-width: 1275px) {
  .companyImage {
    width: 40px;
    height: 40px;
  }

  .CompanyTabEmailImage {
    width: 20px;
    height: 20px;
  }
  .creativeVenus {
    font-size: 8px;
  }

  .email {
    font-size: 8px;
  }
  .email-id {
    font-size: 8px;
  }
  .emailContainer {
    margin-right: 20px;
    margin-top: 5px;
  }
  .deleteCompanySection h6 {
    font-size: 14px;
  }
  .amUsers h6 {
    font-size: 14px;
  }
  .totalCompanies {
    font-size: 12px;
  }
}

@media only screen and (min-width: 2560px) {
  .companyTabUserTable {
    font-size: 12px;
  }
}
