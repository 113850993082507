.companyDetails .formInput {
  border-radius: 50px;
  border: 1px solid #e2e2e9;
  font-weight: 500;
  font-size: 12px;
  color: #666666 !important;
  padding: 10px;
  font-family: Inter;
  padding-left: 17px !important;
  width: 100%;
}
.companyDetails .formInput:focus-visible {
  outline: 1.5px solid #e2e2e9;
  border-radius: 50px;
}
.companyDetails .enterpriseCompanyNameInputField {
  border: 1.5px solid #f89b9c !important;
  border-radius: 50px;
}

.companyDetails .formInput:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  color: #121214 !important;
}
.companyDetails input::placeholder {
  color: #a3a3a3 !important;
  font-weight: 500;
}
.enterpriseSettingCompanyText {
  font-weight: 600;
  font-size: 14px;
  color: #212529;
  font-family: Inter;
}
.enterpriseSettingPictureText {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  font-family: Inter;
}
.enterpriseSettingPictureFormatText {
  font-weight: 400;
  font-size: 10px;
  color: #666666;
  font-family: Inter;
}
.enterpriseSettingUploadButton {
  background: #ff9f4b;
  border-radius: 5px;
  font-weight: 500;
  font-family: Inter;
  font-size: 12px;
  color: #ffffff;
  border: none;
  padding: 5px 36px;
}
.forScroll {
  height: calc(100vh - 201px);
  overflow: auto;
  max-width: 100%;
  overflow-x: hidden;
}
.enterpriseSettingFormField ::-webkit-scrollbar {
  display: none;
}
.sectionDivider {
  border: 0.5px solid #e2e2e9;
}
.crop-container {
  background: #f2f3f4;
  border: 1px dashed #e2e2e9;
  border-radius: 5px;
  padding: 15px 35px;
  height: 200px;
}
.crop-container-logo {
  width: 120px;
  height: 120px;
  border: 1px solid #e2e2e9;
  border-radius: 5px;
}
.enterpriseSettingCancelButton {
  border: none;
  background: #ffffff;
  font-weight: 600;
  font-family: Inter;
  font-size: 14px;
  text-align: right;
  color: #3c3e4d;
  padding: 5px 25px;
}
.enterpriseSettingSavaButton {
  background: #f44d5e;
  border-radius: 10px;
  font-weight: 600;
  font-family: Inter;
  font-size: 14px;
  color: #ffffff;
  border: none;
  padding: 8px 38px;
}
.enterpriseSettingCompanyData > div > div > span {
  font-weight: 500;
  font-size: 14px;
  color: #3c3e4d;
}
.enterpriseSettingCompanyDetailsTags {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.enterpriseSettingDropDown > input {
  width: 95%;
  border: none;
}
.enterpriseSettingCompanyDataInput {
  padding: 5px 15px;
  border: 1px solid #e2e2e9;
  border-radius: 50px;
  width: 60%;
  font-weight: 500;
  font-size: 12px !important;
}
.enterpriseHTTPTags > input {
  all: unset;
  font-weight: 500;
  font-size: 12px;
  width: 100%;
  color: #666666;
  padding-left: 10px;
  opacity: 0px;
}
.enterpriseSettingCompanyData ::placeholder {
  font-weight: 500;
  font-size: 12px !important;
  color: #666666;
}
.enterpriseSettingCompanyDataTextArea {
  background: #f2f3f4;
  border: 1px solid #e2e2e9;
  border-radius: 10px;
  padding: 10px;
  width: 99%;
  height: 132px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  font-family: Inter;
}

.enterpriseSettingCompanyDataTextArea::placeholder {
  font-weight: 400;
  font-size: 12px;
  color: #b1b2b8;
}
textarea:focus-visible {
  outline: 1px solid #e2e2e9;
}
.companyDetailsTags {
  font-weight: 500;
  font-size: 14px;
  color: #3c3e4d;
  font-family: Inter;
}
.socialProfileTags {
  font-size: 14px;
  font-weight: 400;
  color: #3c3e4d;
  font-family: Inter;
}
.enterpriseSettingSecurityText {
  font-weight: 600;
  font-size: 14px;
  color: #3c3e4d;
}
.enterpriseSettingResetPasswordText {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #666666;
}
.enterpriseCompanyLogoContainer {
  margin-right: 48px;
}
.enterpriseSettingResetButton {
  border: none;
  background: #f44d5e;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  padding: 8px 18px;
}
.passwordInput .passwordInputhld > input {
  all: unset;
}
.passwordInput .passwordInputhld > input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: #f2f3f4 !important;
  color: fieldtext !important;
}
.passwordInput .passwordInputhld {
  background: #f2f3f4;
  border: 1px solid #e2e2e9;
  border-radius: 10px;
  padding: 5px 15px;
}

.enterpriseSettingSocialProfileText {
  font-weight: 600;
  font-size: 14px;
  color: #3c3e4d;
}
.enterpriseHTTPTags {
  border: 1px solid #e2e2e9;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  /* opacity: 0.58; */
  margin-left: 10px;
}
.enterpriseSettingCompanyDataInputDropDown {
  all: unset;
  padding: 5px 0px;
  border: 1px solid #e2e2e9;
  border-radius: 50px;
  width: 60%;
}
.enterpriseSettingCompanyDataInputDropDown > input {
  all: unset;
  padding: 0px 15px;
  width: 87%;
}
.enterpriseDropDownTab {
  background: #ffffff;
  box-shadow: 0px 2px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 10px;
  right: 0px;
  z-index: 1;
}
.enterpriseDropDownTab > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
}
.enterpriseHTTPTags > span {
  border-right: 1px solid #e2e2e9;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  opacity: 0.58;
  padding: 12px;
}
.companyDetails .css-1vez5zo-option:active {
  background-color: #f2f3f4;
}
@media (min-width: 1440px) {
  .enterpriseSettingResetButton {
    margin-left: 32px;
  }
  .enterpriseHTTPTags {
    border: 1px solid #e2e2e9;
    border-radius: 20px;
    font-weight: 500;
    font-size: 12px;
    color: #666666;
    width: 70%;
  }
  .passwordInput .passwordInputhld {
    background: #f2f3f4;
    border: 1px solid #e2e2e9;
    border-radius: 10px;
    padding: 5px 15px;
  }
}
@media (min-width: 1650px) {
}
@media (min-width: 2560px) {
  .passwordInput .passwordInputhld {
    background: #f2f3f4;
    border: 1px solid #e2e2e9;
    border-radius: 10px;
    padding: 5px 15px;
  }
  .enterpriseSettingResetButton {
    margin-left: 215px !important;
  }
}

@media (min-width: 1660px) and (max-width: 2450px) {
  .enterpriseSettingResetButton {
    margin-left: 68px;
  }
  .passwordInput .passwordInputhld {
    background: #f2f3f4;
    border: 1px solid #e2e2e9;
    border-radius: 10px;
    padding: 5px 15px;
    width: 56% !important;
  }
  /* .companyDetails .css-1jk2779-container {
    min-width: 78.5% !important;
  } */
}

.enterpriseSettingProfileModal .modal-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  text-align: center;
  color: #000000;
  padding-left: 118px;
}
.enterpriseSettingProfileModal .modal-body {
  background: #f2f3f4;
  border: 1.19167px dashed #ffffff;
  border-radius: 5px;
  max-height: 300px;
  margin: 20px 25px;
}
.enterpriseSettingProfileModal .modalCamera-icon {
  background: #ffffff;
  border-radius: 74px;
  width: 125px;
  height: 125px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.enterpriseSettingProfileModal .modalCamera-icon > img {
  position: absolute;
  z-index: 0;
}
.enterpriseSettingProfileModal .modalCamera-icon > input[type="file" i] {
  all: unset;
  background-color: transparent;
  text-indent: -9999px;
  width: 100%;
  height: 100%;
}
.enterpriseSettingProfileModal .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border-top: none;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  min-height: 130px;
}

.enterpriseSettingProfileModal .modalUploadImage {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #3c3e4d;
  padding-top: 25px;
}
.enterpriseSettingProfileModal .settingModalButton {
  background: #f44d5e;
  border-radius: 50px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  padding: 5px 25px;
}
.enterpriseSettingProfileModal .settingModalButton > img {
  padding-left: 20px;
}
.enterpriseSettingProfileModal .modalFormat {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  text-align: center;
  color: #666666;
  padding-top: 10px;
  padding-bottom: 20px;
}

.enterpriseSettingProfileModal .btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000000 !important;
  background-image: url("../../../../../assets/Icons/Settings/modalCancel.svg");
  border: 0;
  border-radius: 0.25rem;
  opacity: 1.5;
}
.enterpriseSettingProfileModal .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: none;
  border-radius: 10px;
  outline: 0;
  box-shadow: 0px 2px 20px 10px rgba(0, 0, 0, 0.05);
}
.enterpriseSettingProfileModal .reactEasyCrop_Container {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
  user-select: none;
  touch-action: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
}
.enterpriseSettingProfileModal .enterpriseModalStepper {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #3c3e4d;
}
.enterpriseSettingProfileModal .custom-range {
  -webkit-appearance: none;
  width: 100% !important;
  height: 4px !important;
  background: #e2e2e9;
  outline: none;
  border-radius: 50px;
}
.enterpriseSettingProfileModal .custom-range::-webkit-slider-thumb {
  /* -webkit-appearance: none; */
  appearance: none;
  width: 17px;
  height: 17px;
  background: #f56170;
  color: #f56170;
  cursor: pointer;
  border-radius: 50px;
}

.enterpriseSettingProfileModal input::-webkit-slider-runnable-track {
  border-radius: 50px;
  background-color: linear-gradient(90deg, #f25431 20%, #e2e2e9 40%);
}
.enterpriseSettingProfileModal .btn-close:focus {
  box-shadow: none !important;
}
.enterpriseSettingProfileModal .controls {
  margin-top: 100px;
}
.enterpriseSettingErrorMessage {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}
