.contactUsInput {
    all: unset;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #DDD;
    padding: 10px 18px;
    height: fit-content;
    width: 100%;
    max-width: 330px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000;
}

.contactUsModal .modal-content {
    max-width: 423px;
}

.contactUsModalTextArea {
    all: unset;
    width: 90%;
    border-radius: 8px;
    border: 1px solid #DDD;
    font-size: 14px;
    font-weight: 400;
    padding: 12px 15px;
    height: fit-content;
    color: #666666;
}
.contactUsModal .formLabel span{
    color: #F44D5E;
}
.contactUsModal .modal-header{
    border-bottom: none;
}
.contactUsModal .modal-body{
    padding-top: 5px;
}

.contactUsModal .contactUsErrorField{
    border: 1px solid #D44848;
}
.formErrorMessage{
    padding: 5px 0px 0px 6px;
    font-size: 12px;
    font-weight: 500;
    color: #D44848;
}