.tabs {
  background-color: #3c3e4d;
  color: #ffffff;
  margin-top: 20px;
}
.companiesTab {
  margin-right: 40px;
  font-weight: 300;
  cursor: pointer;
  padding: 20px 0px;
}

.jobsTab {
  margin-right: 40px;
  font-weight: 300;
  cursor: pointer;
  padding: 20px 0px;
}
.interviewTab {
  margin-right: 40px;
  font-weight: 300;
  cursor: pointer;
  padding: 20px 0px;
}
.assessmentTab {
  font-weight: 300;
  cursor: pointer;
  padding: 20px 0px;
}
.adminTabs {
  border-right: 1px solid #e2e2e9;
  width: 200px;
  height: calc(100vh - 50px);
}
.adminTabs .tabItemhld {
  padding: 10px;
  border-bottom: 1px solid #E2E2E9;
}
.adminTabs .tabItemhld img{
  vertical-align: baseline;
}
.adminTabs .tabItem {
  width: 120px;
  display: inline-block;
  cursor: pointer;
}
