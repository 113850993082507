.dashboardInterviewHld .graphText{
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #969BA0;
}
.dashboardInterviewHld .positiveGraphText{
  color: #2BC155;
}
.dashboardInterviewHld .negativeGraphText{
  color: #F84E4E;
}
.dashboardInterviewBtn {
  padding: 7.5px 19px;
  background: #FFFFFF;
  border-radius: 50px;
  margin-right: 15px;
  cursor: pointer;
}

.dashboardInterviewBtn img {
  margin-left: 8px;
}

.dashboardTotalInterviewSection {
  background: transparent;
  padding: 10px 0px;
}

.dashboardInterviewText {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #343434;
}

.dashboardInterviewSection {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboardInterviewImageText {
  display: flex;
  align-items: center;
}

.dashboardInterviewImageText img {
  width: 35px;
  height: 35px;
}

.dashboardMoreInterviewIcons {
  display: flex;
}

.dashboardViewMore {
  width: 18px;
  height: 18px;
}

.dashboardInterviewTotalApplications {
  margin: 20px;
}

.dashboardInterviewHld .dashboardInterviewCard {
  margin: 0px 20px 0px 0px;
}

.dashboardInterviewCard {
  min-width: 145px;
  z-index: 111;
  border: 2px solid transparent;
  box-shadow: none;
  transition: box-shadow 0.3s;
}

.dashboardInterviewCard:hover {
  transition-delay: 0.1s;
  background: linear-gradient(141.45deg, #FFFFFF 42.61%, #F9E9EF 91.01%);
  border: 2px solid #FFFFFF;
  box-shadow: 0px 15px 40px #F0D9E1;
  cursor: pointer;
}

.dashboardInterviewHld .dashboardInterviewCard:first-child {
  max-width: 160px;
}

.dashboardInterviewCardMargin {
  margin-bottom: 10px;
}

.dashboardInterviewHeading {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 7px;
}

.dashboardFinal {
  margin-right: 0px;
}

.dashboardInterviewPercentage {
  display: flex;
}

.dashboardInterviewPercentageNeagtive {
  color: #d51501;
  font-size: 18px;
}

.dashboardInterviewPercentagePositive {
  color: #6fcf97;
  font-size: 18px;
}



.dashboardInterviewPercentage {
  font-size: 15px;
}


@media only screen and (max-width: 540px) {
  .dashboardInterviewCard img {
    width: 15px;
    height: 15px;
  }

  .dashboardInterviewImageText h5 {
    font-size: 12px;
  }

  .dashboardInterviewImageText img {
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 380px) {
  .dashboardInterviewImageText h5 {
    font-size: 8px;
  }
}