.leaderboardImgHld {
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  height: 70px;
  width: 70px;
  border-radius: 50px;
  position: absolute;
  top: 106px;
  left: 174px;
  z-index: 1;
}

.leaderboardImg {
  position: absolute;
  top: 20px;
  left: 22px;
}

.pieChartForParticipants canvas {
  border: none;
  height: 280px !important;
  width: 280px !important;
}

.locationProgressBarHld {
  width: 100%;
  padding: 0px;
  border-radius: 20px;
}

.locationProgressBarHld .locationProgressBar {
  border-radius: 20px;
}

.locationPercentageText {
  font-style: normal;
  font-weight: 600;
  font-size: 12.4396px;
  line-height: 16px;
  letter-spacing: 0.44427px;
  color: #000000;
}

.locationLabelText {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #000000;
  text-transform: capitalize;
}

.dashboardHackthonRightItem {
  width: 50%;
  margin: auto;
  /* font-size: small; */
}

.dashboardParticipantsItem {
  border-radius: 20px;
}

.dashboardParticipantsItem .dashboardHeadingHld {
  margin-bottom: 28px;
}

.pieChartForParticipants {
  width: 47%;
}

.pieChart {
  width: 50%;
}

@media only screen and (max-width: 820px) {
  .participantsCharts {
    flex-direction: column;
  }

  .pieChartForParticipants {
    width: 100%;
    /* margin: auto; */
  }
}

.dashboardNoParticipantStatistics {
  width: 70px;
  height: 70px;
}

.dashboardNoParticipantsStatisticsText {
  font-size: 14px;
  font-weight: 400;
  color: #747a8a;
  margin-bottom: 40px;
}