/* .AssessmentCntr {
  margin-top: 63px;
} */

.AssessmentParticipantsDec {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}

.AssessmentParticipantsInviteBtn {
  all: unset;
  padding: 8px 18px;
  background: #f44d5e;
  border-radius: 50px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

.participantsContactDropdown {
  background: #ffffff;
  box-shadow: 0px 2px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.participantsTableText {
  font-weight: 400;
  font-size: 12px;
  color: #3c3e4d;
}

.participantsTable {
  flex: 0 0 auto;
  width: 75.33333333%;
}
.ParticipantsFilter {
  flex: 0 0 auto;
  width: 21.3333%;
  height: calc(100vh - 100px);
  overflow: auto;
}

.ParticipantsTableHeader {
  padding: 10px 0px 10px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}
.participantsTable {
  margin-left: 27px;
}

.participantsTableMessageIcon {
  width: 24px;
  height: 24px;
}

.ParticipantsTableCandidates > img {
  width: 17px;
  height: 17px;
}
.ParticipantsTableCandidates {
  flex: 0 0 auto;
  width: 17.33333333%;
}
.ParticipantsTableStatus {
  flex: 0 0 auto;
  width: 10.33333333%;
}
.ParticipantsTableScore {
  flex: 0 0 auto;
  width: 10.33333333%;
}
.ParticipantsTableInvitedBy {
  flex: 0 0 auto;
  width: 15.33333333%;
}
.ParticipantsTableTimeLine {
  flex: 0 0 auto;
  width: 18.33333333%;
}
.ParticipantsTableActions {
  flex: 0 0 auto;
  width: 10%;
}

.ParticipantsTableBody {
  border: 0.5px solid #d9d9d9;
  border-radius: 5px;
  overflow-y: auto;
  height: calc(100vh - 190px);
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.ParticipantsTableBody::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.ParticipantsTableDataBorder {
  border-bottom: 0.5px solid #d9d9d9;
}
.ParticipantsTableData {
  padding: 10px;
}
.ParticipantsTableData:hover {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}

.assessmentParticipantDropdownList {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: -115px;
  top: 1px;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #666666;
  background-color: #ffffff;
  color: #666666;
  text-align: left;
  box-shadow: 0px 2px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  z-index: 1;
  min-width: 250px;
}
.assessmentParticipantDropdownList .user_Name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 210px;
}
.assessmentParticipantDropdownList > span , .assessmentParticipantDropdownList>a{
  padding: 13px 20px;
}
.assessmentParticipantDropdownList > a {
  color: #666666;
  text-decoration: none;
}

.assessmentParticipantDropdownList > span:hover, .assessmentParticipantDropdownList a:hover {
  background-color: #f2f3f4 !important;
  color: #000000 !important;
  cursor: pointer;
}
.additionalTimeModal {
  max-width: 280px;
}
.additionalTimeModal .modal-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}
.additionalTimeModal .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  color: #666666;
}
.additionalTimeModal label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
}
.additionalTimeModal input {
  background: #f4f4f4;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
.additionalTimeModal input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  opacity: 0.22;
}
.additionalTimeModal .modal-content {
  border: none;
}
.noParticipants {
  height: calc(100vh - 100px);
}
.noParticipants p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #b0b0b4;
}
.noParticipants h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.noParticipants button {
  padding: 16px 28px;
  background: #f44d5e;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  color: #ffffff;
}
