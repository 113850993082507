.subheadingText {
  font-weight: 500;
  font-size: 12px;
}

.hackathonLandingContainer {
  padding-right: 0px !important;
  margin-top: 60px;
}

.requestHackathonBtn>img {
  height: 12px;
}

.requestHackathonBtn {
  margin-right: 33px;
}

.hackathonListContainer {
  padding: 5px 30px;
  background: #ffffff;
  overflow: auto;
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  flex-wrap: wrap;
  height: auto
}

.hackathonLandingHeader {
  background: #f2f3f4;
}


.hackathonListContainer .hackathonItem {
  display: flex;
  flex-direction: column;
  flex: 1 1 350px;
  height: 229px;
  border-radius: 10px;
  border: 0.5px solid #D9D9D9;
  background: #FFF;
  box-shadow: 0px 24px 32px 0px rgba(8, 35, 48, 0.12);
  margin-right: 15px;
  margin-bottom: 45px;
  padding: 22px;
  max-width: 379px;
  cursor: pointer;
}

.hackathonListContainer .hackathonItem .hackathonTitle {
  color: #202224;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.28px;
  margin-top: 15px;
}

.hackathonListContainer .hackathonItem .header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90px;
}

.hackathonListContainer .hackathonItem .header .hackathonBanner {
  width: 130px;
  height: 90px;
  border-radius: 5px;
  margin-right: 15px;
}

.hackathonListContainer .hackathonItem .header .hackathonStatusContainer {
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
  width: 100%;
}

.hackathonListContainer .hackathonItem .header .hackathonStatusContainer .hStatusPill {
  width: 70px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
}
.hackathonListContainer .hackathonItem .header .hackathonStatusContainer .hStatusPillActive{
  background: #F1FBEF;
}
.hackathonListContainer .hackathonItem .header .hackathonStatusContainer .hStatusPillInactive{
  background: #f4b5b5;
}
.hackathonListContainer .hackathonItem .header .hackathonStatusContainer .hStatusPill p {
  color: #3C3E4D;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hackathonListContainer .hackathonItem .header .hackathonStatusContainer .creationDate {
  color: #666;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.hackathonListContainer .hackathonItem .hackthonProgress {
  height: 2px;
  width: 100%;
  background-color: #E4E4E5;
  margin-top: 15px;
  border-radius: 50px;
}