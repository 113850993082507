.hackathonUserInfoHeader {
  background: #f2f3f4;
  padding: 15px 20px;
}

.hackathonAssessBtn {
  border-radius: 10px;
  border: 1px solid #000;
  background: #FFF;
  height: fit-content;
  padding: 9px 15px;
  text-align: center;
}
.textThin,.hackathonAssessBtn{
  color: #000;
  font-size: 12px;
  font-weight: 500;
}
.hackathonStartInterview {
  height: fit-content;
  padding: 9px 15px;
}

.hackathonScrollData {
  overflow-y: auto;
  height: calc(100vh - 291px);
}

.hackathonScrollData::-webkit-scrollbar {
  display: none;
}

.hackathonScrollData {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.hackathonStartInterview>img {
  width: 15px;
}

.jobsInterview .rc-time-picker-input {
  width: 176px !important;
}

.dateIconJobInterview {
  top: 6px;
  left: 11px;
  z-index: 999999;
}

.participantDropdown {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  color: #3c3e4d;
}

.participantDropdown:hover {
  cursor: pointer;
}

.participantDropdownList {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
}

.participantDropdownList:hover {
  color: #3c3e4d !important;
}

.hackathonUserInfo {
  border: 0.5px solid #e2e2e9;
}

.hackathonEarnedBadge {
  background: #f2f3f4;
  padding-left: 17px;
  padding-right: 8px;
  border: 0.812849px solid #e2e2e9;
}

.hackathonEarnedBadge>img {
  margin-top: -16px;
}

.participantDropdownList {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0px;
  top: 30px;
  background-color: #ffffff;
  color: #666666;
  text-align: left;
  box-shadow: 0px 2px 20px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.participantDropdownList>span {
  padding: 10px 20px;
}

.participantDropdownList>span:hover {
  background-color: #f2f3f4 !important;
  color: #000000 !important;
  cursor: pointer;
}

.assessmentModal {
  padding: 30px 25px;
}

.assessmentModalData {
  padding: 18px;
  background: #ffffff;
  border: 1px solid #e2e2e9;
  border-radius: 10px;
  margin-bottom: 17px;
}

.assessmentModalDataSelected {
  padding: 18px;
  background: #fff8f8;
  border: 1px solid #f44d5e;
  border-radius: 10px;
  margin-bottom: 17px;
}

.scheduleInterviewlg {
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  width: 100%;
  height: 30px;
  color: white;
  background-color: #f44d5e;
  border: solid 1px #f44d5e;
}

.createInterviewModal .modal-sm {
  max-width: 400px;
}

.assessmentModalSubmit {
  width: fit-content;
  color: #ffffff;
}

.requestHackathonModal>div {
  max-width: 450px;
}

.questionsAnsHld {
  border: 0.5px solid #d9d9d9;
  border-radius: 10px;
  padding: 10px 0;
}

.questionsAnsHld .question {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #666666;
}

.questionsAnsHld .answer {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #000000;
}

.questionsAnsHld .answeredQuestion {
  color: #00c891;
}

.questionsAnsHld .notansweredQuestion {
  color: #e2e2e9;
}

.addNoteBtn {
  background: #FFBB62;
  color: #000;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 10px;
}

.addNoteSubmitBtn {
  background-color: #000;
  color: #ffffff;
  padding: 10px 22px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 5px;
  width: 112px;
  font-size: 12px;
  margin-left: auto;
}

.addNoteInput {
  border: none;
  color: #666;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  resize: none;
}

.modalNoteHeader {
  background-color: #000;
  padding: 12px 18px;
}

.addNoteHeader {
  color: #ffffff;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
}

.addNoteInput:focus {
  outline: none;
}

.noteHld {
  background-color: #F2F3F4;
  border-radius: 10px;
  margin-bottom: 5px;
  display: flex;
}
.noteArrowHld{
  border-left: 0.5px solid #E2E2E9;
}
.noteHeading {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 26.792px;
}

.noteTimeHld {
  background-color: white;
  width: 75px;
  color: #666;
  font-size: 10px;
  font-weight: 500;
  padding: 6px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media (min-width: 1920px) {
  .hackathonUserInterviewDetails>div>div {
    font-size: 15px;
  }
}